import React from "react";
import "./SupplierRisk.css";

const SupplierRisk = () => {

    return <div>
        SupplierRisk
    </div>
}

export default SupplierRisk;