import * as React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from "../../icons";
import "./DropdownMenu.css";
import { Checkbox } from "../Checkbox";
import { Settings } from "../../icons/General";
import { Badge } from "../Badge";

const DropdownMenu = DropdownMenuPrimitive.Root;

// 1. the dropdown menu Trigger part
const DropdownMenuTrigger = React.forwardRef(
  ({ className, children, filterIcon, height, width, ...props }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
      <DropdownMenuPrimitive.Trigger
        ref={ref}
        className={
          filterIcon
            ? "dropdown-menu-icon-only"
            : `dropdown-menu-trigger ${className || ""}`
        }
        onMouseDown={() => setIsOpen((prev) => !prev)}
        style={{ height: height ?? "36px", width: width ?? "200px" }} // allowed we could send the width and height
        {...props}
      >
        {filterIcon ? (
          <div className="dropdown-menu-custom-icon">
            <Settings size={16} />
          </div>
        ) : (
          <>
            {children}
            {isOpen ? (
              <ChevronUpIcon className="dropdown-menu-trigger-icon" />
            ) : (
              <ChevronDownIcon className="dropdown-menu-trigger-icon" />
            )}
          </>
        )}
      </DropdownMenuPrimitive.Trigger>
    );
  },
);
DropdownMenuTrigger.displayName = DropdownMenuPrimitive.Trigger.displayName;

const DropdownMenuGroup = DropdownMenuPrimitive.Group;

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

const DropdownMenuSubTrigger = React.forwardRef(
  ({ className, inset, filterIcon, children, ...props }, ref) => (
    <DropdownMenuPrimitive.SubTrigger
      ref={ref}
      className={`dropdown-menu-sub-trigger ${inset ? "inset" : ""} ${className || ""}`}
      data-filter-menu={filterIcon ? "" : undefined}
      {...props}
    >
      <div className="dropdown-menu-item-inner dropdown-menu-item-inner-sub-trigger">
        {children}
        <ChevronRightIcon className="dropdown-menu-sub-trigger-icon" />
      </div>
    </DropdownMenuPrimitive.SubTrigger>
  ),
);
DropdownMenuSubTrigger.displayName =
  DropdownMenuPrimitive.SubTrigger.displayName;

const DropdownMenuSubContent = React.forwardRef(
  ({ className, filterIcon, ...props }, ref) => (
    <DropdownMenuPrimitive.SubContent
      ref={ref}
      className={`dropdown-menu-sub-content ${className || ""}`}
      data-filter-menu={filterIcon ? "" : undefined}
      {...props}
    />
  )
);
DropdownMenuSubContent.displayName =
  DropdownMenuPrimitive.SubContent.displayName;

// 2. the dropdown menu content body part
// sideOffset = 0 ==> the space of inner with Dropdownmenu content area
const DropdownMenuContent = React.forwardRef(
  ({ className, sideOffset = 0, width, filterIcon, ...props }, ref) => (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={`dropdown-menu-content ${className || ""}`}
        style={{ width: width ?? "200px" }} // allowed we could send the width and height
        data-filter-menu={filterIcon ? "" : undefined}
        {...props}
      />
    </DropdownMenuPrimitive.Portal>
  )
);
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;


// 3: the dropdown menu select item
const DropdownMenuItem = React.forwardRef(
  ({ className, inset, children, ...props }, ref) => (
    <DropdownMenuPrimitive.Item
      ref={ref}
      className={`dropdown-menu-item ${inset ? "inset" : ""} ${className || ""}`}
      {...props}
    >
      <div className="dropdown-menu-item-inner">{children}</div>
    </DropdownMenuPrimitive.Item>
  )
);
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

// 3.1: the dropdown menu select signle check box item
const DropdownMenuCheckboxItem = React.forwardRef(
  (
    {
      className,
      children,
      checked,
      onChange,
      disabled,
      size = "sm",
      indeterminate,
      label,
      badgeText,
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.CheckboxItem
      ref={ref}
      className={`dropdown-menu-checkbox-item ${className || ""}`}
      checked={checked}
      disabled={disabled}
      {...props}
    >
      <div className="dropdown-menu-item-inner">
        <Checkbox
          id={`dropdown-checkbox-${props.value}`}
          checked={checked}
          onChange={() => {}} // The actual change is handled by DropdownMenuPrimitive.CheckboxItem
          disabled={disabled}
          size={size}
          indeterminate={indeterminate}
          type="checkbox"
          label={label}
        />
        {children}
        {badgeText && <div style={{marginLeft: "8px"}}> <Badge type="category" variant="category-gray">{badgeText}</Badge> </div>}
      </div>
    </DropdownMenuPrimitive.CheckboxItem>
  )
);
DropdownMenuCheckboxItem.displayName =
  DropdownMenuPrimitive.CheckboxItem.displayName;


// 3.2: the dropdown menu content single radio select item
const DropdownMenuRadioItem = React.forwardRef(
  (
    {
      className,
      children,
      checked,
      onChange,
      disabled,
      size = "sm",
      indeterminate,
      label,
      badgeText,
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.RadioItem
      ref={ref}
      className={`dropdown-menu-radio-item ${className || ""}`}
      {...props}
    >
      <div className="dropdown-menu-item-inner">
        <Checkbox
          id={`dropdown-checkbox-${props.value}`}
          checked={checked}
          onChange={() => {}} // The actual change is handled by DropdownMenuPrimitive.CheckboxItem
          disabled={disabled}
          size={size}
          indeterminate={indeterminate}
          type="radio"
          label={label}
        />
        {children}
        {badgeText && <div style={{marginLeft: "8px"}}> <Badge type="category" variant="category-gray">{badgeText}</Badge> </div>}
      </div>
    </DropdownMenuPrimitive.RadioItem>
  )
);
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

// 3.3 the select item with out Radio and checkbox
const DropdownMenuLabelItem = React.forwardRef(
  (
    {
      className,
      children,
      disabled,
      label,
      badgeText,
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.Item
      ref={ref}
      className={`dropdown-menu-label-item ${className || ""} ${disabled ? "disabled" : ""}`}
      aria-disabled={disabled}
      {...props}
    >
      <div className="dropdown-menu-item-inner">
        {children}
        {badgeText && <div style={{marginLeft: "8px"}}> <Badge type="category" variant="category-gray">{badgeText}</Badge> </div>}
      </div>
    </DropdownMenuPrimitive.Item>
  )
);

DropdownMenuLabelItem.displayName = DropdownMenuPrimitive.Item.displayName;


// 4. the Dropdown Menu Label part
const DropdownMenuLabel = React.forwardRef(
  ({ className, inset, children, ...props }, ref) => (
    <DropdownMenuPrimitive.Label
      ref={ref}
      className={`dropdown-menu-label ${inset ? "inset" : ""} ${className || ""}`}
      {...props}
    >
      <div className="dropdown-menu-item-inner">{children}</div>
    </DropdownMenuPrimitive.Label>
  )
);
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

// 5. the Dropdown Menu Separator
const DropdownMenuSeparator = React.forwardRef(
  ({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.Separator
      ref={ref}
      className={`dropdown-menu-separator ${className || ""}`}
      {...props}
    />
  )
);
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const DropdownMenuShortcut = ({ className, ...props }) => {
  return (
    <span className={`dropdown-menu-shortcut ${className || ""}`} {...props} />
  );
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabelItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
};
