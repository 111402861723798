import React from "react";
import "./Optimizations.css";
import { ChevronRight } from "../../../Components/icons/Arrows";
import { GanttCard, MilestonesFormat, Badge} from "../../../Components";
import GanntData from "../../../datasets/achieveData/OptimizationData.json"
import FormatDate from "../../../datasets/achieveData/OptimizationFormatData.json"

const DefaultOptimization = () => {

    // the Issue Key status Badge
    const getIssueKeyColorVersion = (label) => {
        switch (label) {
            case "Open":
                return "Open";
            case "Investigating":
                return "Current";
            case "Closed":
                return "Closed";
            default:
                return "Current";
        }
    };

    // the Design Phase Column Badge
    const getColorVersion = (label) => {
        if (!label) return "category-gray";
        switch (label) {
            case "Concept & Requirements":
                return "category-gray";
            case "Production & Deployment":
                return "category-green";
            case "Integration & Testing":
                return "category-red";
            case "Certification & Validation":
                return "category-yellow";
            case "Design & Development":
                return "category-blue";
            default:
                return "category-gray";
        }
    };

    // the Design Phase Column Badge
    const getPriorityColorVersion = (label) => {
        switch (label) {
            case "High":
                return "PrioritiesHigh";
            case "Medium":
                return "PrioritiesMedium";
            case "Low":
                return "PrioritiesLow";
            default:
                return "PrioritiesHigh";
        }
    };


    return <>
        <div className="pageRoute">
            <button className={`routeButton DisableClicked`}> Achieve </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}> Optimizations </button>
        </div>

        <span className="PageText">Avionics Overview</span>

        <GanttCard
            chartArr={GanntData}
            chartTitle="Design Phases & Time Allocation"
            defaultHeight="530px"
            defaultWidth="100%"
            hideViewDetails={false}
        />

        <div className="optimizationsFormat">
            <div className="optimizationsFormatTitle">
                Breakdown by Issues
            </div>
            <MilestonesFormat
                width="100%"
                height="calc(100vh - 180px)"
                data={FormatDate.data || []}
                columns={[
                    {
                        key: "issue-key",
                        label: "Issue Key",
                        width: "25%",
                        render: (value, row) => (
                            <div className="product-cell">
                                {value}
                                <Badge type="status" variant={getIssueKeyColorVersion(row.label)}>
                                    {row.label}
                                </Badge>
                            </div>
                        ),
                    },
                    {
                        key: "Design Phase",
                        label: "Design Phase",
                        width: "19%",
                        render: (value) => (
                            <div className="product-cell">
                                <Badge type="category" variant={getColorVersion(value)}>
                                    {value}
                                </Badge>
                            </div>
                        ),
                    },
                    {
                        key: "Start Date",
                        label: "Start Date",
                        width: "12%",
                    },
                    {
                        key: "Closing Date",
                        label: "Closing Date",
                        width: "12%",
                    },
                    {
                        key: "Priority",
                        label: "Priority",
                        width: "12%",
                        render: (value) => (
                            <div className="product-cell">
                                <Badge type="priorities" variant={getPriorityColorVersion(value)}>
                                    {value}
                                </Badge>
                            </div>
                        ),
                    },
                    {
                        key: "Location",
                        label: "Location",
                        width: "10%",
                        render: (value) => (
                            <span className="locationColumnUI">
                              {value}      
                            </span>
                        )
                    },
                    {
                        key: "Supplier name",
                        label: "Supplier name",
                        width: "10%",
                        render: (value) => (
                            <span className="supplierColumn">
                                {value}
                            </span>
                        )
                    },
                ]}
                rowHeight="50px"
            />
        </div>
    </>
}

export default DefaultOptimization;