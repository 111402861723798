// this is used for Achieve Module Trends filter including: series and mode dropdown menu
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "./TrendsFilter.css"
import AchieveSearch from "../Achieve Search/AchieveSearch.js";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from "../../ui//DropdownMenu/DropdownMenu.jsx";

const TrendsFilter = ({
    data,  // the data from parents level
    searchItem,
    setSearchItem,
    selectedSeries,
    setSelectedSeries,
    selectedModel,
    setSelectedModel,
}) => {
    const [seriesDropDownArray, setSeriesDropDownArray] = useState([]);
    // const [modelDropDownArray, setModelDropDownArray] = useState([]);
    
    // useEffect(() => {
    //     // Extract all unique series and models
    //     const uniqueSeries = new Set();
    //     const uniqueModels = new Set();

    //     // Iterate through each key in the data object
    //     Object.keys(data).forEach((key) => {
    //         const item = data[key];

    //         // Add series to the uniqueSeries Set
    //         item.series.forEach((series) => {
    //             uniqueSeries.add(series);
    //         });

    //         // Add models to the uniqueModels Set
    //         item.model.forEach((model) => {
    //             uniqueModels.add(model);
    //         });
    //     });

    //     // Convert the Sets back to arrays and set them to state
    //     setSeriesDropDownArray(Array.from(uniqueSeries));
    //     setModelDropDownArray(Array.from(uniqueModels));
    // }, [data]);

    // This is static data for next week 
    //  seriesDropDownArray = ["100", "200", "300", "400", "500", "600", "700", "800", "900", "Undifferentiated or Other Model", "Other"];
    const modelDropDownArray =  ["B737", "B747", "B757", "B767", "B777", "B787", "Other"];

    useEffect(() => {
        if(selectedModel === "B737"){
            setSeriesDropDownArray(["200", "300", "400", "500", "600", "700", "800", "900", "Undifferentiated or Other Model", "Next Generation Undifferentiated"]);
        } else if(selectedModel === "B747"){
            setSeriesDropDownArray(["100", "200", "300", "400", "800", "Undifferentiated or Other Model"]);
        } else if(selectedModel === "B757"){
            setSeriesDropDownArray(["200", "300", "Undifferentiated or Other Model"]);
        } else if(selectedModel === "B767"){
            setSeriesDropDownArray(["200", "300 and 300 ER", "400 and 400 ER", "Undifferentiated or Other Model"]);
        } else if(selectedModel === "B777"){
            setSeriesDropDownArray(["200", "Undifferentiated or Other Model"]);
        } else if(selectedModel === "B787"){
            setSeriesDropDownArray(["Dreamliner Undifferentiated or Other Model"]);
        } else if(selectedModel === "Other"){
            setSeriesDropDownArray(["Other"]);
        } else{
            setSeriesDropDownArray(["100", "200", "300", "400", "500", "600", "700", "800", "900", "Undifferentiated or Other Model", "Other"]);
        }
    }, [selectedModel, setSeriesDropDownArray])
  

    // function: used to Series dropdown menu selected
    const handleSelectSeries = (seriesItem) => {
        setSelectedSeries(seriesItem);
    };

     // function: used the model dropdown menu selected
     const handleSelectedModel = (modelItem) => {
        setSelectedModel(modelItem);
    };

    return <div className="SearchArea">
        {/* AchieveSearch component */}
        <AchieveSearch
            width="250px"
            height="34px"
            options={data}
            selectedProduct={searchItem}  // control user selected
            onSearch={setSearchItem} // Listen user selected
            placeholder="Search System/Component"
        />

        <div className="viewByArea">View by</div>
        
        {/* Model dropdown menu */}
        <DropdownMenu >
            <DropdownMenuTrigger height="34px" width="130px">
                <span className="dropdown-menu-trigger-text">
                    {selectedModel === "" ? "Select Model" : selectedModel}
                </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent width="130px">
                <div className="achievDropDownArea">
                    {modelDropDownArray.map((item) => (
                        <DropdownMenuItem key={item} onSelect={() => handleSelectedModel(item)}>
                            {item}
                        </DropdownMenuItem>
                    ))}
                </div>
            </DropdownMenuContent>
        </DropdownMenu>

        {/* Series dropdown menu */}
        <DropdownMenu >
            <DropdownMenuTrigger height="34px">
                <span className="dropdown-menu-trigger-text">
                    {selectedSeries === "" ? "Select Series" : selectedSeries}
                </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <div className="achievDropDownArea">
                    {seriesDropDownArray.map((item) => (
                        <DropdownMenuItem key={item} onSelect={() => handleSelectSeries(item)}>
                            {item}
                        </DropdownMenuItem>
                    ))}
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    </div>
};

TrendsFilter.propTypes = {
    data: PropTypes.array,  
    searchItem: PropTypes.object,  
    setSearchItem: PropTypes.func, 
    selectedSeries: PropTypes.string,  
    setSelectedSeries: PropTypes.func, 
    selectedModel: PropTypes.string,  
    setSelectedModel: PropTypes.func,
};

export default TrendsFilter;