// router.js: routes.js is responsible for managing the routes change of top-level modules 
import Discover from "../Pages/Module Discover/Discover";
import Achieve from "../Pages/Module Achieve/Achieve";
import HelpCenter from "../Pages/Help Center/HelpCenter";
import Login from "../Pages/Global Component/LogIn_SignUp/Login";
import UserPage from "../Pages/Module UserProfile/UserPage";
import Setup from "../Pages/Setup Area/Setup";
import Drive from "../Pages/Module Drive/Drive";
import TeamPage from "../Pages/Global Component/Team/TeamPage";
import SharedView from "../Pages/Shared/SharedView";
import ErrorPage from "../Pages/Error Page/ErrorPage"
import LoginCallback from "../Pages/Global Component/Login Callback/LoginCallback"
import Register from "../Pages/Global Component/Register/Register";
import Pending from "../Pages/Global Component/Pending/Pending";
import { Navigate } from "react-router-dom";
import { element } from "prop-types";

export const routes = [
  {
    path: "/",
    element: <Navigate to="/discover" replace />,
    protected: false,
  },
  {
    path: "/login",
    element: <Login />,
    protected: false,
  },
  {
    path: "/register",
    element: <Register />,
    protected: false,
  },
  {
    path: "/callback",
    element: <LoginCallback/>,
    protected: false,
  },
  {
    path: "/pending",
    element: <Pending/>,
    protected: true
  },
  {
    path: "/drive/*",
    element: <Drive />,
    protected: true,
  },
  {
    path: "/achieve/*",
    element: <Achieve />,
    protected: true,
  },
  {
    path: "/discover/*",
    element: <Discover />,
    protected: true,
  },
  {
    path: "/help-center",
    element: <HelpCenter />,
    protected: true,
  },
  {
    path: "/setup",
    element: <Setup />,
    protected: true,
  },
  {
    path: "/user-account",
    element: <UserPage />,
    protected: true,
  },
  {
    path: "/team",
    element: <TeamPage />,
    protected: true,
  },
  {
    path: "/shared/*",
    element: <SharedView />,
    protected: true,
  },
  {
    path: "/404Error/*",
    element: <ErrorPage />,
    protected: false,
  },
  {
    path: "/pending",
    element: <Pending />,
    protected: false,
  },
  {
    path: "*",
    element: <Navigate to="/404Error" replace />,
    protected: false,
  },
];

