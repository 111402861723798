import axios from "axios";

const baseApiURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseApiURL,
  withCredentials: true,
});

// Add interceptor to handle unauthorized responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status;
    const detail = error.response?.data?.detail;
    if (status === 401) {
      window.location.href = '/login';
    }
    if (
      status === 403 && 
      Array.isArray(detail) && 
      detail[0]?.error?.code === 210
    ){
      window.location.href = '/pending'
    }
    return Promise.reject(error);
  }
);

// Add interceptor to handle other error cases
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle non-auth related errors here
    return Promise.reject(error);
  }
);

// stop the error, and jump to the error page
// api.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response) {
//       const navigate = useNavigate();
//       if (error.response.status === 403 || error.response.status === 404) {
//         // jump to the error page
//         navigate('/404Error');
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export {api};