import React, {useState} from "react";
import './AchieveHome.css';
import AddChart from "./Add Chart/AddChart";
import { ChevronRight } from "../../../Components/icons/Arrows";
import { useAppContext } from "../../../AppContext";
import { Button, DatePicker, DetailsWindow, DesignChart } from "../../../Components";

const AchieveHome = () => {
    const [openAddChart, setOpenAddChart] = useState(false); // control whether open the add chart page or not

    const { userGraphArray } = useAppContext();
    
    // 4. the home page add chart date picker range 
    // const [chartRange, setChartRange] = useState({
    //     startDate: null,
    //     endDate: null
    // });

    // handle get the user select date from date picker component in the failure mode search
    // const handleChartDateProductRange = (dates) => {
    //     setChartRange(dates);
    // };

    // open the add chart pop window
    const handleOpenAddChart = () => {
        setOpenAddChart(true);
    }

    // closed the add chart pop window
    const handleClosedAddChart = () => {
        setOpenAddChart(false);
    }

    return <div className="achieveHome">
        <div className="pageRoute">
            <button className={`routeButton DisableClicked`}> Achieve </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}> Home </button>
        </div>

        <div className="addChartButtonArea">
            <div className="addChartButtonAreaLeft">
                Quickstart
                <Button variant="primary" size="xsmall" onClick={handleOpenAddChart}> Add Graph </Button>
            </div>

            {/* <DatePicker
                width="460px"
                height="428px"
                initialMode="Since"
                startDate={chartRange.startDate}
                endDate={chartRange.endDate}
                onApply={handleChartDateProductRange}
            /> */}
        </div>

        {
            (Array.isArray(userGraphArray) && userGraphArray.length >= 1) ? 
            <>
                {userGraphArray.map((item, index) => (
                    <DesignChart
                        key={index}
                        width="900px"
                        height="550px"
                        title={item.title}
                        chartData={item.chartData}
                        chartLayout={item.chartLayout}
                        flex={1}
                    />
                ))}
            </> :  
            <DesignChart
                width="100%"
                height="520px"
                title="Define Your Chart Graph"
                htmlGraph=""
                flex={0}
            />
        }

        <DetailsWindow
            open={openAddChart}
            onClose={handleClosedAddChart}
            width="80vw"
            height="100vh"
        >
            <AddChart setOpenAddChart={setOpenAddChart}/>
        </DetailsWindow>
    </div>
}

export default AchieveHome;