import React from "react";
import './Pending.css';
import Mail from "../../../Components/icons/General/mail";
import ApiphanyIconWithName from "../../../image/ApiphanyLogo/ApiphanyIconWithName.jpg"

const Pending = () => {

    return <div className="PendingPage">
        <div className="detailsArea">
            <img
                src={ApiphanyIconWithName}
                alt="Company Logo"
                className="CompanyLogo"
            />

            <span className="pendingTitle">You don't have access to this page yet—your request is still under review.</span>

            <span className="pendingText">Our team is reviewing your details to ensure everything meets our requirements. We'll notify you once it's ready to go. In the meantime, if you have any questions, please contact your organizations administrator. </span>

            <div className="svgIcon"> <Mail size={24} color="white" /> </div>
            <div className="contactPending">
                <span className="title">Contact us</span>
                <span className="subTitle">We're here to help.</span>
                <a href="mailto:Support@apiphany.ai" className="emailText">Support@apiphany.ai</a>
            </div>
        </div>
    </div>
};
export default Pending;