import React, {useState, useEffect} from "react";
import './DetailsWindow.css';
import PropTypes from "prop-types";
import { ArrowLeft } from "../../icons/Arrows";
import Button from "../../ui/Button/Button";

const DetailsWindow = ({
    width,
    height,
    open,
    onClose,
    children,
    zIndex = 1000,
    isTopWindow = true, 
}) => {

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
      // define and reStart the component
      if (!isInitialized && open) {
        // first setup closed status
        setTimeout(() => {
          setIsInitialized(true);
        }, 10);
      }
    }, [open]);

    return <div className="DetailsWindow" style={{
        width: width, height: height, zIndex: zIndex,
        transform: (!isInitialized && open) ? "translateX(100%)" :
            (open ? "translateX(0)" : "translateX(100%)"),
        transition: isInitialized ? "transform 0.5s ease-in-out" : "none",
        overflowY: isTopWindow ? 'auto' : 'hidden',
    }}>
        <Button
            iconOnly
            size="small"
            variant="tertiary-gray"
            style={{ position: "absolute", top: 24, right: 24 }} 
            onClick={onClose}
        >
            <ArrowLeft size={20} color="#475467"/>
        </Button>
        {children}
    </div>
}

DetailsWindow.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    zIndex: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
    isTopWindow: PropTypes.bool,
}

export default DetailsWindow;