// the temporary pop window: save hide and share
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Save, Hide, Show, Share, Delets, Copy, Star } from "../../icons/General/index";
import { ChevronRight } from "../../icons/Arrows/index";
import { Checkbox } from "../../ui/Checkbox";
import { Button } from "../../index"
import Spinner from '../../Spinner/Spinner'; // Import the Spinner component
import "./TablePopWindow.css";

const TablePopWindow = ({ 
  selectedCount,
  checkedRows,
  onSave, 
  onShare, 
  onHide,
  onFavorite,
  tableView,
  className,
  teamMember = [],
  top = "40px", 
  left = "50px",
  isTeamMemberLoading, // Accept the new prop
}) => {
  const [clickButton, setClickedButton] = useState(); // the click button: Share, Remove, Hide, Star, unhide
  const [showShareMenu, setShowShareMenu] = useState(false); // judge whether showup the share item menu
  const [selectTeamMeber, setSelectTeamMeber] = useState([]); // the team meber user selected for share (the email array)

  // the function about click button
  const handleAction = (str) => {
    setClickedButton(str);
  }

  // fixed the async error: and judge which button user clicked it
  useEffect(() => {
    if(clickButton === "Save" || clickButton === "Remove"){
        onSave(checkedRows);
    } else if(clickButton === "Share"){
        setShowShareMenu((prev) => !prev);
    } else if(clickButton === "Hide" || clickButton === "Unhide"){
        onHide(checkedRows);
    } else if(clickButton === "Favorite" || clickButton === "Unfavorite"){
        onFavorite(checkedRows);
    }
  }, // eslint-disable-next-line 
  [clickButton]);

  // and get which team member that user selected for share
  const handleCheckboxChange = (email) => {
    setSelectTeamMeber(prev => {
      if (prev.includes(email)) {
        return prev.filter(e => e !== email);
      }
      return [...prev, email];
    });
  };

  return (
    <div className={className ? `${className}` : "TablePopWindow"} style={{top: top, left: left}}>
      <span className="selectText">{selectedCount} selected</span>
      <button onClick={() => handleAction(tableView === "Saved" ? "Remove" : "Save")} className="mainSelectButton">
        {tableView === "Saved" ? (
          <div className="leftPart">
            <Delets size={16} /> Remove
          </div>
        ) : (
          <div className="leftPart">
            <Save size={16} /> Save
          </div>
        )}
      </button>
      {/* <button onClick={() => handleAction(tableView === "Starred" ? "Unfavorite" : "Favorite")} className="mainSelectButton">
        {tableView === "Starred" ? (
          <div className="leftPart">
            <Star size={16} /> Unfavorite
          </div>
        ) : (
          <div className="leftPart">
            <Star size={16} /> Favorite
          </div>
        )}
      </button> */}
      <div>
        <button onClick={() => handleAction("Share")} className= {`mainSelectButton ${showShareMenu ? "clicked" : ""}`} style={{ position: "relative" }}>
          <div className="leftPart"><Share size={16} />Share</div>
          <ChevronRight size={16} />
        </button>
        {showShareMenu && (
          <div
            style={{
              position: "absolute",
              top: "78px",
              left: "101%",
            }}
            className="subMenu"
          >
            <div className="dropDownHeader">
              <Copy size={16} /> Copy to clipboard
            </div>
 
            <div className="selectArea">
            {isTeamMemberLoading ? (
              <div className="spinner-container">
                <Spinner size="small" />
                <span>Loading Team Members...</span>
              </div>
            ) : teamMember.length > 0 ? (
              teamMember.map((member, index) => (
                <Checkbox
                    key={index}
                    id={member.email}
                    label={member.name}
                    checked={selectTeamMeber.includes(member.email)}
                    className={"dropDownItem"}
                    onChange={() => handleCheckboxChange(member.email)}
                  />
                ))
              ) : (
                <span>No team members available</span>
              )}
            </div>

            <Button 
                variant="primary" 
                size="xsmall" 
                width={"100%"}
                onClick={() => {
                    onShare(selectTeamMeber);
                    setShowShareMenu(false);
                }}
                disabled={selectTeamMeber.length === 0}
            >
                Send
            </Button>
          </div>
        )}
      </div>
      {(tableView !== "Shared" && tableView !== "Saved") && <button onClick={() => handleAction(tableView === "Hidden" ? "Unhide" : "Hide")} className="mainSelectButton">
        {tableView === "Hidden" ? (
          <div className="leftPart">
            <Show size={16} /> Unhide
          </div>
        ) : (
          <div className="leftPart">
            <Hide size={16} /> Hide
          </div>
        )}
      </button>}
    </div>
  );
};

TablePopWindow.propTypes = {
  selectedCount: PropTypes.number.isRequired,
  checkedRows: PropTypes.object,
  top: PropTypes.string,
  left: PropTypes.string,
  onSave: PropTypes.func,
  onShare: PropTypes.func,
  onHide: PropTypes.func,
  tableView: PropTypes.string,
  classNames: PropTypes.string,
  teamMember: PropTypes.array,
  selectedMember: PropTypes.array,
  isTeamMemberLoading: PropTypes.bool, // Add the prop type
};

export default TablePopWindow;