// the Bar chart customize area and UI
import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./BarChart.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const BarChart = ({ height, width, data, selectedFailureViews, selectedFailureSeries, stacked = true, dateFailureModeRange, isLoading, }) => {
  // Normalize the view key to match your data structure
  const views = selectedFailureViews === "Cost" ? "Cost" : 
               selectedFailureViews === "Failure Rate" ? "Failure Rate" : 
               "Total Downtime";

  // Get all unique years across all variants for the selected view
  const allYears = [...new Set(
    Object.values(data).flatMap(variant => 
      Object.keys(variant[views] || {})
    )
  )].map(Number).sort((a, b) => a - b);

  // Filter years based on date range if provided
  const filteredYears = (() => {
    if (!dateFailureModeRange || !dateFailureModeRange.startDate) {
      return allYears;
    }
    
    const startYear = dateFailureModeRange.startDate.getFullYear();
    const endYear = dateFailureModeRange.endDate 
      ? dateFailureModeRange.endDate.getFullYear() 
      : new Date().getFullYear();
    
    return allYears.filter(year => year >= startYear && year <= endYear);
  })();

  // The color array
  const colors = ["#D1E9FF", "#B2DDFF", "#84CAFF", "#53B1FD", "#2E90FA", "#1570EF", "#175CD3", "#1849A9", "#194185"];

  // Get color based on variant index
  const getColor = (variant, data) => {
    const index = Object.keys(data).indexOf(variant);
    return colors[index % colors.length]; 
  };

  // Prepare datasets for Chart.js
  const datasets = Object.keys(data)
    .filter(variant => selectedFailureSeries.length === 0 || selectedFailureSeries.includes(variant))
    .map(variant => {
      const metricData = filteredYears.map(year => {
        // Safely access the value for each year
        const metricValues = data[variant][views] || {};
        return metricValues[year] || 0;
      });

      return {
        label: variant,
        data: metricData,
        backgroundColor: getColor(variant, data),
        borderColor: getColor(variant, data),
        borderWidth: 1,
        barThickness: 32,
      };
    });

  const chartData = {
    labels: filteredYears,
    datasets,
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
      title: { display: false },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
          font: { size: 12, family: "Inter, sans-serif" },
        },
        stacked: stacked,  
      },
      y: {
        title: {
          display: true,
          text:
            views === "Cost"
              ? "Annual cost of failure mode"
              : views === "Failure Rate"
              ? "Annual Failure Rate"
              : "Annual resolution time of failure mode",
          font: { size: 12, family: "Inter, sans-serif" },
        },
        beginAtZero: true,
        stacked: stacked,  
      },
    },
  };

   // the search animation effect dots numbers
   const dots = Array.from({ length: 16 });

  return (
    <div
      className="BarChartArea"
      style={{ minHeight: height, maxHeight: height, minWidth: width, maxWidth: width }}
    >
      {isLoading ?
        <div className="fade-in">
          <div className="dots-loader">
            {dots.map((_, index) => (
              <div
                key={index}
                className="dot"
                style={{
                  transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                  animationDelay: `${index * 0.125}s`
                }}
              />
            ))}
          </div>
        </div> :
        <Bar data={chartData} options={options} />
      }
    </div>
  );
};

BarChart.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  selectedFailureViews: PropTypes.string.isRequired,
  selectedFailureSeries: PropTypes.array.isRequired,  
  stacked: PropTypes.bool,  
  dateFailureModeRange: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default BarChart;