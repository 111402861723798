import React, { useEffect, useMemo, useRef } from "react";
import './IssueOverview.css'
import { ChartGraph } from "../../../Components";
import { ChevronRight } from "../../../Components/icons/Arrows";
import StatCard from "../../../Components/achieve/StatCard/StatCard";
import SortTable from "../../../Components/achieve/Sort Table/SortTable";
import { useAchievedIssuesQuery } from "../../../api/queries";
import { Badge } from "../../../Components/ui/Badge";
import SearchIcon from "../../../Components/icons/General/Search";
import CloseIcon from "../../../Components/icons/General/Close";
import FilterIcon from "../../../Components/icons/FilterIcon";
import { useAppContext } from "../../../AppContext";

const DefaultIssueOverview = () => {
    const {
        searchText, setSearchText,
        appliedSearchText, setAppliedSearchText,
        showModelFilter, setShowModelFilter,
        selectedModels, setSelectedModels,
        allModels, setAllModels,
        expandedModelRows, setExpandedModelRows,
        expandedFailureModes, setExpandedFailureModes,
        failureModeChartData, setFailureModeChartData,
        sortTableData, setSortTableData,
    } = useAppContext();
    const { data: achievedIssues } = useAchievedIssuesQuery();
    const searchTimeoutRef = useRef(null);

    useEffect(() => {
        if (achievedIssues) {
            // Pre-calculate the percentage for each item
            const dataWithPercentage = achievedIssues.data.map(item => ({
                ...item,
                percentage: achievedIssues.Total_Recurring_Issues > 0
                    ? (item.Issue_Count / achievedIssues.Total_Recurring_Issues) * 100
                    : 0
            }));
            setSortTableData(dataWithPercentage);

            // Extract all unique models for the filter
            const uniqueModels = new Set();
            dataWithPercentage.forEach(row => {
                if (row.Make_Model_Names && Array.isArray(row.Make_Model_Names)) {
                    row.Make_Model_Names.forEach(model => {
                        if (model &&
                            !model.includes('Undifferentiated') &&
                            !model.includes('Other Model')) {
                            uniqueModels.add(model);
                        }
                    });
                }
            });

            // Convert set to sorted array
            setAllModels([...uniqueModels].sort());
        }
    }, [achievedIssues]);

    // Toggle the expanded state for a specific row
    const toggleExpandModels = (rowId) => {
        setExpandedModelRows(prev => ({
            ...prev,
            [rowId]: !prev[rowId]
        }));
    };

    // Toggle expansion of failure mode text
    const toggleExpandFailureMode = (rowId) => {
        setExpandedFailureModes(prev => ({
            ...prev,
            [rowId]: !prev[rowId]
        }));
    };

    // Function to filter data based on search text and selected models
    const filterData = useMemo(() => {
        if (!sortTableData || !sortTableData.length) return [];

        return sortTableData.filter(row => {
            // First apply search text filter (only on failure mode / Group Descriptor)
            const searchMatch = !appliedSearchText ||
                (row["Group Descriptor"] &&
                    String(row["Group Descriptor"]).toLowerCase().includes(appliedSearchText.toLowerCase()));

            // If search doesn't match, exclude this row
            if (!searchMatch) return false;

            // Then apply model filter
            if (selectedModels.length === 0) {
                // No models selected means no filtering by model
                return true;
            }

            // Check if any of the row's models match any of the selected models
            if (row.Make_Model_Names && Array.isArray(row.Make_Model_Names)) {
                return row.Make_Model_Names.some(model => selectedModels.includes(model));
            }

            return false;
        });
    }, [sortTableData, appliedSearchText, selectedModels]);

    // Update chart data when filtered data or search changes
    useEffect(() => {
        if (filterData && filterData.length > 0) {
            // Transform the failure mode data for the chart
            // Sort by Issue_Count descending and take top 10
            const topFailureModes = [...filterData]
                .sort((a, b) => b.Issue_Count - a.Issue_Count)
                .slice(0, 10);

            // Format for the chart with open and closed status
            const formattedData = topFailureModes.map(item => ({
                label: item["Group Descriptor"],
                count: item.Issue_Count,
                // Add Issues array to match the structure expected by ChartGraph for stacked charts
                Issues: [
                    {
                        count: item.Open_Count,
                        label: "Open"
                    },
                    {
                        count: item.Closed_Count,
                        label: "Closed"
                    }
                ]
            }));

            setFailureModeChartData(formattedData);
        } else {
            setFailureModeChartData([]);
        }
    }, [filterData]);

    // Search handlers
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchText(value);

        // Clear any existing timeout to implement debouncing
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        // Set a new timeout - only apply search after 500ms of inactivity
        searchTimeoutRef.current = setTimeout(() => {
            setAppliedSearchText(value);
            searchTimeoutRef.current = null;
        }, 500);
    };

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Clear any pending timeout
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
                searchTimeoutRef.current = null;
            }
            // Apply search immediately
            setAppliedSearchText(searchText);
        }
    };

    const clearSearch = () => {
        // Clear any pending timeout
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
            searchTimeoutRef.current = null;
        }
        setSearchText('');
        setAppliedSearchText('');
    };

    // Model filter handlers
    const toggleModelFilter = () => {
        setShowModelFilter(!showModelFilter);
    };

    const handleModelSelect = (model) => {
        setSelectedModels(prev => {
            if (prev.includes(model)) {
                // Remove the model if already selected
                return prev.filter(m => m !== model);
            } else {
                // Add the model if not already selected
                return [...prev, model];
            }
        });
    };

    const clearModelFilters = () => {
        setSelectedModels([]);
    };

    // Create a consistent color mapping for all models across the table
    // Using only valid variant values from the Badge component
    const modelColorMap = useMemo(() => {
        if (!sortTableData.length) return {};

        // Collect all unique models from all rows
        const allModels = new Set();
        sortTableData.forEach(row => {
            if (row.Make_Model_Names && Array.isArray(row.Make_Model_Names)) {
                row.Make_Model_Names.forEach(model => {
                    if (
                        model &&
                        !model.includes('Undifferentiated') &&
                        !model.includes('Other Model')
                    ) {
                        allModels.add(model);
                    }
                });
            }
        });

        // List of available color variants for badges that are valid
        const colorVariants = [
            "category-blue", "category-green", "category-yellow",
            "category-purple", "category-red", "category-teal"
        ];

        // Map each unique model to a color variant
        const colorMap = {};
        [...allModels].forEach((model, index) => {
            // Cycle through colors if we have more models than colors
            const colorIndex = index % colorVariants.length;
            colorMap[model] = colorVariants[colorIndex];
        });

        return colorMap;
    }, [sortTableData]);

    // Generate filter display text
    const getFilterDisplayText = () => {
        let text = '';

        if (appliedSearchText) {
            text += `Failure Mode: "${appliedSearchText}"`;
        }

        if (selectedModels.length > 0) {
            if (text) text += ' • ';
            if (selectedModels.length === 1) {
                text += `Model: ${selectedModels[0]}`;
            } else {
                text += `Models: ${selectedModels.length} selected`;
            }
        }

        return text;
    };

    // Check if we have any data to display
    const hasFilteredData = filterData.length > 0;

    // Check if we have multiple data points for the chart
    const hasMultipleDataPoints = failureModeChartData.length > 1;

    // Generate the message for no data state
    const getNoDataMessage = () => {
        if (appliedSearchText && selectedModels.length > 0) {
            return `No data matches both "${appliedSearchText}" and the selected models.`;
        } else if (appliedSearchText) {
            return `No failure modes match "${appliedSearchText}".`;
        } else if (selectedModels.length > 0) {
            return `No failure modes found for the selected models.`;
        } else {
            return "No data available.";
        }
    };

    // the main render area
    return (
        <>
            <div className="pageRoute">
                <button className={`routeButton DisableClicked`}>Achieve</button>
                <ChevronRight size={16} color="#D0D5DD" />
                <button className={`routeButton isClicked`}> Issue Overview </button>
            </div>

            <div className="issueDetailsTitleArea">
                <div className="issueDetailsTitleAreaLeft">
                    <span className="pageTitle"> Recurring Issue performance </span>
                </div>
            </div>
            {achievedIssues && (
                <div className="statCardArea">
                    <StatCard label="Total Issues" number={achievedIssues?.Total_Issues} />
                    <StatCard label="Total Recurring Issues" number={achievedIssues?.Total_Recurring_Issues} percentage={Math.round((achievedIssues?.Total_Recurring_Issues / achievedIssues?.Total_Issues) * 100)} />
                </div>
            )}
            <div style={{ width: "100%" }}>
                {/* Search and Filter container */}
                <div className="filters-container">
                    {/* Search Bar - For failure mode only */}
                    <div className="achieve-search-container">
                        <div className={`search-box ${searchText ? "isSearched" : ""}`}>
                            <SearchIcon size={16} color="#667085" />
                            <input
                                type="text"
                                placeholder="Search by Failure Mode..."
                                value={searchText}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearchKeyPress}
                                className="search-input"
                            />
                            {searchText && (
                                <div
                                    className="search-clear-icon"
                                    onClick={clearSearch}
                                    title="Clear search"
                                >
                                    <CloseIcon size={16} color="var(--color-gray-500)" />
                                </div>
                            )}
                        </div>

                        {/* Model Filter Button */}
                        <button
                            className={`model-filter-button ${selectedModels.length > 0 ? 'active' : ''}`}
                            onClick={toggleModelFilter}
                        >
                            <FilterIcon size={16} color={selectedModels.length > 0 ? "#1570EF" : "var(--color-gray-700)"} />
                            <span>Filter Models</span>
                            {selectedModels.length > 0 && (
                                <span className="filter-badge">{selectedModels.length}</span>
                            )}
                        </button>
                    </div>

                    {/* Model Filter Dropdown */}
                    {showModelFilter && (
                        <div className="model-filter-dropdown">
                            <div className="model-filter-header">
                                <h4>Filter by Model</h4>
                                {selectedModels.length > 0 && (
                                    <button
                                        className="clear-model-filters"
                                        onClick={clearModelFilters}
                                    >
                                        Clear all
                                    </button>
                                )}
                            </div>
                            <div className="model-checkbox-list">
                                {allModels.map((model, idx) => (
                                    <div key={idx} className="model-checkbox-item">
                                        <input
                                            type="checkbox"
                                            id={`model-${idx}`}
                                            checked={selectedModels.includes(model)}
                                            onChange={() => handleModelSelect(model)}
                                        />
                                        <label htmlFor={`model-${idx}`}>
                                            <Badge
                                                type="category"
                                                variant={modelColorMap[model] || "category-blue"}
                                            >
                                                {model}
                                            </Badge>
                                        </label>
                                    </div>
                                ))}
                                {allModels.length === 0 && (
                                    <div className="no-models-message">No models available</div>
                                )}
                            </div>
                            <div className="model-filter-footer">
                                <button
                                    className="apply-model-filters"
                                    onClick={toggleModelFilter}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {hasFilteredData ? (
                <>
                    {/* Only show the chart if there are multiple data points */}
                    {hasMultipleDataPoints && (
                        <div className="chartOverview">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: "100%"
                            }}>
                                <ChartGraph
                                    chartType="failureModeChart"
                                    chartArr={failureModeChartData}
                                    defaultWidth="100%"
                                    defaultHeight='675px'
                                    yAxisLabel='Recurring Issue Count'
                                    isIssueOverviewData={false}
                                    hideViewDetails={true}
                                    truncateLabels={true}
                                    maxLabelLength={40}
                                    chartTitle="Most Common Recurring Failure Modes"
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ width: "100%", marginTop: "5px" }}>
                        <SortTable
                            width="100%"
                            data={filterData}
                            appliedSearchText={getFilterDisplayText()}
                            columns={[{
                                key: "Group Descriptor",
                                label: "Failure Mode",
                                width: "30%",
                                searchable: true,
                                render: (value, rowData) => {
                                    // Use row id for tracking expanded state
                                    const rowId = rowData.ID || rowData["Group Descriptor"];
                                    const isExpanded = expandedFailureModes[rowId];

                                    return (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleExpandFailureMode(rowId);
                                            }}
                                            className={`failure-mode-text ${isExpanded ? 'expanded' : 'collapsed'}`}
                                            title={!isExpanded ? value : ""}
                                        >
                                            {value}
                                        </div>
                                    );
                                }
                            }, {
                                key: "Issue_Count",
                                label: "Count",
                                width: "10%",
                                sortType: "number",
                                searchable: false
                            }, {
                                key: 'percentage',
                                label: 'Frequency',
                                width: "10%",
                                sortType: "number",
                                searchable: false,
                                render: (value) => {
                                    return <span>{value.toFixed(1)}%</span>;
                                }
                            }, {
                                key: 'Total_Cost',
                                label: 'Total Cost',
                                width: "15%",
                                sortType: "number",
                                searchable: false,
                                render: (value) => (
                                    <span>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}</span>
                                )
                            }, {
                                key: 'Make_Model_Names',
                                label: 'Models',
                                width: "35%",
                                searchable: true,
                                render: (value, rowData) => {
                                    if (!value || !Array.isArray(value)) return "-";

                                    // Filter out unwanted model names
                                    const filteredModels = value.filter(model =>
                                        model &&
                                        !model.includes('Undifferentiated') &&
                                        !model.includes('Other Model')
                                    );

                                    // If no models remain after filtering, show a dash
                                    if (filteredModels.length === 0) return "-";

                                    // Use row id to track expanded state (assuming ID field exists, if not use Group Descriptor)
                                    const rowId = rowData.ID || rowData["Group Descriptor"];
                                    const isExpanded = expandedModelRows[rowId];

                                    // Default show 5 if not expanded, show all if expanded
                                    const showCount = isExpanded ? filteredModels.length : Math.min(filteredModels.length, 5);
                                    const remainingCount = filteredModels.length - showCount;

                                    const handleMoreClick = (e) => {
                                        // Stop event propagation to prevent row selection
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // Toggle the expanded state for this row
                                        toggleExpandModels(rowId);
                                    };

                                    return (
                                        <div
                                            className="model-badge-container"
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '8px',
                                                padding: '12px 0'
                                            }}
                                        >
                                            {filteredModels.slice(0, showCount).map((model, index) => (
                                                <Badge
                                                    key={index}
                                                    type="category"
                                                    variant={modelColorMap[model] || "category-blue"}
                                                >
                                                    {model}
                                                </Badge>
                                            ))}
                                            {!isExpanded && remainingCount > 0 && (
                                                <div
                                                    onClick={handleMoreClick}
                                                    style={{
                                                        cursor: 'pointer',
                                                        display: 'inline-block'
                                                    }}
                                                >
                                                    <Badge
                                                        type="category"
                                                        variant="category-gray"
                                                    >
                                                        +{remainingCount} more
                                                    </Badge>
                                                </div>
                                            )}
                                            {isExpanded && filteredModels.length > 5 && (
                                                <div
                                                    onClick={handleMoreClick}
                                                    style={{
                                                        cursor: 'pointer',
                                                        display: 'inline-block'
                                                    }}
                                                >
                                                    <Badge
                                                        type="category"
                                                        variant="category-gray"
                                                    >
                                                        Show less
                                                    </Badge>
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                            }]}
                            rowHeight="auto"
                            flex={1}
                        />
                    </div>
                </>
            ) : (
                <div className="no-data-container">
                    <div className="no-data-message">
                        <FilterIcon size={48} color="var(--color-gray-300)" />
                        <h3>No Matching Data</h3>
                        <p>{getNoDataMessage()}</p>
                        {(appliedSearchText || selectedModels.length > 0) && (
                            <button
                                className="clear-all-filters-button"
                                onClick={() => {
                                    setSearchText('');
                                    setAppliedSearchText('');
                                    setSelectedModels([]);
                                }}
                            >
                                Clear all filters
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default DefaultIssueOverview;