import React from "react";
import PropTypes from "prop-types";

const Refresh = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M17.6452 7.06028C15.3357 4.41489 11.4058 3.6825 8.24971 5.50466C6.09124 6.75085 4.78594 8.90714 4.54052 11.2129C4.48206 11.762 3.98947 12.1599 3.44029 12.1014C2.89111 12.0429 2.49329 11.5504 2.55175 11.0012C2.86193 8.08711 4.51421 5.35195 7.24971 3.77261C11.3786 1.38882 16.553 2.45659 19.4319 6.08067L19.5406 5.6752C19.6835 5.14174 20.2318 4.82516 20.7653 4.9681C21.2988 5.11104 21.6154 5.65938 21.4724 6.19284L20.7404 8.92489C20.6717 9.18107 20.5041 9.39949 20.2744 9.5321C20.0448 9.66471 19.7718 9.70064 19.5156 9.632L16.7836 8.89995C16.2501 8.75701 15.9335 8.20867 16.0765 7.6752C16.2194 7.14174 16.7677 6.82516 17.3012 6.9681L17.6452 7.06028ZM20.5588 11.8987C21.108 11.9571 21.5058 12.4497 21.4474 12.9989C21.1372 15.913 19.4849 18.6481 16.7494 20.2275C12.6208 22.6112 7.44661 21.5436 4.5676 17.9199L4.45909 18.3249C4.31615 18.8584 3.76782 19.1749 3.23435 19.032C2.70088 18.8891 2.3843 18.3407 2.52724 17.8073L3.25929 15.0752C3.40223 14.5417 3.95057 14.2252 4.48404 14.3681L7.21609 15.1001C7.74955 15.2431 8.06614 15.7914 7.9232 16.3249C7.78025 16.8584 7.23192 17.1749 6.69845 17.032L6.35372 16.9396C8.66323 19.5852 12.5933 20.3176 15.7494 18.4954C17.9079 17.2492 19.2132 15.0929 19.4586 12.7872C19.5171 12.238 20.0096 11.8402 20.5588 11.8987Z"
      clipRule="evenodd"
    />
  </svg>
);

Refresh.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default Refresh;