// Navigation.js
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import PropTypes from "prop-types";
import "./Navigation.css";
import { useAppContext } from "../../../AppContext";
import { ChevronDownIcon } from "../../icons";
import userImage from "../../../image/user.png";
import { LogoutIcon, User, TeamIcon } from "../../icons/General";
import useAuthStore from "../../../stores/useAuthStore";
import { useAuth0 } from "@auth0/auth0-react";

initializeIcons(); // please becarefult remove it ==> there is still some icons in drive module and used this

const Navigation = ({ menuItems, isLoggedIn, companyLogo }) => {
  const { selectedMenuItem, setSelectedMenuItem, setClickedIssue, isSharedView, setSharedIssuedArray, setIsSharedView } = useAppContext();
  const location = useLocation(); // get the data from URL
  const navigate = useNavigate(); // jump to other module
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // the navigation dropdown menu open or closed: team, profile, and Log out
  const dropdownRef = useRef(null); // the ref used for auto closed of dropdown menu

  const { clearAuth } = useAuthStore();
  const { logout } = useAuth0();

  const queryCache = useRef({}); // the user searched query

  const handleClick = (menuItem) => {
    setSelectedMenuItem(menuItem);

    if (menuItem === "discover") {
      const lastDiscover = localStorage.getItem("discoverQuery") || "";
      navigate(`/discover${lastDiscover}`);
    } else if (menuItem === "achieve") {
      // return back the last page of achieve module
      const lastAchievePath = localStorage.getItem("lastAchievePath") || "dashboard";
      navigate(`/achieve/${lastAchievePath}`);
    } else {
      navigate(`/${menuItem}`);
    }

    // if we from share view page go to our general page, it will setup false
    if (isSharedView) {
      setIsSharedView(false);
      setSharedIssuedArray([]);
    }
  };


  // listner the location and navigation menu change ==> jump to correct page
  useEffect(() => {
    const path = location.pathname.slice(1);
    const query = location.search;

    //console.log("Current Path:", location.pathname);

    if (path.startsWith("discover")) {
      if (query) {
        queryCache.current.discover = query;
        localStorage.setItem("discoverQuery", query); // get the discover search query
      }
    } else if (path.startsWith("achieve/")) {
      const subPath = path.replace("achieve/", ""); // get the achiecve route
      localStorage.setItem("lastAchievePath", subPath);
      setSelectedMenuItem("achieve")
    }

    // add the selected effect of navigation bar 
    if (["discover", "drive", "achieve", "user-account"].includes(path)) {
      setSelectedMenuItem(path);
    }
  }, [location, setSelectedMenuItem]);


  // when the suer closed the Tab, it will clear the user search history in the local storage
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("discoverQuery"); // user search history
      localStorage.removeItem('savedOpenKeySpec'); // the open history
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // the account dropdown menu open function
  const handleAccountClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // the dropdown menu select item click function: profile and team page
  const handleDropdownItemClick = (path) => {
    navigate(path);
    setSelectedMenuItem(path);
    setIsDropdownOpen(false);
  };

  // the logout function
  const handleLogOut = async () => {
    // Clear local state immediately
    setClickedIssue(null);
    clearAuth();
    
    // Handle Auth0 logout in the background
    await logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    }).catch(error => {
      console.error('Logout error:', error);
    });
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav
      className={`navigation ${isLoggedIn ? "navigation-post-login" : "navigation-pre-login"}`}
    >
      <div className="navigation-content">
        <div className="navigation-left">
          {isLoggedIn && (
            <img
              src={companyLogo}
              alt="Company Logo"
              className="navigation-logo-pre-login"
              onClick={() => handleClick('discover')}
            />
          )}

          {isLoggedIn && (
            <div className="menu-items">
              {menuItems.map((item) => (
                <button
                  key={item.key}
                  onClick={() => handleClick(item.key)}
                  className={`menu-item ${selectedMenuItem === item.key ? "selected" : ""}`}
                >
                  <div className="menu-item-content">
                    {item.icon}
                    <span>{item.name}</span>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="auth-buttons" ref={dropdownRef}>
          {isLoggedIn && (
            <div className="account-dropdown">
              <button
                className="button-account"
                onClick={handleAccountClick}
              >
                <img
                  src={userImage}
                  alt="User"
                  className="userLogo"
                  draggable="false"
                />
                  <ChevronDownIcon className={`arrow-icon ${isDropdownOpen ? 'open' : ''}`}/>
              </button>

              {isDropdownOpen && <div className={`naviDropdown-menu ${isDropdownOpen ? "show" : ""}`}>
                <button onClick={() => handleDropdownItemClick("/user-account")} className="naviDropdown-item">
                  <User /> Profile
                </button>
                <button onClick={() => handleDropdownItemClick("/team")} className="naviDropdown-item">
                  <TeamIcon /> Team
                </button>
                <button onClick={handleLogOut} className="naviDropdown-Logout">
                  <LogoutIcon /> Log out
                </button>
              </div>}

            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  menuItems: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  companyLogo: PropTypes.string.isRequired,
};

export default Navigation;
