import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { format, parse, isValid, startOfMonth, endOfMonth, addMonths, subMonths, 
         eachDayOfInterval, getDay, addDays, isSameDay, isAfter, isBefore, isSameMonth, 
         startOfWeek, endOfWeek } from "date-fns";
import "./DatePicker.css";
import Button from "../../ui/Button/Button";
import { ChevronLeft, ChevronRight } from "../../icons/Arrows";
import { ChevronDownIcon } from "../../icons";
import { Calendar } from "../../icons/General";
import SwitchButton from "../../keySpecs/SwitchImageButton/SwitchButton";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabelItem } from "../../ui/DropdownMenu/DropdownMenu";

const DatePicker = ({
  width = "460px",
  height = "428px",
  initialMode="Since",
  startDate,
  endDate,
  onApply,
}) => {
  const datePickerRef = useRef(null); // listener if user click outside, it wil daynmic closed

  const [mode, setMode] = useState(initialMode);
  const [isOpen, setIsOpen] = useState(false); // the state that user click to open the calendar
  const [selectedStart, setSelectedStart] = useState(startDate); // the user select start date
  const [selectedEnd, setSelectedEnd] = useState(endDate); // the user select end date
  const [hoverDate, setHoverDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date()); // the current month

  const [isSelectingStart, setIsSelectingStart] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target) &&
        !event.target.closest(".dropdown-menu-content")
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // the mode cahnge function
  const handleModeChange = (newMode) => {
    setMode(newMode);
    
    // if the since mode, setup the end date was today
    if (newMode === "Since" && selectedStart) {
      setSelectedEnd(today);
    }
  };
  
  // Get today's date, for use in "Since" mode
  const today = new Date();

  // Process the state that user input value
  const [inputStartValue, setInputStartValue] = useState(
    selectedStart ? format(selectedStart, "MM/dd/yyyy") : ""
  );
  const [inputEndValue, setInputEndValue] = useState(
    selectedEnd ? format(selectedEnd, "MM/dd/yyyy") : ""
  );

  // Update input value when date changes
  useEffect(() => {
    if (selectedStart) {
      setInputStartValue(format(selectedStart, "MM/dd/yyyy"));
    } else {
      setInputStartValue("");
    }
    
    if (selectedEnd) {
      setInputEndValue(format(selectedEnd, "MM/dd/yyyy"));
    } else {
      setInputEndValue("");
    }
  }, [selectedStart, selectedEnd]);

  // Handle changes in the start date input box
  const handleInputStartChange = (e) => {
    const value = e.target.value;
    setInputStartValue(value);

    try {
      const parsedDate = parse(value, "MM/dd/yyyy", new Date());
      
      if (isValid(parsedDate)) {
        setSelectedStart(parsedDate);
        setCurrentMonth(parsedDate);
        
        if (mode === "Since") {
          setSelectedEnd(today);
        } else if (selectedEnd && isAfter(parsedDate, selectedEnd)) {
          setSelectedEnd(null);
          setInputEndValue("");
        }
      }
    } catch (error) {
     // Invalid date format, no action will be taken
    }
  };

  // Processing end date input box changes
  const handleInputEndChange = (e) => {
    const value = e.target.value;
    setInputEndValue(value);

    try {
      const parsedDate = parse(value, "MM/dd/yyyy", new Date());
      
      if (isValid(parsedDate) && selectedStart && !isBefore(parsedDate, selectedStart)) {
        setSelectedEnd(parsedDate);
        setCurrentMonth(parsedDate);
      }
    } catch (error) {
      // Invalid date format, no action will be taken
    }
  };

  // Generate calendar data for the current month
  const generateCalendarDays = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(currentMonth);
    let startDate = startOfWeek(monthStart);
    let endDate = endOfWeek(monthEnd);
  
    let days = eachDayOfInterval({ start: startDate, end: endDate });
  
    // make every page 6 weekes (42 days)
    while (days.length < 42) {
      endDate = addDays(endDate, 1);
      days = eachDayOfInterval({ start: startDate, end: endDate });
    }
  
    const rows = [];
    let cells = [];
  
    days.forEach((day, i) => {
      if (i % 7 === 0 && cells.length > 0) {
        rows.push(cells);
        cells = [];
      }
      cells.push(day);
      if (i === days.length - 1) {
        rows.push(cells);
      }
    });
  
    return rows;
  };

  // Handle date clicks
  const handleDateClick = (day) => {
    if (isAfter(day, today)) {
      return; // cannot select a future date
    }

    if (mode === "Since") {
      setSelectedStart(day);
      setSelectedEnd(today);
    } else {
      if (!selectedStart || (selectedStart && selectedEnd)) {
        setSelectedStart(day);
        setSelectedEnd(null);
        setIsSelectingStart(false); // swicth the end date highlight
      } else {
        if (isBefore(day, selectedStart)) {
          setSelectedStart(day);
          setSelectedEnd(null);
        } else {
          setSelectedEnd(day);
          setIsSelectingStart(true); // swicth the end date highlight
        }
      }
    }
  };

  // Generate an array of optional years
  const getYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    
    for (let i = currentYear - 30; i <= currentYear; i++) {
      years.push(i);
    }
    
    return years;
  };

  // Generate month array
  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  // Calculate the date class name
  const getDayClassName = (day) => {
    let className = "calendar-day";
    
    // 1. Check if it is the date of the current month
    if (!isSameMonth(day, currentMonth)) {
      className += " outside-month";
      className += " disabled";//add the click => can not click outside of current months
    }
    
    // 2. Check if it is today
    if (isSameDay(day, today)) {
      className += " today";
    }
    
    // 3. Check if it is an unselectable date (future date)
    if (isAfter(day, today)) {
      className += " disabled";
    }
    
    // 4. Check if it is the selected date (Start Date)
    if (selectedStart && isSameDay(day, selectedStart)) {
      className += " selected start-date";
    }
    // 4. Check if it is the selected date (End Date)
    if (selectedEnd && isSameDay(day, selectedEnd)) {
      className += " selected end-date";
    }
    
   // 5. Check if the date is within the range
    if (selectedStart && selectedEnd && isAfter(day, selectedStart) && isBefore(day, selectedEnd)) {
      className += " in-range";
    }
    
    return className;
  };

  // Apply button click processing
  const handleApply = () => {
    onApply({
      startDate: selectedStart,
      endDate: mode === "Since" ? today : selectedEnd
    });
    setIsOpen(false); // apply, then closed the calender
  };

  // Switch to the previous month
  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  // Switch to the next month
  const handleNextMonth = () => {
    // Prevent future months date from being selected
    const nextMonth = addMonths(currentMonth, 1);
    if (!isAfter(startOfMonth(nextMonth), today)) {
      setCurrentMonth(nextMonth);
    }
  };

  // Handling year changes
  const handleYearChange = (year) => {
    const yearIndex = parseInt(year);
    const newDate = new Date(currentMonth);
    newDate.setFullYear(yearIndex);

    // Prevent future years from being selected
    if (!isAfter(startOfMonth(newDate), today)) {
      setCurrentMonth(newDate);
    }
  };

  const calendarRows = generateCalendarDays();
  const years = getYears();


  const handleInternalCancel = () => {
    // closed the Calendar
    setIsOpen(false);
    
    // redefine the start date and end date
    setSelectedStart(startDate);
    setSelectedEnd(endDate);
    
    // redefine the input value
    setInputStartValue(startDate ? format(startDate, "MM/dd/yyyy") : "");
    setInputEndValue(endDate ? format(endDate, "MM/dd/yyyy") : "");
  };

  // when user input start date, hight the input area border
  const handleStartDateFocus = () => {
    setIsSelectingStart(true);
  };

  // when user input end date, hight the input area border
  const handleEndDateFocus = () => {
    setIsSelectingStart(false);
  };

  return <div ref={datePickerRef} className="datePicker">
    <div
      className="selectDateArea"
      onClick={() => setIsOpen(!isOpen)}
    >
      <Calendar size={24} color="#667085" />
      <span className="date-text">
        {selectedStart && selectedEnd
          ? `${format(selectedStart, "MMM dd, yyyy")} - ${format(selectedEnd, "MMM dd, yyyy")}`
          : "MM DD, YYYY - MM DD, YYYY"
        }
      </span>
      <ChevronDownIcon size={24} color="#667085" className={`arrowDown-icon ${isOpen ? 'open' : ''}`} />
    </div>

    {isOpen && (<div className="datePickerArea" style={{ height: height, width: width }}>
      <div className="date-picker">
        { }
        <div className="date-picker-sidebar">
          <button
            className={mode === "Since" ? "active" : ""}
            onClick={() => handleModeChange("Since")}
          >
            Since
          </button>
          <button
            className={mode === "Between" ? "active" : ""}
            onClick={() => handleModeChange("Between")}
          >
            Between
          </button>
        </div>

        {/* date picker content */}
        <div className="date-picker-content">
          <div className="calendar-header">
            <div className="month-selector">
              <SwitchButton
                Icon={ChevronLeft}
                onClick={handlePrevMonth}
                shape="circle"
              />
              <span className="monthText">{months[currentMonth.getMonth()]}</span>
              <SwitchButton
                Icon={ChevronRight}
                onClick={handleNextMonth}
                shape="circle"
                disable={isAfter(startOfMonth(addMonths(currentMonth, 1)), today)}
              />
            </div>

            <div className="year-nav">
              <DropdownMenu>
                <DropdownMenuTrigger height="32px">
                  <span className="dropdown-menu-trigger-text">{currentMonth.getFullYear()}</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent width="100px">
                  <div className="yearSelectionMenuArea">
                    {years.map((year) => (
                      <DropdownMenuLabelItem
                        key={year}
                        checked={currentMonth.getFullYear() === year}
                        onSelect={() => handleYearChange(year)}
                      >
                        {year}
                      </DropdownMenuLabelItem>
                    ))}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>

          <div className="calendar-body">
            <div className="calendar-weekdays">
              <div className="weekday">Su</div>
              <div className="weekday">Mo</div>
              <div className="weekday">Tu</div>
              <div className="weekday">We</div>
              <div className="weekday">Th</div>
              <div className="weekday">Fr</div>
              <div className="weekday">Sa</div>
            </div>

            <div className="calendar-days">
              {calendarRows.map((row, i) => (
                <div key={i} className="calendar-row">
                  {row.map((day, j) => (
                    <div
                      key={j}
                      className={getDayClassName(day)}
                      onClick={() => {
                        if (isSameMonth(day, currentMonth) && !isAfter(day, today)) {
                          handleDateClick(day);
                        }
                      }}
                      onMouseEnter={() => setHoverDate(day)}
                      onMouseLeave={() => setHoverDate(null)}
                    >
                      {format(day, "d")}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* footer area */}
      <div className="date-picker-footer">
        {mode === "Since" && <div>Since</div>}
        <input
          type="text"
          className={`date-input ${isSelectingStart ? 'highlight-border' : ''}`}
          placeholder="MM/DD/YYYY"
          value={inputStartValue}
          onChange={handleInputStartChange}
          onFocus={handleStartDateFocus} 
        />

        {mode === "Since" ? <div>to today</div> : <div>-</div>}

        {mode === "Between" && (
          <input
            type="text"
            className={`date-input ${!isSelectingStart ? 'highlight-border' : ''}`}
            placeholder="MM/DD/YYYY"
            value={inputEndValue}
            onChange={handleInputEndChange}
            onFocus={handleEndDateFocus}
          />
        )}

        <div className="date-picker-actions">
          <Button onClick={handleInternalCancel} size="small" variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleApply} size="small" variant="primary">
            Apply
          </Button>
        </div>
      </div>
    </div>)}
  </div>;
};

DatePicker.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  mode: PropTypes.oneOf(["Since", "Between"]),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onApply: PropTypes.func.isRequired,
};

export default DatePicker;