import "./App.css";
import "./CSS Global Style/global.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import companyLogoWithName from "./image/ApiphanyLogo/ApiphanyLogoWithTitle.png";
import { AppProvider } from "./AppContext";
import Navigation from "./Components/layout/Application Navigation/NavigationBar";
import AchieveModule from "./Components/icons/General/AchieveModuleIcon";
import DriveIcon from "./Components/icons/General/DriveModule";
import DiscoverModule from "./Components/icons/General/DiscoverModuleIcon";
import { routes } from "./routes/routes";
import AuthRoute from "./AuthRoute/AuthRoute";
import { useAuth0 } from "@auth0/auth0-react";
import MiniumWidth from "./Pages/Global Component/Minium Width/MiniumWidth";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import useAuthStore from "./stores/useAuthStore";

const AppContent = () => {
  const { isAuthenticated, user } = useAuth0();
  const {user: appUser} = useAuthStore();
  const [showMinWarning, setShowMiniWarning] = useState(false);
  const location = useLocation();

  const menuItems = [
    { name: "Achieve", key: "achieve", icon: <DiscoverModule size={23} color="#667085"/> },
    { name: "Discover", key: "discover", icon: <AchieveModule size={23} olor="#667085"/> },
    { name: "Drive", key: "drive", icon: <DriveIcon size={23} color="#667085"/> },
  ];

  // useEffect to listener the website page width change
  useEffect(() => {
    // check the screen width happened change
    const handleResize = () => {
      setShowMiniWarning(window.innerWidth <= 1024);
    };

    handleResize(); // restart the check width function

    // listener the screen width 
    window.addEventListener('resize', handleResize);

    // clear the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      window.analytics.identify(user?.email, {
        name: user?.name,
        email: user?.email,
      });
    }
  }, [isAuthenticated, user]);

  // only Include Amplitude Session on the front end production deployment part
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const loadScript = (src) => {
        return new Promise((resolve, reject) => {
          if (document.querySelector(`script[src="${src}"]`)) return resolve(); // already loaded
          const script = document.createElement('script');
          script.src = src;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
      };
  
      const loadAmplitude = async () => {
        try {
          await loadScript('https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz');
          await loadScript('https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.8.0-min.js.gz');
  
          if (window.amplitude && window.sessionReplay?.plugin) {
            window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
            window.amplitude.init('a15d98ad6eaccd49a428db1388c18a32');
          } else {
            console.warn('Amplitude or sessionReplay plugin is not available');
          }
        } catch (error) {
          console.error('Failed to load Amplitude scripts', error);
        }
      };
  
      loadAmplitude();
    }
  }, []);
  // the main render and route setup
  return (
    <>
      <Toaster
        position="top-center"
        containerStyle={{
          marginTop: '40px', // the distance of screen top
        }}
        toastOptions={{
          duration: 3000,
          style: {
            fontFamily: 'var(--font-family-body)',
            fontSize: 'var(--font-size-md)',
            zIndex: 1000000,
            padding: '12px 16px',
          },
          success: {
            style: {
              background: 'var(--success-50)',
              color: 'var(--success-700)',
            },
          },
          error: {
            style: {
              background: 'var(--danger-50)',
              color: 'var(--danger-700)',
            },
          },
        }}
      />
      {showMinWarning && <MiniumWidth showMinWarning={showMinWarning} />}

      {isAuthenticated && appUser && (location.pathname !== '/404Error' && location.pathname !== '/register' && location.pathname !== '/pending') && (
        <Navigation
          menuItems={menuItems}
          isLoggedIn={isAuthenticated}
          companyLogo={companyLogoWithName}
        />
      )}

      <div className="layout-container">
        <div className="content">
          <Routes>
            {routes.map(
              ({ path, element, protected: isProtected }, index) => {
                const Component = isProtected ? (
                  <AuthRoute>{element}</AuthRoute>
                ) : (
                  element
                );

                return (
                  <Route key={index} path={path} element={Component} />
                );
              },
            )}
          </Routes>
        </div>
      </div>
    </>
  );
};

// the main render area (App.js)
function App() {
  return (
    <Router>
      <Auth0ProviderWithNavigate>
        <AppProvider>
          <div className="App">
            <AppContent />
          </div>
        </AppProvider>
      </Auth0ProviderWithNavigate>
    </Router>
  );
}


export default App;
