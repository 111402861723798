/*
  this is login component: to check the user enter the username and password
*/
import React, { useEffect} from "react";
import { useLocation } from "react-router-dom";
import "./layout.css";
import companyLogoWithoutName from "../../../image/ApiphanyLogo/ApiphanyIconWithoutName.jpg";
import { FormHeader } from "../../../index";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../../../Components/buttons/login-button";
import useAuthStore from "../../../stores/useAuthStore";

const Login = ({ onLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { user, loading } = useAuthStore();

  const from = location.state?.from || "/";

  useEffect(() => {
    console.log("[Login] isAuthenticated:", isAuthenticated);
    console.log("[Login] navigating to:", from);
    if (isAuthenticated && user) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, user, navigate, from]);

  if (isAuthenticated && loading) {
    return <div>Checking user...</div>;
  }

  return (
    <div className="container">
      <div className="loginComp" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div className="login-container">
          <FormHeader
            title="Log in to your account"
            subtitle="Welcome back! Please enter your details."
            className="form-header"
            companyImage={companyLogoWithoutName}
          />
          <div className="formArea">
              <LoginButton/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
