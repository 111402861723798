import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {fetchUser} from '../../../api/queries';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import useAuthStore from '../../../stores/useAuthStore';

const CallbackPage = () => {
  const { 
    isAuthenticated, 
    user, 
    isLoading: auth0Loading, 
    error: auth0Error 
  } = useAuth0();
  const { getToken } = useAuth0Token();
  const { setAuth, setLoading } = useAuthStore();

  const navigate = useNavigate();
  
  useEffect(() => {
    setLoading(true);
  }, []);

  const { data, error, isLoading, refetch } = useQuery(
    ["user", user?.sub],
    async () => {
      const userData = await fetchUser(getToken);
      setAuth(userData);
      return userData;
    },
    {
      enabled: !auth0Loading && isAuthenticated,
      retry: false,
      onError: async (err) => {
        if (err.response?.status === 404) {
          navigate("/register", {
            state: {
              first_name: user.given_name ?? "",
              last_name: user.family_name ?? "",
              email: user.email ?? "",
            },
          });
        }
        setLoading(false)
      },
    }
  );

  useEffect(() => {
    if (isAuthenticated && user) {
      refetch();
    }
  }, [isAuthenticated, user, refetch]);
  
  useEffect(() => {
    if (data && !isLoading) {
      navigate('/discover');
    }
  }, [data, isLoading, navigate]);

  if (auth0Error) {
    return <div>Error: {auth0Error.message}</div>;
  }

  if (auth0Loading) {
    return <div>Loading authentication...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error && error.response?.status !== 404) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>Welcome {user.name}</h1>
      <p>Your account is being set up...</p>
    </div>
  );
};

export default CallbackPage;
