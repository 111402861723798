import React from "react";
import PropTypes from "prop-types";

const RouteTourIcon = ({ size = 24, color = "black", strokeWidth = 2, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3ZM1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5ZM15.3531 6.06506C14.5699 6.00083 13.4768 6 11.9344 6H11.5C10.9477 6 10.5 5.55228 10.5 5C10.5 4.44772 10.9477 4 11.5 4L11.9846 4C13.4658 3.99999 14.6415 3.99998 15.5166 4.07176C15.9623 4.10831 16.3749 4.16645 16.736 4.26872C17.0962 4.37071 17.4673 4.53362 17.771 4.82093C18.5209 5.53055 18.8532 6.57592 18.6507 7.58832C18.5688 7.99821 18.3599 8.34552 18.1247 8.63678C17.8889 8.92882 17.5856 9.21455 17.2429 9.50177C16.5699 10.0657 15.61 10.7447 14.4007 11.6L10.2952 14.5039C9.0359 15.3946 8.14402 16.0264 7.54167 16.5312C7.24297 16.7815 7.0505 16.9721 6.9314 17.1196C6.83281 17.2417 6.81191 17.3009 6.80978 17.3071C6.74385 17.6425 6.8538 17.9884 7.1013 18.2241C7.10663 18.2279 7.15787 18.2642 7.30887 18.3069C7.49129 18.3586 7.75849 18.4031 8.1469 18.4349C8.93013 18.4992 10.0232 18.5 11.5656 18.5H12.5C13.0523 18.5 13.5 18.9477 13.5 19.5C13.5 20.0523 13.0523 20.5 12.5 20.5H11.5154C10.0343 20.5 8.85853 20.5 7.98341 20.4282C7.53771 20.3917 7.12511 20.3335 6.76395 20.2313C6.40378 20.1293 6.03267 19.9664 5.72904 19.6791C4.97912 18.9694 4.64679 17.9241 4.84925 16.9117C4.93123 16.5018 5.14013 16.1545 5.37529 15.8632C5.61108 15.5712 5.91437 15.2854 6.25714 14.9982C6.93015 14.4343 7.89004 13.7553 9.09927 12.9L13.2048 9.99611C14.4641 9.1054 15.356 8.47355 15.9583 7.96881C16.257 7.71851 16.4495 7.5279 16.5686 7.38039C16.6672 7.25833 16.6881 7.19915 16.6902 7.1929C16.7562 6.85751 16.6462 6.51165 16.3987 6.2759C16.3934 6.27206 16.3421 6.23581 16.1911 6.19305C16.0087 6.1414 15.7415 6.09692 15.3531 6.06506ZM19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17ZM15 19C15 16.7909 16.7909 15 19 15C21.2091 15 23 16.7909 23 19C23 21.2091 21.2091 23 19 23C16.7909 23 15 21.2091 15 19Z"
            clipRule="evenodd"
        />
    </svg>
);

RouteTourIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default RouteTourIcon;