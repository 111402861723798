import React from "react";
import PropTypes from "prop-types";

const ScatterChartIcon = ({ size = 24, color = "black", strokeWidth = 2, ...props }) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={size}
  height={size}
  fill="none"
  viewBox="0 0 24 24"
  {...props}
>
  <path
      fill={color}
      fillRule="evenodd"
      d="M12.303 4.9205C12.1357 4.7458 11.8643 4.7458 11.697 4.9205C11.5296 5.09521 11.5296 5.37847 11.697 5.55318C11.8643 5.72789 12.1357 5.72789 12.303 5.55318C12.4704 5.37847 12.4704 5.09521 12.303 4.9205ZM10.4848 3.65516C11.3216 2.78161 12.6784 2.78161 13.5152 3.65516C13.9049 4.06192 14.1131 4.58638 14.1399 5.11892L17.3192 6.36345C17.3267 6.35537 17.3343 6.34735 17.3419 6.33937C18.1788 5.46583 19.5355 5.46583 20.3724 6.33937C21.2092 7.21291 21.2092 8.6292 20.3724 9.50274C19.5355 10.3763 18.1788 10.3763 17.3419 9.50274C16.9522 9.09597 16.744 8.57152 16.7172 8.03898L13.538 6.79445C13.5305 6.80252 13.5229 6.81055 13.5152 6.81853C12.8181 7.5462 11.7602 7.66771 10.9426 7.18306L7.22857 10.0908C7.39292 10.8199 7.20276 11.6184 6.65809 12.1869C5.82125 13.0605 4.46447 13.0605 3.62763 12.1869C2.79079 11.3134 2.79079 9.89712 3.62763 9.02358C4.32473 8.2959 5.38262 8.17439 6.20021 8.65904L9.91429 5.75132C9.74994 5.02221 9.9401 4.22372 10.4848 3.65516ZM19.1602 7.60471C18.9928 7.43001 18.7215 7.43001 18.5541 7.60471C18.3867 7.77942 18.3867 8.06268 18.5541 8.23739C18.7215 8.4121 18.9928 8.4121 19.1602 8.23739C19.3276 8.06268 19.3276 7.77942 19.1602 7.60471ZM5.4459 10.2889C5.27854 10.1142 5.00718 10.1142 4.83981 10.2889C4.67244 10.4636 4.67244 10.7469 4.83981 10.9216C5.00718 11.0963 5.27854 11.0963 5.4459 10.9216C5.61327 10.7469 5.61327 10.4636 5.4459 10.2889ZM12 9.26316C12.4734 9.26316 12.8571 9.66374 12.8571 10.1579V19.1053C12.8571 19.5994 12.4734 20 12 20C11.5266 20 11.1429 19.5994 11.1429 19.1053V10.1579C11.1429 9.66374 11.5266 9.26316 12 9.26316ZM18.8571 11.9474C19.3305 11.9474 19.7143 12.348 19.7143 12.8421V19.1053C19.7143 19.5994 19.3305 20 18.8571 20C18.3838 20 18 19.5994 18 19.1053V12.8421C18 12.348 18.3838 11.9474 18.8571 11.9474ZM5.14286 14.6316C5.61624 14.6316 6 15.0322 6 15.5263L6 19.1053C6 19.5994 5.61624 20 5.14286 20C4.66947 20 4.28571 19.5994 4.28571 19.1053L4.28571 15.5263C4.28571 15.0322 4.66947 14.6316 5.14286 14.6316Z"
      clipRule="evenodd"
  />
</svg>
);

ScatterChartIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default ScatterChartIcon;