import React from "react";
import "./TimeAllocation.css";

const TimeAllocation = () => {

    return <div>
       TimeAllocation
    </div>
}

export default TimeAllocation;