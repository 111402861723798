// this is the similar issue details componment (windows area) to display the issue slected details
import React, { useState, useCallback } from "react";
import { useAppContext } from "../../../AppContext";
import { generateDynamicStyles } from "../dynamicStyles";
import ResizableDraggablePopup from "./ResizableDraggablePopup";
import { Badge, TabButton, CommentItem } from "../../../Components";
import 'tippy.js/dist/tippy.css';
import { parseWithHighlight } from '../../../Components/tooltip/transformHighlight';

const IssueDetails = () => {
  const { clickedSimilarIssue, setClickedSimilarIssue, highlightArray, isOpenSpecification } =
    useAppContext();
  const [detailsMenu, setDetailsMenu] = useState("Overview");
  const [size, setSize] = useState({ width: 1100, height: "auto" });

  const jira_link = process.env.REACT_APP_NASA_JIRA_LINK

  // used to filter, not will shown in the deatils area ==> including the overview main data and the filtered data
  const OverviewArray = ["Opening date", "Last updated", "Failure Mode", "Other", "Reporter",
    "Resolution", "Issue ID", "All_Comments", "Mitigations steps", "Standards", "Specifications",
    "Cases", "Title", 'Description', 'AreaList', "Area", "Part Number", "Requestor", "Creator", 
    "Quantitative Impact", "Overview", "Date", "FMEAs", "issue_summary", "Simplified Description",
    "Narrative", "Synopsis","Names", "Checked", "Tagged_Synopsis_old", "Tagged_Narrative_old", "Status"
  ];

  // Close the popup
  const closeArea = () => {
    setClickedSimilarIssue(null);
  };

  // Switch menu
  const switchMenu = useCallback(
    (e) => {
      setDetailsMenu(e.target.value);
    },
    [setDetailsMenu],
  );

  // get all the comments of user clciked issue
  const allComments = clickedSimilarIssue?.["All_Comments"] || [];

  // issueDetailsModal have two insildes function => click mouse to update the new position / relase mosue to stop dragging
  return (
    <div>
      {/* Injecting dynamic styles */}
      <style>
        {generateDynamicStyles(
          highlightArray["Failure Mode"],
          highlightArray["Components"],
          highlightArray["Failure Condition"],
          highlightArray["After Effects"],
          highlightArray["Specification"],
        )}
      </style>

      {/*issueDetailsArea  => click the mouse to start dragging the pop window / and update the position based on the Top and left attribute  */}
      <ResizableDraggablePopup
        onClose={closeArea}
        size={size}
        setSize={setSize}
        isOpenArea={isOpenSpecification}
      >
        <div className="detailsHeader">
        {
          clickedSimilarIssue["issue_key"] ? (
            <span className="DetailsTitle">
              Issue Key:{" "}
              <a
                href={`${jira_link}/${clickedSimilarIssue["issue_key"]}`}
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
              >
                {clickedSimilarIssue["issue_key"]}
              </a>
            </span>
          ) : (
            <span className="DetailsTitle">
              Issue ID: {clickedSimilarIssue["Issue ID"]}
            </span>
          )
        }
          <Badge type={"status"} variant={(clickedSimilarIssue["Status"] === "Closed" || clickedSimilarIssue["Status"] === "Open" || clickedSimilarIssue["Status"] === "Current") ? clickedSimilarIssue["Status"] : "Other"} children={clickedSimilarIssue["Status"]} />
        </div>

        <div className="realativeIssueDetailsMenu">
          {/* <TabButton
            label={"Timeline"}
            value={"Timeline"}
            variant={"black"}
            isActive={detailsMenu === "Timeline" ? true : false}
            isDisable={true}
            onClick={(e) => switchMenu(e)}
          />*/}
          <TabButton
            label={"Overview"}
            value={"Overview"}
            variant={"black"}
            isActive={detailsMenu === "Overview" ? true : false}
            onClick={(e) => switchMenu(e)}
          />  
          <TabButton
            label={"Details"}
            value={"Details"}
            variant={"black"}
            isActive={detailsMenu === "Details" ? true : false}
            onClick={(e) => switchMenu(e)}
          />
          <TabButton
            label={"Comments"}
            value={"Comments"}
            variant={"black"}
            isDisable={allComments.length === 0}
            isActive={detailsMenu === "Comments" ? true : false}
            onClick={(e) => switchMenu(e)}
          />
        </div>

        <div style={{ maxHeight: "600px", overflowY: "auto", width: "100%" }}>
          {/**********************************   1. the overview part data ****************************************************/}
          {detailsMenu === "Overview" && (
            <div className="issueFormat">
              <div className="issueFormatChild" style={{ marginRight: "4px" }}>

                <div className="issueDetailsRowDisplay">
                  <span className="DetailsSubTitle">Created: </span> {(clickedSimilarIssue["Opening date"]).split(' ')[0]}
                  <span className="DetailsSubTitle" style={{ marginLeft: "16px" }}>Last updated: </span> {(clickedSimilarIssue["Last updated"]).split(' ')[0]}
                  {/* <span className="DetailsSubTitle" style={{ marginLeft: "16px" }}>Issue ID: </span> {clickedSimilarIssue["Issue ID"]}  */}
                  <span className="DetailsSubTitle" style={{ marginLeft: "16px" }}>Case: </span> {clickedSimilarIssue["Cases"] ? clickedSimilarIssue["Cases"] : "1"}
                </div>

                <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">Title </span>
                  {clickedSimilarIssue ? (
                    <div>
                      {parseWithHighlight(clickedSimilarIssue["Title"])}
                    </div>
                  ) : (
                    " "
                  )}
                </div>

                {clickedSimilarIssue["Description"] && <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">Issue Description </span>
                  {clickedSimilarIssue ? (
                    <div>
                      {parseWithHighlight(clickedSimilarIssue["Description"])}
                    </div>
                  ) : (
                    " "
                  )}
                </div>}

                <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">System </span>
                  <div className="detailsAreaValue">
                    {clickedSimilarIssue && clickedSimilarIssue["AreaList"]
                      ? clickedSimilarIssue["AreaList"].map((area, index) => (
                        <span key={index} className="areaItem">
                          {area}
                        </span>
                      ))
                      : clickedSimilarIssue["Area"]
                        ? clickedSimilarIssue["Area"]
                          .split(", ")
                          .map((area, index) => (
                            <span key={index} className="areaItem">
                              {area}
                            </span>
                          ))
                        : " "
                    }
                  </div>

                  {clickedSimilarIssue["Part Number"] && <div style={{ display: "flex" }}>
                    <span className="DetailsSubTitle"> Part Number: </span> {clickedSimilarIssue["Part Number"]}
                  </div>}
                </div>

                {clickedSimilarIssue["Failure Mode"] && <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">Failure Mode</span>
                  <div>
                      {parseWithHighlight(clickedSimilarIssue["Failure Mode"])}
                  </div>
                </div>
                }

                {clickedSimilarIssue["Other"] && <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">Additional information:</span>
                  {clickedSimilarIssue["Other"]}
                </div>}

                <div className="issueDetailsRowDisplay">
                  {[
                    clickedSimilarIssue["duplicates"] && (
                      <span key="duplicates">
                        <strong>
                          {clickedSimilarIssue["is_parent"] ? "Is parent of:" : "Duplicates:"}
                        </strong>{" "}
                        {clickedSimilarIssue["duplicates"]}
                      </span>
                    )
                  ]
                    .filter(Boolean)
                    .reduce((acc, item) =>
                      acc.length === 0
                        ? [item]
                        : [...acc, <span key={`sep-${acc.length}`}>&nbsp; &nbsp;</span>, item]
                    , [])}
                </div>

                <div className="issueDetailsRowDisplay">
                  {[
                    clickedSimilarIssue["Requestor"] && (
                      <span key="requestor">
                        <strong>Owner:</strong> {clickedSimilarIssue["Requestor"]}
                      </span>
                    ),
                    clickedSimilarIssue["Creator"] && (
                      <span key="creator">
                        <strong>Creator:</strong> {clickedSimilarIssue["Creator"]}
                      </span>
                    ),
                    clickedSimilarIssue["Reporter"] && (
                      <span key="reporter">
                        <strong>Reporter:</strong> {clickedSimilarIssue["Reporter"]}
                      </span>
                    )
                  ]
                    .filter(Boolean)
                    .reduce((acc, item) => (
                      acc.length === 0
                        ? [item]
                        : [...acc, <span key={`sep-${acc.length}`}>&nbsp; &nbsp;</span>, item]
                    ), [])}
                </div>
              </div>
              <div className="issueFormatChild" style={{ marginRight: "4px" }}>
                {clickedSimilarIssue["Mitigations steps"] && <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">Mitigation Steps</span>
                  {clickedSimilarIssue["Mitigations steps"]}
                </div>}

                {clickedSimilarIssue["Resolution"] && <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">Resolution</span>
                  {clickedSimilarIssue["Resolution"]}
                </div>}

                {clickedSimilarIssue["Quantitative Impact"] && <div className="issueDetailsColumnDisplay">
                  <span className="DetailsTitle">Financial Impact</span>
                  {clickedSimilarIssue["Quantitative Impact"] ? clickedSimilarIssue["Quantitative Impact"] : ""}
                </div>}
              </div>


              {/* <div className="issueDetailsColumnDisplay">
                <div style={{ display: "flex", alignItems: "center" }}><span className="DetailsTitle">Attachments</span> <p className="downloadText">Download all</p></div>
                <div className="attachmentsArea">
                  <Badge type="file" variant="default" icon={<DocxIcon/>}>
                    Resolutions 6/05.docx
                  </Badge>
                  <Badge type="file" variant="default" icon={<span><PdfIcon/></span>}>
                    Resolutions 6/05.pdf
                  </Badge>
                  <Badge type="file" variant="default" icon={<span><PdfIcon/></span>}>
                    Resolutions 6/05.pdf
                  </Badge>
                </div>
              </div> */}
            </div>
          )}

          {/* the Comments part data */}
          { detailsMenu === "Comments" && <div className="issueGrayFormat">
              {allComments.map((item, index) => (
                <CommentItem
                  key={index}
                  author={item.author}
                  timestamp={item.timestamp}
                  context={item.content}
                  defaultWidth="calc(100% - 24px)"
                />
              ))}
            </div>
          }

          {/**********************************  2.the Details part data ****************************************************/}
          {detailsMenu === "Details" && clickedSimilarIssue && Object.keys(clickedSimilarIssue).length > 0 && (
            <div className="issueFormat">
              <div className="issueDetailsFormat" style={{ width: "100%" }}>
                {Object.entries(clickedSimilarIssue)
                  .filter(([key]) => !OverviewArray.includes(key))
                  .map(([key, value]) => {
                    if (!value) return null;

                    // judge whether the value is too long
                    const isLongValue = value.length > 70; 

                    return (
                      <div
                        key={key}
                        className={`issueDetailsColumnArea ${isLongValue ? "fullWidth" : ""}`}
                      >
                        <span className="DetailsHeader">{key}</span>
                        <div style={{ width: "calc(100% - 24px)" }}>
                          {parseWithHighlight(value) || value}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>

      </ResizableDraggablePopup>
    </div>
  );
};

export default IssueDetails;
