import React, { useState } from "react";
import './MilestonesFormat.css';
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "../../icons/Arrows";
import SwitchButton from "../../keySpecs/SwitchImageButton/SwitchButton";

const MilestonesFormat = ({
    width,
    height,
    data,
    columns,
    rowHeight,
    flex = 0,
    isLoading,
}) => {
    // the main format start and end Item
    const [currPage, setCurrPage] = useState(1);
    const [rowPage, setRowPage] = useState(25); // control how many rows in one page
    const maxResult = data && Object.keys(data).length;
    const startItem = (currPage - 1) * rowPage;
    const endItem = Math.min(currPage * rowPage, maxResult);

    // switch to next page
    const handleNextPage = () => {
        setCurrPage(currPage => currPage + 1);
    }

    // switch to previous page
    const handlePrevPage = () => {
        setCurrPage(currPage => currPage - 1);
    }

    // the search animation effect dots numbers
    const dots = Array.from({ length: 16 });


    return (
        <div className="MilestonesFormat" style={{ minWidth: width, maxWidth: width, minHeight: height, maxHeight: height, flex: flex}}>
            {/* Table Header */}
            <div className="MilestonesFormat-header">
                {columns.map((col) => (
                    <div
                        key={col.key}
                        className="MilestonesFormatHeader-cell"
                        style={{ minWidth: col.width, maxWidth: col.width }}
                    >
                        {col.label}
                    </div>
                ))}
            </div>

            {/* Table Body */}
            <div className="MilestonesFormat-body">
                {isLoading ? <div className="fade-in">
                    <div className="dots-loader">
                        {dots.map((_, index) => (
                            <div
                                key={index}
                                className="dot"
                                style={{
                                    transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                                    animationDelay: `${index * 0.125}s`
                                }}
                            />
                        ))}
                    </div>
                </div> : <>
                    {Object.values(data).slice(startItem, endItem).map((row, rowIndex) => (
                        <div key={rowIndex} className="MilestonesFormatRowsArea">
                            <div
                                className={`MilestonesFormat-row ${rowIndex % 2 === 0 ? "even" : "odd"}`}
                                style={{ height: rowHeight, position: "relative" }}
                            >
                                {columns.map((col) => (
                                    <div
                                        key={col.key}
                                        className="MilestonesFormat-cell"
                                        style={{ minWidth: col["width"], maxWidth: col["width"] }}
                                    >
                                        <span>{col.render ? col.render(row[col.key], row) : String(row[col.key])}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </>}
            </div>

            {/* Table Footer */}
            <div className="MilestonesFormat-footer">
                <div className="MilestonesFormat-left">
                    {startItem + 1} - {endItem} of {maxResult} Result
                </div>
                <div className="MilestonesFormat-right">
                    <SwitchButton
                        Icon={ChevronLeft}
                        onClick={handlePrevPage}
                        shape="circle"
                        disable={currPage <= 1}
                    />
                    <SwitchButton
                        Icon={ChevronRight}
                        onClick={handleNextPage}
                        shape="circle"
                        disable={endItem >= maxResult}
                    />
                </div>
            </div>
        </div>
    );
};

MilestonesFormat.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string,
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            width: PropTypes.string.isRequired,
        })
    ).isRequired,
    rowHeight: PropTypes.string.isRequired,
    flex: PropTypes.number,
    isLoading: PropTypes.bool,
};

export default MilestonesFormat;