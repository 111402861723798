// The Trends Page Logic: System (Default Status) -> Product Level -> Failure Mode Level -> Action Token Level
// The System Page (Default Page 1)
import React, { useState } from "react";
import './Trends.css';
import { ChevronRight } from "../../../Components/icons/Arrows";
import { TrendsFilter, FormatTable, Badge, DatePicker} from "../../../Components";
import ProductPage from "./ProductPage";
import { useAppContext } from "../../../AppContext";
import { useSystemsQuery } from "../../../api/queries";

const Dashboard = () => {
    // get the state from Appcontext API
    const { selectAchieveSystem, setSelectAchieveSystem, selectedSeries, setSelectedSeries, selectedModel, setSelectedModel,
        setOpenDetailsWindow, clickAchieveSystem, setClickAchieveSystem,
    } = useAppContext();
 
    // // 1. first level: the system search range:
    // const [dateRange, setDateRange] = useState({
    //     startDate: null,
    //     endDate: null
    // });

    // // hanlde get the user select date from date picker component in product search
    // const handleDateApply = (dates) => {
    //     setDateRange(dates);
    // };

    const { data, isLoading, error } = useSystemsQuery({
        model: selectedModel || null,
        series: selectedSeries || null
    });

    // the get color function in the system (default level)
    const getColorVersion = (label) => {
        if (!label) return "category-gray";
        switch (label) {
            case "system":
                return "category-green";
            case "design":
                return "category-green";
            case "Maintenance":
                return "category-red";
            case "component":
                return "category-yellow";
            case "assembly":
                return "category-purple";
            case "supplier quality":
                return "category-blue";
            default:
                return "category-gray";
        }
    };

    // main render area
    return <div className="trendsArea">
        {(!clickAchieveSystem) && (
            <>
                {!clickAchieveSystem && <div className="pageRoute">
                    <button className={`routeButton DisableClicked`}> Achieve </button>
                    <ChevronRight size={16} color="#D0D5DD" />
                    <button className={`routeButton isClicked`}> System Trends </button>
                </div>}

                {/* there is small error muat input somthing will open the dropdown menu area and shiws item */}
                {/* Solution: {(!clickAchieveSystem && !isLoading)  */}
                {(!clickAchieveSystem && !isLoading) && <div className="TrendsFilter">
                    <TrendsFilter
                        data={data}
                        searchItem={selectAchieveSystem}
                        setSearchItem={setSelectAchieveSystem}
                        selectedSeries={selectedSeries}
                        setSelectedSeries={setSelectedSeries}
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel}
                    />

                    {/* <DatePicker
                        width="460px"
                        height="428px"
                        initialMode="Since"
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        onApply={handleDateApply}
                    /> */}
                </div>}

                {(!clickAchieveSystem) && <FormatTable
                        width="100%"
                        height="calc(100vh - 180px)"
                        data={(selectAchieveSystem ? data.filter((item) => item.Name === selectAchieveSystem.value): data) || []}
                        columns={[
                            {
                                key: "Name",
                                label: "System/Component name",
                                width: "35%",
                                render: (value, row) => (
                                    <div className="product-cell">
                                        {value}
                                        {row.Label && (
                                            <Badge type="category" variant={getColorVersion(row.Label)}>
                                                {row.Label}
                                            </Badge>
                                        )}
                                    </div>
                                ),
                            },
                            {
                                key: "Mentions",
                                label: "Mentions",
                                width: "18%",
                            },
                            {
                                key: "Total Servicing Cost",
                                label: "Total Servicing Cost",
                                width: "18%",
                                render: (value) => {
                                    return <span>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}</span>
                                }
                            },
                            {
                                key: "Total Down Time",
                                label: "Servicing days",
                                width: "18%",
                                render: (value) => {
                                    return <div className="Effectiveness-cell">
                                        {value} days
                                    </div>
                                }
                            },
                            { key: "Occurrences", label: "Field Errors", width: "11%" },
                        ]}
                        rowHeight="50px"
                        setOpenDetailsWindow={setOpenDetailsWindow}
                        currentLevel="default"
                        setClickSystem={setClickAchieveSystem}
                        isLoading={isLoading}
                    />}
            </>
        )}

        {clickAchieveSystem && <ProductPage />}

    </div>
}

export default Dashboard