// Action Take Level (4)
import React from "react";
import './Trends.css';
import { useAppContext } from "../../../AppContext";
import { ChevronRight } from "../../../Components/icons/Arrows";
import {Badge, IssueTrendsBar, FormatCompon } from "../../../Components";
import { ChevronDownIcon } from "../../../Components/icons";
import { useActionTakenQuery } from "../../../api/queries";

const ActionTakenPage = () => {
    const { selectedAction, setSelectedAction,
        clickProduct, clickFailureMode, clickAchieveSystem, 
    } = useAppContext();

    const {
        data: actionsTakenData,
        isLoading: actionsDataLoading,
        error: actionsError
    } = useActionTakenQuery({
        group: clickFailureMode.Group_id || null,
        model: null,
        series: null
    });

    // the badge color in the fauiler mode page level
    const getBadgeColor = (label) => {
        switch (label) {
            case "Stayed closed":
                return "PrioritiesLow";
            case "Temporarily resolved":
                return "PrioritiesMedium";
            default:
                return "PrioritiesHigh";
        }
    };

    // the function used to open the nested format (fauiler mode) area
    const handleOccurrenceClick = (row) => {
        setSelectedAction((prev) => (prev === row ? null : row));
    };

    return <>
        <div className="pageRoute">
        <button className={`routeButton DisableClicked`}> Achieve </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton DisableClicked`}> System Trends </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton DisableClicked`}> {clickAchieveSystem.Name} </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton`}>
                {clickProduct.Name}: Failure modes
            </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}>
                {clickFailureMode["Failure Mode"]}
            </button>
        </div>

        <span className="detailWindowTitle">{clickFailureMode["Failure Mode"]}</span>

        <div className="TrendsSingleBarArea">
            <span className="detailWindowTitle">Issue trends: </span>
            <IssueTrendsBar
                width="100%"
                height="14px"
                data={[
                    { name: "Re-opened", occurrences: 10, color: "#FDA29B" },
                    { name: "Temporarily resolved", occurrences: 10, color: "#FEDF89" },
                    { name: "Stayed closed", occurrences: 80, color: "#75E0A7" },
                ]}
            />
        </div>


        <FormatCompon
            width="100%"
            data={actionsDataLoading ? [] : actionsTakenData}
            columns={[
                {
                    key: "Resolutions",
                    label: "Action taken",
                    width: "50%",
                    render: (value, row) => (
                        <div className="actionsTakens-cell">
                            <span className="nameText">{value}</span>
                            {row.Occurrences && (
                                <button className="occureencesButton" onClick={() => handleOccurrenceClick(row)}>
                                    {row.Occurrences} occurrences
                                    <ChevronDownIcon size={20} className={`occureencesButton-icon ${row === selectedAction ? 'open' : ''}`} />
                                </button>
                            )}
                        </div>
                    ),
                },
                {
                    key: "Total action cost",
                    label: "Total cost",
                    width: "15%",
                    render: (value) => (
                        <span>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}</span>
                    ),
                },
                {
                    key: "Effectiveness",
                    label: "Effectiveness",
                    width: "15%",
                    render: (value) => (
                        <div className="Effectiveness-cell">
                            <Badge type="priorities" variant={getBadgeColor(value)}>{value}</Badge>
                        </div>
                    ),
                },
                {
                    key: "Exp. required",
                    label: "Exp. Required",
                    width: "10%",
                },
                {
                    key: 'Avg. days of solution',
                    label: "Avg. Res Day",
                    width: "10%",
                    render: (value) => (
                        <div className="Effectiveness-cell">
                            {value} days
                        </div>
                    ),
                }
            ]}
            rowHeight="50px"
            selectedAction={selectedAction}  // Pass the expandedRowIndex to FormatCompon
            flex={1}
            isLoading={actionsDataLoading}
        />

    </>
}

export default ActionTakenPage;