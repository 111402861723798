// this is tempoary logic used for demo and future key spec
// Issues: 485102, 444879, 566448, 442643, 574577
import React, { useRef, useEffect } from "react";
import { useAppContext } from "../../../AppContext";
import "./DemoKeySpec.css";
import { AccordionItems } from "../../../Components/index";
import { SpeciDataCard } from "../../../Components/keySpecs/DataCard/index"
import { useShareSpecs } from "../../../api/queries";
import { 
  useOrganizationUsersQuery
} from '../../../api/queries';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';
import {NotificationComp} from "../../../Components/index";
import { Search } from "../../../Components/icons/General";

// 1. menuTitle: Specifications or Standards
// 2. subMenuTitle: dynamic create based on difference issue id key spec data
const DemoKeySpecFormat = ({issueID, menuTitle, subMenuTitle}) => {
  const { clickedIssue, openItem, setOpenItem, 
    openDataCard, setOpenDataCard, specData,
    setAdditionalArray, setSpecificationArray, setSpecMenu, setStandMenu,
  } = useAppContext();
  const { "*": token } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // console.log(specData);

  // the 4 difference kinds tags: query(issue id or issue key), status, system, and requestor
  const queryParam = searchParams.get("query") || ""; // the issue id and issue key only could be single
  const systemParams = searchParams.getAll("system") || []; // system
  const statusParams = searchParams.getAll("status") || []; // status
  const requestorParams = searchParams.getAll("requestor") || []; // requestor
  const keywordsParams = searchParams.getAll("keywords") || []; // the user input tags


  // based on the difference issue id data, get the difference sub menu ==> used to right area sub menu
  useEffect(() => {
    if(specData["Specifications"]){
      const newSpeciArr = Object.keys(specData["Specifications"]);
      setSpecificationArray(newSpeciArr);
      // Only set specMenu if this component is for Specifications
      if (menuTitle === "Specifications") {
        setSpecMenu(newSpeciArr[0]); // select the first sub button as default
      }
    }
    if(specData["Standards"]){
      const newAddtionArr = Object.keys(specData["Standards"]);
      setAdditionalArray(newAddtionArr);
      // Only set standMenu if this component is for Standards
      if (menuTitle === "Standards") {
        setStandMenu(newAddtionArr[0]); // select the first sub button as default
      }
    }
    // eslint-disable-next-line
  }, [issueID, menuTitle]);

  // based on user select the item ==> get the display key specification data
  const filteredSpecs = specData?.[menuTitle]?.[subMenuTitle] || {};
  //console.log(filteredSpecs);

  const containerRef = useRef(null);

  // Listener the user action, auto open the accordion items when it only one accordion and one card
  useEffect(() => {
    if (!containerRef.current) return;

    const checkAndOpenLastItem = () => {
      const container = containerRef.current;
      const hasVerticalScrollbar = container.scrollHeight > container.clientHeight; // check whether have space

      if (!hasVerticalScrollbar && Object.keys(filteredSpecs).length > 0) {
        const lastKey = Object.keys(filteredSpecs).pop(); // get the last one accordionitem tittle used to open it
        if (lastKey) {
          setOpenItem(prev => [...prev, lastKey]); // add the current last one into OpenItem and open it
        }
      }
    };

    checkAndOpenLastItem();
    window.addEventListener("resize", checkAndOpenLastItem);

    return () => {
      window.removeEventListener("resize", checkAndOpenLastItem);
    };
  }, [subMenuTitle]);

  // the share key specification data card feature
  // 1. get the could share team member array
  const { data: teamMembers = [], isLoading: isTeamMemberLoading } = useOrganizationUsersQuery();

  // 2. call the backend share API function ==> send the share email to selected user
  const shareSpecsMutation = useShareSpecs(); // call the api token to save the data into databse and send the email

  // 3. the share button click function from user selected key specification data card
  const handleShareClick = async (selectedEmails, title) => {
    const values = [ 
      menuTitle, // "Key Specs" or other menu standards
      subMenuTitle, //the sub menu of key spec and 
      title.trim()// The selected accordion item
    ];


    try {
      await shareSpecsMutation.mutateAsync({
        values: values,
        current_issue_id: clickedIssue["Issue ID"].toString(),
        emails: selectedEmails,
        currTagsArr: {
          query: queryParam,
          statusArr: statusParams,
          systemArr: systemParams,
          requestorArr: requestorParams,
          keywordsArr: keywordsParams,
        },
      });

      toast.success('Specs shared successfully');
    } catch (error) {
      toast.error(error.message || 'Failed to share specs');
    }
  };


  // the main render place
  return (
    <>
      <div className="demoKeySpecFormat" ref={containerRef}>
        {Object.keys(filteredSpecs).length === 0 ? (
          <NotificationComp
            width="100%"
            errorTitle= {`Could not found any Industry ${menuTitle}`}
            hasSearchBar={false}
            Icon={Search}
          />
        ) : (
          Object.entries(filteredSpecs).map(([key, value], index) => (
            <AccordionItems
              key={`${key}-${index}`}
              title={key} // used the key as Index
              defaultWidth="calc(32vw - 74px)"
              openItem={openItem} // save which accorditionItem was open
              setOpenItem={setOpenItem} // save which accorditionItem was open
            >
              {Array.isArray(value)
                ? value.map((data) => (
                  <SpeciDataCard
                    key={`${data.id} ${data.title}`}  
                    title={`${data.id} ${data.title}`} 
                    sourceLinks={data.sourceLinks}
                    defaultWidth="calc(32vw - 105px)"
                    context={data.summary}  
                    openDataCard={openDataCard}
                    setOpenDataCard={setOpenDataCard}
                    teamMember={teamMembers}
                    isTeamMemberLoading={isTeamMemberLoading}
                    onShare={handleShareClick}
                    accordionTitle={key}
                    imageObj={data.imageObj}  
                    showButton={false}
                  />
                ))
                : null}
            </AccordionItems>
          ))
        )}


      </div >
    </>
  );
};

export {DemoKeySpecFormat};