import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

export const Auth0ProviderWithNavigate = ({ children }) => {

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin + process.env.REACT_APP_AUTH0_CALLBACK_URL;

  if (!(domain && clientId && redirectUri)) {
    console.error("Null values for auth0 provider.")
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        organization: process.env.REACT_APP_AUTH0_ORGANIZATION_ID,
        scope: "openid profile email offline_access",
        useRefreshTokens: true,
        cacheLocation: "localstorage"
      }}
    >
      {children}
    </Auth0Provider>
  );
};