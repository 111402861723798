const ISSUE_FIELD_MAPPING = {
  // Source field -> Target field
  'Apiphany_Environment': 'Phase', // TypeError: Cannot read properties of undefined (reading 'Phase')
  'Issue id': 'Issue ID',
  //'Description tags': 'Issue Details',
  'Description tags': 'Description',
  'Root Cause': 'Root cause',
  'Failure_Mode': 'Failure Mode',
  'Countermeasure': 'Mitigations steps',
  //'Summary': 'Issue Summary Without Tag',
  'Summary': 'Title',
  'Status': 'Status',
  'Custom field (Issue Area)': 'System',
  'Custom field (Part Number REV)': 'Part Number',
  'All_Components': 'Area Short Name',
  'Custom field (Business Impact)': 'Quantitative Impact',
  'Resolutions': 'Resolution',
  'FFF Framework': 'Fit Form or Function categorization',
  'Created': 'Date',
  'Custom field (Number or Occurrences)': 'Cases',
  'Assignee': 'Requestor',
  'Creator': 'Creator',
  'Reporter': 'Reporter',
  'Assignee Id': 'Requestor ID',
  'Specs': 'Issue Details Tag',
  'System': 'AreaList',
  "All_Comments": 'All_Comments',
  'Business Impact': 'Business Impact',
  'Delay Impact': 'Delay Impact',
  'Defect Type': 'Defect Type',
  // 'Phase': 'Phase',
  'duplicates': 'duplicates',
  '_is_favorite': 'is_favorite',
  'is_parent': 'is_parent',
  'Overview': 'Overview',
  'Other': 'Other',
  'Opening date': 'Opening date',
  'Last updated': 'Last updated',
  'simplified_Description': 'Simplified Description',
  'issue_summary': 'issue_summary',
  'table_summary_title': 'Table Summary Title',
  'table_summary_description': 'Table Summary Description',
  'table_summary_failure_mode': 'Table Summary Failure Mode'
};

// Helper function to map an issue object according to the defined mapping
const mapIssueFields = (source, isOriginalIssue = false) => {
  const sourceData = isOriginalIssue ? source : source._source;

  // filter all the key attribute with "_1"
  const filteredSourceData = Object.fromEntries(
    Object.entries(sourceData).filter(([key]) => !key.endsWith('_1') && !key.endsWith('_backend'))
  );

  // copy all the data key attribute
  const mappedIssue = { ...filteredSourceData };

  // transver all the key, and map it
  Object.entries(ISSUE_FIELD_MAPPING).forEach(([sourceField, targetField]) => {
    let value = sourceData[sourceField];

    // Special handling for AreaList
    if (targetField === 'AreaList') {
      value = value
        ? value.split(',').map(area => area.trim()).filter(area => area)
        : [];
    }

    // Special handling for duplicates
    if (targetField === 'duplicates') {
      value = Array.isArray(value) ? value.join(', ') : '';
    }

    // Convert `is_favorite` to Boolean
    if (targetField === 'is_favorite') {
      value = Boolean(value);
    } else {
      value = value || ''; 
    }

    // update the mapped data key
    mappedIssue[targetField] = value;

    // removed the source key attribute
    if (sourceField !== targetField) {
      delete mappedIssue[sourceField];
    }
  });

  // format the name in here
  const impactFields = {
    'Delay Impact': 'Delay',
    'Impact': 'Impact',
    'Quantitative Impact': 'Financial Impact',
    'Business Impact': 'Business Impact'
  };

  const formattedImpact = Object.entries(impactFields)
    .map(([key, label]) => mappedIssue[key] ? `${label}: ${mappedIssue[key]}` : null)
    .filter(Boolean)
    .join('\n');

  mappedIssue['Impact'] = formattedImpact;

  // format the fauiler mode place
  if (mappedIssue['Defect Type']) {
    mappedIssue['Failure Mode'] = `Defect Type: ${mappedIssue['Defect Type']}. ${mappedIssue['Failure Mode'] || ''}`;
  }

  return mappedIssue;
};

export { ISSUE_FIELD_MAPPING, mapIssueFields }; 