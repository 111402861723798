import React from "react";
import PropTypes from "prop-types";
const SvgChevronDown = ({
  size = 24,
  color = "var(--icon-color)",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0C111D"
      fillRule="evenodd"
      d="M5.293 8.293a1 1 0 0 1 1.414 0L12 13.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
      clipRule="evenodd"
    />
  </svg>
);
SvgChevronDown.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgChevronDown;
