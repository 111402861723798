import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "./config";
import { mapIssueFields } from '../config/issueMapping';
import toast from 'react-hot-toast';
import { useAuth0Token } from '../hooks/useAuth0Token';

export const fetchUser = async (getToken) => {
  const token = await getToken();
  const response = await api.get('/users/me', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const postUser = async ({ getToken, userData }) => {
  const token = await getToken();
  const response = await api.post('/users', userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Hook for fetching all issue IDs
export const useAllIssueID = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery('allIssueIds', async () => {
    const token = await getToken();
    const response = await api.get('/issues/id', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });  
    return response.data;
  });
};

export const useAllIssueKeys = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery('allIssueKeys', async () => {
    const token = await getToken();
    const response = await api.get('/issues/key', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });  
    return response.data;
  });
};

// Hook for search mutation
export const useSearchMutation = () => {
  const { getToken } = useAuth0Token();
  
  return useMutation(
    async ({ query, statusArr, systemArr, requestorArr, keywordsArr }) => {
      if (!query) {
        throw new Error('Issue ID is required');
      }

      try {
        const token = await getToken();

        // Construct query parameters
        const params = new URLSearchParams();
        params.append('issue_id', query);

        // Append arrays to query parameters
        statusArr?.forEach((status) => params.append('status', status));
        systemArr?.forEach((system) => params.append('system', system));
        requestorArr?.forEach((requestor) => params.append('requestor', requestor));
        keywordsArr?.forEach((keyword) => params.append('keywords', keyword));
        const response = await api.get(`/search?${params.toString()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (error) {
        if (error.response?.data?.detail) {
          throw new Error(error.response.data.detail);
        }
        throw error;
      }
    }
  );
};

// Hook for searching key specifications
export const useSearchKeyMutation = () => {
  const { getToken } = useAuth0Token();
  
  return useMutation(async ({ query }) => {
    if (!query) {
      throw new Error("Issue ID is required");
    }

    try {
      const token = await getToken();
      const response = await api.get(`/search/specs/${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch search specs:", error);
      throw new Error("Failed to fetch search specs");
    }
  });
};

// Hook for toggling favorites
export const useToggleFavoriteMutation = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth0Token();

  return useMutation(
    async ({ issueId, sourceId, isFavorite }) => {
      const token = await getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (isFavorite) {
        const response = await api.delete("/favorites", {
          ...config,
          data: { issue_id: issueId, source_id: sourceId },
        });
        return response.data;
      } else {
        const response = await api.post(
          "/favorites",
          { issue_id: issueId, source_id: sourceId },
          config,
        );
        return response.data;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('starredIssues');
      },
    }
  );
}

// Hook for updating password
export const useUpdatePasswordMutation = () => {
  const { getToken } = useAuth0Token();
  
  return useMutation(async (passwordData) => {
    const token = await getToken();
    const response = await api.post("/update-password",
      {
        current_password: passwordData.currentPassword,
        password: passwordData.newPassword,
        password_confirmation: passwordData.confirmPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Hook for updating issue fields
export const useUpdateIssueMutation = () => {
  const { getToken } = useAuth0Token();
  
  return useMutation(async ({ issueId, field, value }) => {
    const token = await getToken();
    const response = await api.put(
      "/issues",
      { 
        issue_id: issueId,
        field: field,
        value: value 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Hook for fetching saved issues
export const useSavedIssuesQuery = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery(
    'savedIssues',
    async () => {
      const token = await getToken();
      const savedResponse = await api.get("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!savedResponse.data || savedResponse.data.length === 0) {
        return [];
      }
     
      const modifiedData = savedResponse.data.map(item => {
        if (item.issues) {
          item.issues = item.issues.map(issue => mapIssueFields(issue._source, true));
        }
        return item;
      });

      return modifiedData;
    },
    {
      staleTime: Infinity, // Data is always fresh
      cacheTime: Infinity, // Cache data indefinitely
      refetchOnMount: true, // Refetch on component mount
      refetchOnWindowFocus: false, // Do not refetch on window focus
    }
  );
};

// Hook for saving issues
export const useSaveIssuesMutation = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth0Token();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = await getToken();
      const response = await api.post(
        "/saves",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('savedIssues');
        toast.success(
          `${data.saved_count} issues saved successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to save issues');
      },
    }
  );
};

// Hook for deleting saved issues
export const useDeleteSavedIssuesMutation = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth0Token();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = await getToken();
      const response = await api.delete("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { 
          issue_ids: issueIds,
          source_id: sourceId 
        }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('savedIssues');
        toast.success('Issues removed from saved successfully');
      },
      onError: () => {
        toast.error('Failed to remove issues from saved');
      },
    }
  );
};

// Hook for fetching hidden issues
export const useHiddenIssuesQuery = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery(
    'hiddenIssues',
    async () => {
      const token = await getToken();
      const hiddenResponse = await api.get("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!hiddenResponse.data || hiddenResponse.data.length === 0) {
        return [];
      }

      const modifiedData = hiddenResponse.data.map(item => {
        if (item.issues) {
          item.issues = item.issues.map(issue => mapIssueFields(issue._source, true));
        }
        return item;
      });

      return modifiedData;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};

// Hook for hiding issues
export const useHideIssuesMutation = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth0Token();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = await getToken();
      const response = await api.post(
        "/hides",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success(
          `${data.hidden_count} issues hidden successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to hide issues');
      },
    }
  );
};

// Hook for unhiding issues
export const useUnhideIssuesMutation = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth0Token();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = await getToken();
      const response = await api.delete("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { 
          issue_ids: issueIds,
          source_id: sourceId 
        }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success('Issues unhidden successfully');
      },
      onError: () => {
        toast.error('Failed to unhide issues');
      },
    }
  );
};

// 2. get the token and check token is correct or not
export const useVerifyResetTokenMutation = () => {
  return useMutation(
    async (token) => {
      const response = await api.get(`/reset-password/${token}`, { token });
      return response.data;
    },
    // {
    //   onSuccess: (data) => {
    //     toast.success("Token is valid.");
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data?.message || "Invalid or expired token.");
    //   },
    // }
  );
};

 
// 3. send user input email and token to backend: update the password
export const useResetPasswordMutation = () => {
  return useMutation(
    async ({ token, password, password_confirmation }) => {
      const response = await api.post(`/reset-password/${token}`, {
        password,
        password_confirmation,
      });
      return response.data;
    },
    // {
    //   onSuccess: () => {
    //     toast.success("Password has been reset successfully.");
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data?.message || "Failed to reset password.");
    //   },
    // }
  );
}


// Hook for fetching organization users
export const useOrganizationUsersQuery = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery(
    'organizationUsers',
    async () => {
      const token = await getToken();
      const response = await api.get("/organizations/users/emails", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      return response.data.map(user => ({
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        initials: `${user.first_name[0]}${user.last_name[0]}`
      }));
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false, 
    }
  );
};

// Hook for fetching shared issues
export const useSharedIssuesQuery = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery(
    'sharedIssues',
    async () => {
      const token = await getToken();
      const response = await api.get("/shares", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.data || response.data.length === 0) {
        return [];
      }

      const modifiedData = response.data.map(item => {
        if (item.issues) {
          item.issues = item.issues.map(issue => mapIssueFields(issue._source, true));
        }
        return item;
      });

      return modifiedData;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// Hook for fetching starred issues
export const useStarredIssuesQuery = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery(
    'starredIssues',
    async () => {
      const token = await getToken();
      const starredResponse = await api.get("/favorites", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!starredResponse.data || starredResponse.data.length === 0) {
        return [];
      }
      
      return starredResponse.data.map((issue) => {
        const issueWithFavorite = {
          ...issue._source,
          _is_favorite: true
        };
        return mapIssueFields(issueWithFavorite, true);
      });
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// Hook for sharing issues
export const useShareIssuesMutation = () => {
  const { getToken } = useAuth0Token();
  
  return useMutation(async ({ emails, issueIds, query }) => {
    if (!emails || !Array.isArray(emails) || emails.length === 0) {
      throw new Error('At least one email is required');
    }

    if (!issueIds || !Array.isArray(issueIds) || issueIds.length === 0) {
      throw new Error('At least one issue ID is required');
    }

    try {
      const token = await getToken();
      const response = await api.post(
        "/shares",
        { 
          emails: emails,
          issue_ids: issueIds,
          query: typeof query === "object" && query !== null ? query.query : query,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      return response.data;
      
    } catch (error) {
      console.error('Share error:', error);
      throw error.response?.data?.message || error.message || 'Failed to share issues';
    }
  });
};

// Hook for fetching shared content
export const useSharedContentQuery = (shareToken) => {
  const { getToken } = useAuth0Token();
  
  return useMutation(
    async () => {
      try {
        const token = await getToken();
        const response = await api.get(`/shares/${shareToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;
        return {
          issues: data.issues.map(issue => mapIssueFields(issue._source, true)),
          query: data.query,
          sharedBy: data.shared_by,
          timeStamp: data.created_at,
        };
      } catch (error) {
        if (error.response?.status === 404) {
          throw new Error('Invalid token');
        }
        throw error;
      }
    }
  );
};

// Hook for sharing specs
export const useShareSpecs = () => {
  const { getToken } = useAuth0Token();
  
  return useMutation(
    async ({ values, current_issue_id, emails, currTagsArr}) => {
      if (!emails || !Array.isArray(emails) || emails.length === 0) {
        throw new Error('At least one email is required');
      }

      if (current_issue_id !== null && typeof current_issue_id !== 'string') {
        throw new Error('Value must be an string');
      }

      if (values !== null && !Array.isArray(values)) {
        throw new Error('Value must be an array');
      }
      
      try {
        const token = await getToken();
        const response = await api.post(
          '/share-specs',
          {
            values: values,
            current_issue_id: current_issue_id,
            emails: emails,
            currTagsArr: currTagsArr,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return response.data;
      } catch (error) {
        if (error.response?.data?.error) {
          throw new Error(error.response.data.error);
        }
        throw error;
      }
    }
  );
};

// Hook for fetching shared specs
export const useSharedSpecsQuery = (shareToken) => {
  const { getToken } = useAuth0Token();
  
  return useMutation(
    async () => {
      try {
        const token = await getToken();
        const response = await api.get(`/sharedspecs/${shareToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;
        return {
          values: data.values, // shared values array
          currentIssueId: data.current_issue_id, // current issue id
          sharedBy: data.shared_by, // shared by info
          currTagsArr: data.currTagsArr,
        };
      } catch (error) {
        if (error.response?.status === 404) {
          throw new Error('Invalid token');
        }
        throw error;
      }
    }
  );
};

// Hook for fetching achieved issues data
export const useAchievedIssuesQuery = () => {
  const { getToken } = useAuth0Token();
  
  return useQuery(
    'achievedIssues',
    async () => {
      const token = await getToken();
      const response = await api.get("/achieve/issues", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.data.length === 0) {
        return [];
      }

      return response.data;
    },
    {
      staleTime: Infinity, // Data is always fresh
      cacheTime: Infinity, // Cache data indefinitely
      refetchOnMount: true, // Refetch on component mount
      refetchOnWindowFocus: false, // Do not refetch on window focus
    }
  );
};

export const useSearchChartData = () => {
  const { getToken } = useAuth0Token();

  return useMutation(async ({ user_query, max_retries = 3 }) => {
    if (!user_query) {
      throw new Error("user_query is required");
    }
    const token = await getToken();

    try {
      const response = await api.post(
        `/chart/generate`,
        { user_query, max_retries },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response.data.chart_json);
      return response.data.chart_json; 
    } catch (error) {
      console.error("Failed to fetch chart data:", error);
      throw new Error("Failed to fetch chart data");
    }
  });
};

// 2. Ahcieve System Trends Page:
// Level 1: The System Level (Default Status): Searching, and filter the Series, Mode 
// API end point: /achieve/systems
export const useSystemsQuery = (query = {}) => {
  // Handle undefined, null, or empty string values properly
  const model = query.model || null;
  const series = query.series || null;

  const { getToken } = useAuth0Token();
  
  // Create a query key that will change when model or series changes
  const queryKey = ['systems', model, series];
  
  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      
      // Only add parameters to the request if they have values
      const params = {};
      if (model) params.model = model;
      if (series) params.series = series;
      
      const response = await api.get("/achieve/systems", {
        params, // This will be empty if both model and series are null
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!response.data || response.data.length === 0) {
        return [];
      }
      
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

/* 
Level 2: The Product Level: Must have System (need send to backend), could filter views (Failure Mode, Cost, and Failure Rate)
// API end point:  
// 2.1. /achieve/failure_mode when 'failure mode is selected' 
// 2.2. /achieve/cost when 'cost is selected' 
// 2.3. /achieve/failure_rate when 'failure_rate' is selected 
*/
// 2.1. /achieve/failure_mode when 'failure mode is selected' 
export const useFailureModeQuery = (query = {}, options = {}) => {
  // Handle parameters with defaults
  const system = query.system || null;
  const model = query.model || null;
  const series = query.series || null;
  const { getToken } = useAuth0Token();
  
   // Create a query key that will change when any parameter changes
  const queryKey = ['failureMode', system, model, series];
  
  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      
       // Only add parameters with values
      const params = {};
      if (system) params.system = system;
      if (model) params.model = model;
      if (series) params.series = series;
      
      const response = await api.get("/achieve/failure_mode", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data || [];
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options, // only enable was true, will call the data
    }
  );
};

// 2.2. /achieve/cost when 'cost is selected' 
export const useCostQuery = (query = {}, options = {}) => {
  // Handle parameters with defaults
  const system = query.system || null;
  const model = query.model || null;
  const series = query.series || null;
  const { getToken } = useAuth0Token();
  
   // Create a query key that will change when any parameter changes
  const queryKey = ['cost', system, model, series];
  
  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      
       // Only add parameters with values
      const params = {};
      if (system) params.system = system;
      if (model) params.model = model;
      if (series) params.series = series;
      
      const response = await api.get("/achieve/cost", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data || [];
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options, // only enable was true, will call the data
    }
  );
};

// 2.3: /achieve/failure_rate when 'failure_rate' is selected 
export const useFailureRateQuery = (query = {}, options = {}) => {
  // Handle parameters with defaults
  const system = query.system || null;
  const model = query.model || null;
  const series = query.series || null;
  const { getToken } = useAuth0Token();
  
  // Create a query key that will change when any parameter changes
  const queryKey = ['failureRate', system, model, series];
  
  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      
       // Only add parameters with values
      const params = {};
      if (system) params.system = system;
      if (model) params.model = model;
      if (series) params.series = series;
      
      const response = await api.get("/achieve/failure_rate", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data || [];
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,  // only enable was true, will call the data
    }
  );
};


/* 
Level 3: The Failure Mode Level: : Must have Product (need send to backend)
// 3.1: /achieve/component_description: the summary of current product or compoment
// 3.2: /achieve/time_evolution for the barchart (have 6 difference filter dropdown menu)
// 3.3: /achieve/group for the 'Issue Trends' and below (need send product to get all the data)
*/
// 3.1: /achieve/component_description: the summary of current product or compoment
export const useCompoDescription = (query = {}) => {
  const component = query.component || null;
  const { getToken } = useAuth0Token();

  return useQuery(
    component,
    async () => {
      const token = await getToken();

      const params = {};
      if (component) params.component = component;

      const response = await api.get('/achieve/component_description', {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.data.length === 0) {
        return [];
      }
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// 3.2: /achieve/time_evolution for the barchart (have 6 difference filter dropdown menu)
export const useGroupQuery = (query = {}) => {
  // Handle parameters with defaults
  const component = query.component || null;
  const model = query.model || null;
  const series = query.series || null;
  const { getToken } = useAuth0Token();
  
  // Create a query key that will change when any parameter changes
  const queryKey = ['group', component, model, series];
  
  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      
      // Only add parameters with values
      const params = {};
      if (component) params.component = component;
      if (model) params.model = model;
      if (series) params.series = series;
      
      const response = await api.get("/achieve/group", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!response.data || response.data.length === 0) {
        return [];
      }
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// 3.3: /achieve/group for the 'Issue Trends' and below (need send product to get all the data)
export const useTimeEvolutionQuery = (query = {}) => {
  // Handle the component parameter with default
  const component = query.component || null;
  const { getToken } = useAuth0Token();
  
  // Create a query key that will change when the component parameter changes
  const queryKey = ['timeEvolution', component];
  
  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      
      // Only add parameter if it has a value
      const params = {};
      if (component) params.component = component;
      
      const response = await api.get("/achieve/time_evolution", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!response.data || response.data.length === 0) {
        return [];
      }
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// Level 4. The Action Taken Level: Must have the Failure Mode and user selected ==>
// /achieve/action_taken: used for shows the Action take format take with to user selected tag
export const useActionTakenQuery = (query = {}) => {
  // Handle parameters with defaults
  const group = query.group || null;
  const model = query.model || null;
  const series = query.series || null;
  const { getToken } = useAuth0Token();
  
  // Create a query key that will change when any parameter changes
  const queryKey = ['actionTaken', group, model, series];
  
  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      
      // Only add parameters with values
      const params = {};
      if (group) params.group = group;
      if (model) params.model = model;
      if (series) params.series = series;
      
      const response = await api.get("/achieve/action_taken", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!response.data || response.data.length === 0) {
        return [];
      }
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// 3. Achieve Milestones Page:
// Line Chart
// 3.1: /achieve/cost_estimation ==> the cost data
export const useCostEstimationQuery = (query = {}) => {
  // Handle parameters with defaults
  const referenceModel = query.referenceModel || null;
  const currentModel = query.currentModel || null;
  const { getToken } = useAuth0Token();

  // Create a query key that will change when parameters change
  const queryKey = ['costEstimation', referenceModel, currentModel];

  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();

      // Only add parameters with values
      const params = {};
      if (referenceModel) params.reference_model = referenceModel;
      if (currentModel) params.current_model = currentModel;

      const response = await api.get("/achieve/cost_estimation", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.data.length === 0) {
        return [];
      }

      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// 3.2: /achieve/failure_estimation ==> the time data
export const useFailureEstimationQuery = (query = {}) => {
  // Handle parameters with defaults
  const referenceModel = query.referenceModel || null;
  const currentModel = query.currentModel || null;

  // Create a query key that will change when parameters change
  const queryKey = ['failureEstimation', referenceModel, currentModel];
  const { getToken } = useAuth0Token();

  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();

      // Only add parameters with values
      const params = {};
      if (referenceModel) params.reference_model = referenceModel;
      if (currentModel) params.current_model = currentModel;

      const response = await api.get("/achieve/failure_estimation", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.data.length === 0) {
        return [];
      }

      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// Second Graph:
// 3.3: /achieve/cost_allocation ==> the time data
export const useCostAllocationQuery = (query = {}) => {
  // Handle parameters with defaults
  const referenceModel = query.referenceModel || null;
  const currentModel = query.currentModel || null;

  // Create a query key that will change when parameters change
  const queryKey = ['costAllocationQuery', referenceModel, currentModel];
  const { getToken } = useAuth0Token();

  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();

      // Only add parameters with values
      const params = {};
      if (referenceModel) params.reference_model = referenceModel;
      if (currentModel) params.current_model = currentModel;

      const response = await api.get("/achieve/cost_allocation", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.data.length === 0) {
        return [];
      }

      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};


// 3.4: /achieve/technician_allocation ==> the time data
export const useTechnicianAllocationQuery = (query = {}) => {
  // Handle parameters with defaults
  const referenceModel = query.referenceModel || null;
  const currentModel = query.currentModel || null;

  // Create a query key that will change when parameters change
  const queryKey = ['technicianAllocationQuery', referenceModel, currentModel];
  const { getToken } = useAuth0Token();

  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();

      // Only add parameters with values
      const params = {};
      if (referenceModel) params.reference_model = referenceModel;
      if (currentModel) params.current_model = currentModel;

      const response = await api.get("/achieve/technician_allocation", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.data.length === 0) {
        return [];
      }

      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// 3.5: /achieve/group_allocation ==> the time data
export const useGroupAllocationQuery = (query = {}) => {
  // Handle parameters with defaults
  const model = query.model || null;

  // Create a query key that will change when parameters change
  const queryKey = ['groupAllocationQuery', model];
  const { getToken } = useAuth0Token();

  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();

      // Only add parameters with values
      const params = {};
      if (model) params.model = model;

      const response = await api.get("/achieve/group_allocation", {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.data.length === 0) {
        return [];
      }

      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};


// 4. Achieve Issue overviews Page: 



/************************************************************** The Achieve Module API end Pointer function ***************************************************************/ 