import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, TimeScale, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import { format, parseISO, differenceInDays } from 'date-fns';
import "./GanttCard.css";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    TimeScale,
    Tooltip,
    Legend
);

const processGanttData = (data) => {
    const phases = Object.keys(data);
    const datasets = [
        {
            label: 'Time Expected',
            backgroundColor: '#1570EF',
            borderColor: '#1570EF',
            borderWidth: 1,
            barThickness: 20,
            data: []
        },
        {
            label: 'Time Allocated',
            backgroundColor: '#84CAFF',
            borderColor: '#84CAFF',
            borderWidth: 1,
            barThickness: 20,
            data: []
        }
    ];

    let minDate = Infinity;
    let maxDate = -Infinity;

    phases.forEach(phase => {
        // handle Deal with the Time Expected
        const expectedStart = parseISO(data[phase]["Time Expected"][0]);
        const expectedEnd = parseISO(data[phase]["Time Expected"][1]);
        datasets[0].data.push({
            x: [expectedStart, expectedEnd],
            y: phase
        });

        // handle deal with Time Allocated
        const allocatedStart = parseISO(data[phase]["Time Allocated"][0]);
        const allocatedEnd = parseISO(data[phase]["Time Allocated"][1]);
        datasets[1].data.push({
            x: [allocatedStart, allocatedEnd],
            y: phase
        });

        // calulate the total time
        minDate = Math.min(minDate, expectedStart.getTime(), allocatedStart.getTime());
        maxDate = Math.max(maxDate, expectedEnd.getTime(), allocatedEnd.getTime());
    });

    return {
        datasets,
        labels: phases,
        minDate: new Date(minDate),
        maxDate: new Date(maxDate)
    };
};

const GanntCard = ({
    chartArr,
    chartType = "Gantt Chart",
    defaultHeight = "600px",
    defaultWidth = "800px",
    chartTitle,
    hideViewDetails
}) => {
    const chartData = useMemo(() => processGanttData(chartArr), [chartArr]);

    const options = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                ticks: {
                    autoSkip: false, // not can hide skip
                    maxRotation: 45, //  max 45
                    minRotation: 45, //  min also 45
                    font: { size: 12, family: "Inter, sans-serif" }
                },
                time: {
                    unit: 'month',
                    tooltipFormat: 'yyyy-MM-dd'
                },
                font: { size: 12, family: "Inter, sans-serif" },
                min: chartData.minDate,
                max: chartData.maxDate
            },
            y: {
                beginAtZero: true,
                stacked: true,
                font: { size: 12, family: "Inter, sans-serif" },
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (context) => context[0].label,
                    label: (context) => {
                        const data = context.raw.x;
                        return [
                            `Start: ${format(data[0], 'yyyy-MM-dd')}`,
                            `End: ${format(data[1], 'yyyy-MM-dd')}`,
                            `Duration: ${differenceInDays(data[1], data[0])} days`
                        ];
                    },
                    font: { size: 12, family: "Inter, sans-serif" },
                }
            },
            legend: {
                position: 'top',
                align: 'end', // display in the end of top area
                labels: {
                    usePointStyle: true,
                },
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    boxWidth: 11,
                    font: {
                        size: 11, // the font size
                    },
                }
            }
        }
    };

    return (
        <div className="GanttCard" style={{ width: defaultWidth, minHeight: defaultHeight, maxHeight: defaultHeight}}>
            <div className="GanttCardMenuArea">
                {chartTitle && <div className="ganttChartTitle">{chartTitle}</div>}
                {!hideViewDetails && <div className="detailsButtonText">View details</div>}
            </div>

            <div className="GanttArea">
                {chartType === "Gantt Chart" && <Bar data={{ datasets: chartData.datasets }} options={options} />}
            </div>
        </div>
    );
};

GanntCard.propTypes = {
    chartArr: PropTypes.object.isRequired,
    chartType: PropTypes.string,
    defaultHeight: PropTypes.string,
    defaultWidth: PropTypes.string,
    hideViewDetails: PropTypes.bool,
    chartTitle: PropTypes.string
};

export default GanntCard;