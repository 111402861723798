import ToggleSwitch from "../../ui/Toggles/ToggleSwitch";

const KeySpecToggleButton = ({ handleOpenSpecification, isOpenSpecification }) => {
    return (
        <div className="ToggleButton" onClick={handleOpenSpecification} style={{ cursor: 'pointer' }}>
            <span className="titleToggle">Key Specifications</span>
            <ToggleSwitch
                isOpen={isOpenSpecification}
                onToggle={(e) => {
                    e.stopPropagation();
                    handleOpenSpecification();
                }}
                width="36px"
                height="18px"
            />
        </div>
    )
}

export default KeySpecToggleButton;