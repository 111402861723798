// The Product Level (2)
import React, { useEffect } from "react";
import './Trends.css';
import FailureModePage from "./FailureModePage";
import ActionTakenPage from "./ActionTakenPage";
import { ChevronRight, ArrowLeft } from "../../../Components/icons/Arrows";
import { FormatTable, Badge, DetailsWindow, DatePicker, AchieveSearch } from "../../../Components";
import { useAppContext } from "../../../AppContext";
import { useFailureModeQuery, useCostQuery, useFailureRateQuery } from "../../../api/queries";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../../Components/ui/DropdownMenu/DropdownMenu";


const ProductPage = () => {
    const { selectedProduct, setSelectedProduct, 
        productFormatColumn, setProductFormatColumn,
        productFormatData, setProductFormatData,
        setClickAchieveSystem, openDetailsWindow, setOpenDetailsWindow, clickAchieveSystem,
        clickProduct, setClickProduct, clickFailureMode, selectedViews, setSelectedViews, setClickFailureMode,
        // selectedModel selectedSeries
    } = useAppContext();

    // Fake data of views dropdown 
    const viewsArray = ["Failure mode", "Cost", "Failure rate"];

    // the back button => come back to previous level (system level)
    const handleBackToSystem = () => {
        setClickAchieveSystem(null);
    }

    // the function about views dropdown selected ==> user could select difference views and dynamic change the product format
    const handleSelectViews = (item) => {
        setSelectedViews(item);
    }

    // // 2. the second level: the product search date range
    // const [dateProductRange, setDateProductRange] = useState({
    //     startDate: null,
    //     endDate: null
    // });

    // // hanlde get the user select date from date picker component in the failure mode search
    // const handleProductRange = (dates) => {
    //     setDateProductRange(dates);
    // };

    // in here use selectedViews to check which API need to call 

    // fauiler mode
    // For failure mode data
    const { data: productData = [], isLoading: isProductLoading, error: productError } = useFailureModeQuery(
        {
            system: clickAchieveSystem.Name || null,
            model: null, // selectedModel || null
            series: null // selectedSeries || null
        },
        {
            enabled: selectedViews === "Failure mode", // Call the API only if selectedViews === "Failure Mode"
            onSuccess: (data) => {
                setProductFormatData(data);
                setProductFormatColumn(failureModeColumn);
            }
        }
    );

    // For cost data
    const { data: costs = [], isLoading: costsLoading, error: costsError } = useCostQuery(
        {
            system: clickAchieveSystem.Name || null,
            model: null, // selectedModel || null
            series: null // selectedSeries || null
        },
        {
            enabled: selectedViews === "Cost", // Call the API only if selectedViews === "Cost"
            onSuccess: (data) => {
                setProductFormatData(data);
                setProductFormatColumn(costColumn);
            }
        }
    );

    // For failure rate data
    const { data: failureRates = [], isLoading: failureRatesLoading, error: failureRatesError } = useFailureRateQuery(
        {
            system: clickAchieveSystem.Name || null,
            model: null, // selectedModel || null
            series: null // selectedSeries || null
        },
        {
            enabled: selectedViews === "Failure rate", // Call the API only if selectedViews === "Failure Rate"
            onSuccess: (data) => {
                setProductFormatData(data);
                setProductFormatColumn(failureRateColumn);
            }
        }
    );


    // call the api loading and error status check
    const isLoading = isProductLoading || costsLoading || failureRatesLoading;
    const error = productError || costsError || failureRatesError;


    // the FailureModeColumn column
    const failureModeColumn = [
        {
            key: "Name",
            label: "Product Name",
            width: "31%",
            render: (value, row) => (
                <div className="product-cell">
                    {value}
                    {row.Label && (
                        <Badge type="category" variant={getColorVersion(row.Label)}>
                            {row.Label}
                        </Badge>
                    )}
                </div>
            ),
        },
        { key: "Mentions", label: "Mentions", width: "10%" },
        { key: "Occurrences", label: "Occurrences", width: "11%" },
        { key: "Failure Rate", label: "Failure Rate", width: "12%" },
        {
            key: "Total Servicing Cost",
            label: "Total Servicing Cost",
            width: "18%",
            render: (value) => {
                return <span>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}</span>
            }
        },
        {
            key: "Total Down Time",
            label: "Servicing days",
            width: "18%",
        }
    ];


    // the cost views format column
    const costColumn = [
        {
            key: "Name",
            label: "Product Name",
            width: "34%",
            render: (value, row) => (
                <div className="product-cell">
                    {value}
                    {row.Label && (
                        <Badge type="category" variant={getColorVersion(row.Label)}>
                            {row.Label}
                        </Badge>
                    )}
                </div>
            ),
        },
        { key: "Variance", label: "Variance", width: "15%" },
        {
            key: "Total Servicing Cost",
            label: "Total Cost",
            width: "18%",
            render: (value) => {
                return <span>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}</span>
            }
        },
        { key: "Source", label: "Source", width: "15%" },
        {
            key: "Total Down Time",
            label: "Servicing days",
            width: "18%",
            render: (value) => {
                return <div className="Effectiveness-cell">
                    {value} 
                </div>
            }
        }
    ];

    // the fauilre rate views format column
    const failureRateColumn = [
        {
            key: "Name",
            label: "Product Name",
            width: "35%",
            render: (value, row) => (
                <div className="product-cell">
                    {value}
                    {row.Label && (
                        <Badge type="category" variant={getColorVersion(row.Label)}>
                            {row.Label}
                        </Badge>
                    )}
                </div>
            ),
        },
        { key: "Failure Rate per M flight hrs", label: "Failure Rate (M Flight Hrs)", width: "18%" },
        { key: "Avg Failure Rate", label: "Avg Failure Rate", width: "14%" },
        { key: "Unscheduled Maintenance", label: "Unscheduled Maintenance", width: "15%" },
        {
            key: "Total Down Time",
            label: "Servicing days",
            width: "18%",
            render: (value) => {
                return <div className="Effectiveness-cell">
                    {value} 
                </div>
            }
        },
    ];


    // handle the second round click
    useEffect(() => {  
        if (!isLoading) {
            if (selectedViews === "Failure mode") {
                setProductFormatData(productData);
                setProductFormatColumn(failureModeColumn);
            } else if (selectedViews === "Cost" && costs.length > 0) {
                setProductFormatData(costs);
                setProductFormatColumn(costColumn);
            } else if (selectedViews === "Failure rate" && failureRates.length > 0) {
                setProductFormatData(failureRates);
                setProductFormatColumn(failureRateColumn);
            }
        }
    }, [selectedViews, isLoading]);


    // close function ==> in the level 3: Failure mode
    const handleClose = () => {
        setOpenDetailsWindow("");
    }

    // back function => in the level 4: action taken
    const handleBackToFailureMode = () => {
        setOpenDetailsWindow("product");
        setClickFailureMode(null);
    }

    // get the badge color of issue
    const getColorVersion = (label) => {
        if (!label) return "category-gray";
        switch (label) {
            case "system":
                return "category-green";
            case "design":
                return "category-green";
            case "maintenance":
                return "category-red";
            case "component":
                return "category-yellow";
            case "assembly":
                return "category-blue";
            case "supplier quality":
                return "category-purple";
            default:
                return "category-gray";
        }
    };

    // the search animation effect dots numbers
    const dots = Array.from({ length: 16 });

    return <>
        <div className="pageRoute">
            <button className={`routeButton DisableClicked`}> Achieve </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton`}> System Trends </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}> {clickAchieveSystem.Name} </button>
        </div>

        <div className="TrendsFilter">
            <div className="backButton" onClick={handleBackToSystem}>
                <ArrowLeft size={16} color="#667085" />
            </div>

            <div className="TrendsFilterLeft">
                <div className="ShowsSystemArea">
                    {clickAchieveSystem.Name}
                    <Badge type="category" variant={getColorVersion(clickAchieveSystem.Label)}>
                        {clickAchieveSystem.Label}
                    </Badge>
                </div>
                <div className="viewByArea">View by</div>
                {/* Cost dropdown menu */}
                <DropdownMenu >
                    <DropdownMenuTrigger height="34px">
                        <span className="dropdown-menu-trigger-text">
                            {selectedViews || "Select Views"}
                        </span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        {viewsArray.map((item) => (
                            <DropdownMenuItem key={item} onSelect={() => handleSelectViews(item)}>
                                {item}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>


            <AchieveSearch
                width="250px"
                height="34px"
                options={productData}
                selectedProduct={selectedProduct}  // control user selected
                onSearch={setSelectedProduct} // Listen user selected
                placeholder="Search Product"
            />

        {/* 
            <DatePicker
                width="460px"
                height="428px"
                initialMode="Since"
                startDate={dateProductRange.startDate}
                endDate={dateProductRange.endDate}
                onApply={handleProductRange}
            /> */}
        </div>

        <div className="trendsDataFormat">
            {isLoading ? <div className="trendsDataFormat">
                <div className="LoadingFormatArea">
                    <div className="fade-in">
                        <div className="dots-loader">
                            {dots.map((_, index) => (
                                <div
                                    key={index}
                                    className="dot"
                                    style={{
                                        transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                                        animationDelay: `${index * 0.125}s`
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div> : <FormatTable
                width="100%"
                height="calc(100vh - 180px)"
                data={selectedProduct ? productFormatData.filter((item) => item.Name === selectedProduct.value) : productFormatData}
                columns={productFormatColumn}
                rowHeight="50px"
                setOpenDetailsWindow={setOpenDetailsWindow}
                setClickProduct={setClickProduct}
                currentLevel="system"
            />
            }
        </div>

        {clickProduct && (
            <DetailsWindow
                open={openDetailsWindow === "product" || openDetailsWindow === "Failure Mode"}
                onClose={openDetailsWindow === "product" ? handleClose : handleBackToFailureMode}
                width="92vw"
                height="100vh"
            >
                {(openDetailsWindow === "product" && clickProduct) && <FailureModePage />}

                {(openDetailsWindow === "Failure Mode" && clickFailureMode) && <ActionTakenPage />}

            </DetailsWindow>
        )}
    </>
}

export default ProductPage;