import React, { createContext, useState, useContext } from "react";

// create the context api store to save all the state in there
const AppContext = createContext();

//Customer hook: get the data from AppContext, it means if any component call useAppContext, it can use the data from AppContext
export const useAppContext = () => useContext(AppContext);

// AppProvider: a Component pass the state into Children component
export const AppProvider = ({ children }) => {
  // The other state: the user information state / the global state
  const [registrationSuccess, setRegistrationSuccess] = useState(false); //check whether user was scusess sign a account
  const [selectedMenuItem, setSelectedMenuItem] = useState("Log in");
  const [isRememberMe, setIsRemeberMe] = useState(false); // login account ==> whether will remember or not

  /********************************************************** 1. the Drive Module state ***************************************/
  // 1.1 the node graph part
  const [graphWidth, setGraphWidth] = useState(50);
  const [selectedCar, setSelectedCar] = useState(); // declare the state to get user selected the car system
  const [clickNode, setClickNode] = useState(); // declare a state to get which node the user clicked it
  const [scenarioNodeID, setScenarioNodeID] = useState(); // declare the scenario 1 2 3 node id of clickedNode impact for used display in the node graph

  const [multipleClicked, setMultipleClicked] = useState([]); //support the user could muplite clicked node
  const [selectInteraction, setSelectInteraction] = useState([]); // the area of user click the first node component interactions
  const [clickEdge, setClickedEdge] = useState(); // declare a state to get which edge that user clicked it
  const [connectArr, setConnectArr] = useState(null); // used for hight the clicked nodes and connect
  const [connectionArr, setConnectionArr] = useState([]); // used to save connection array of each node => decrease filter times
  const [searchNode, setSearchNode] = useState(); // used for search bar find the node
  const [twoDzoomLevel, settwoDZoomLevel] = useState(0.8); //the 2D graph camera views size

  // All the state was the graph bottom area checkbox to control display some needed attribute
  const [displayColor, setDisplayColor] = useState(false); // node color
  const [displayName, setDisplayName] = useState(true); // node name tittle
  const [displayLinkColor, setDisplayLinkColor] = useState(false); // link color
  const [isInteractions, setIsInteractions] = useState(true); // The interaction check box should work with the every categorical fields (currently Group, Team and department).
  const [displayErrorNode, setDisplayErrorNode] = useState(false); // the dangerous and warning node

  // used for choose num or string filter node
  const [filterNode, setFilterNode] = useState({
    systemFilter: null,
    subSystemFilter: null,
    componentFilter: null,
  });
  const [carFilter, setCarFilter] = useState(null); // the car prograom filter button
  const [filterNodeArr, setFilterNodeArr] = useState({
    nodes: [],
    links: [],
  }); // the arr of filter bar

  // array to save data source ==> and display, change the node details (build case)
  const [data, setData] = useState({
    nodes: [],
    links: [],
  });
  const [filterProgramNode, setFilterProgramNode] = useState([]); // filter the program node arr when user clicked it

  // the three function state used to calculate each node attribute index ==> color index
  const [systemArr, setSystemArr] = useState([]);
  const [subSystemArr, setSubSystemArr] = useState([]);
  const [componentArr, setComponentArr] = useState([]);

  //check box ==> filter color based
  const [checkColor, setCheckColor] = useState("System");

  //the color bar array to display each system color or subsystem
  const [colorData, setColorData] = useState([]);

  //filter link(edge)
  const [filterEdge, setFilterEdge] = useState(null);
  const [filterEdgeArr, setFilterEdgeArr] = useState([]);

  //the user information
  const [userDetailInfor, setUserDetailInfor] = useState();

  // change the graph to 2D or item iamge
  const [is2D, setIs2D] = useState("2D"); // the state to control 2d or image

  // 1.2 the right information data part (Properties, and concern clow stage): control open the right format area or not
  const [isExpanded, setIsExpanded] = useState(false); // judge whetehr expand the right area, used for the expand button function
  const [isDragging, setIsDragging] = useState(false); // judge whether could dragging the Properties menu button
  const [startX, setStartX] = useState(0); // the start place of move left/right button of the Properties menu button
  const [scrollLeft, setScrollLeft] = useState(0); // the disancte of user have scroll it from left

  // the page to control display wich part deatils: basic, Mass-Cost-Investment-Time, Validation Risk Areas, Geometric....
  const [page, setPage] = useState("General");

  // the Percentage of each node (cost, mass, investment, time)
  const [massPercentage, setMassPercentage] = useState(0);
  const [costPercentage, setCostPercentage] = useState(0);
  const [timePercentage, setTimePercentage] = useState(0);
  const [investmentPercentage, setInvestmentPercentage] = useState(0);
  const [clickIndex, setClickInxdex] = useState(1);

  // the concern flow stage part open and closed
  const [propertiesAreaOpen, setPropertiesAreaOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0); // save the moved distance of user moved it
  const [isOpenPrint, setIsOpenPrint] = useState(false); // the state for used open the model print window

  // 1.3 the print function states
  const [selectedSections, setSelectedSections] = useState({
    "Basic Details": {
      "Decision Requested": true,
      "Root Cause": true,
      "Part Number": true,
      "Issue Number": true,
      "Requestor Name": true,
      "Requestor ID": true,
    },
    "Change Action Evaluation": {
      "Action Description": true,
      "Benefits": true,
      "Cost-Mass-Time-Quality Effect": true,
      "Activities Impacted": true,
    },
    "Business Case Evaluation": {
      "Basic Details": true,
      "Business Case Comparisons": true,
    },
    "Release Notice": {
      "Release Notice": false,
    },
  }); // print selection about 4 parts of concern flow stage

  const [selectedScenario, setSelectedScenario] = useState({
    "Scenario 1": true,
    "Scenario 2": true,
    "Scenario 3": false,
  }); // print selection about Scenario 1 2 3 of concern flow stage "Change action Evaluation" and "Business Case Evaluation"

  // 1.4 the concern flow stage part satte
  const [concernAreaOpen, setConcernAreaOpen] = useState(false);
  const [accordionState, setAccordionState] = useState({
    "Basic Details": false,
    "Change Action": false, // Change Action Evaluation
    "Business Case": false, // Business Case Evaluation
    "Release Notice": false, // Release Notice
  });
  const [dataIndex, setDataIndex] = useState(0); // the ides used for display which case data of change action plan
  const [scenarioPage, setScenarioPage] = useState("Scenario 1"); // the scenario Page switch of Change action evaluation
  const [scenarioBusinessPage, setScenarioBusinessPage] =
    useState("Scenario 1"); // the scenario Page switch of Business Case Evaluation

  /********************************************************** 2. the Discover module page state ***************************************/
  const [isSearchLoading, setIsSearchLoading] = useState(false); // the state for the search loading process
  const [isKeySpecLoading, setIsKeySpecLoading] = useState(false); // the state for the key speac area loading process
  const [hasSearched, setHasSearched] = useState(false); // used for judege whether is first search
  const [clickedIssue, setClickedIssue] = useState(); // the issuse user clciked it
  const [clickedSimilarIssue, setClickedSimilarIssue] = useState(null); // the smilar issue in the issue area that user clicked it
  const [checkedRows, setCheckedRows] = useState(new Set()); // the user selected row with checkbox
  const [currentRowIndex, setCurrentRowIndex] = useState(-1); // the state used for keybord index
  const [basicValue, setBasicValue] = useState([]); // the basic value item used of search field with notification component

  const [specData, setSpecData] = useState(null); // the key specification data of current searched issue id

  // Add state for filter options
  const [filterOptions, setFilterOptions] = useState({
    status: [],
    system: [],
    assignee: [],
    stages: [],
    areas: [],
  });
  
  const [discoverColumns, setDiscoverColumns] = useState(() => {
    // check whether local storage has user memorized selected or not
    const savedColumns = localStorage.getItem("discoverColumns");
    return savedColumns ? JSON.parse(savedColumns) : [
      { field: "Issue ID", headerName: "Issue ID", width: 175, minWidth: 175, index: 0, hide: false, default: true },
      { field: "System", headerName: "System", width: 254, minWidth: 254, index: 1, hide: false, default: true },
      { field: "Title", headerName: "Title", width: 250, minWidth: 250, index: 2, hide: false, default: true },
      { field: "Description", headerName: "Description", width: 350, minWidth: 350, index: 3, hide: false, default: true },
      { field: "Failure Mode", headerName: "Failure Mode", width: 250, minWidth: 250, index: 4, hide: false, default: true },
      { field: "Root cause", headerName: "Root cause", width: 250, minWidth: 250, index: 5, hide: false, default: true },
      { field: "Mitigations steps", headerName: "Mitigations steps", width: 350, minWidth: 350, index: 6, hide: false, default: true },
      { field: "Resolution", headerName: "Resolution", width: 450, minWidth: 450, index: 7, hide: false, default: true },
      { field: "Impact", headerName: "Impact", width: 350, minWidth: 350, index: 8, hide: false, default: true },
      { field: "Part Number", headerName: "Part Number", width: 200, minWidth: 150, index: 9, hide: true, default: false },
      { field: "Requestor", headerName: "Requestor", width: 200, minWidth: 150, index: 10, hide: true, default: false },
      { field: "Requestor ID", headerName: "Requestor ID", width: 200, minWidth: 150, index: 11, hide: true, default: false },
    ];
  })

  const [IssuePageSize, setIssuePageSize] = useState({
    pageSize: 25,
    page: 1,
  }); // the default rows of one page, issue table navigation

  const [rowsHeight, setRowsHeight] = useState(90); // user could scroll and change the issue table rows height
  const [rowsHeightMap, setRowsHeightMap] = useState({}); // save user changed the row height as HashMap object
  const [stagesArray, setStagesArray] = useState([]); // save all the catergory item
  const [showsStarIssue, setShowsStarIssue] = useState(false); // the custom table for whether display star issue

  const [inputIssue, setInputIssue] = useState(""); // the user enter string in search bar
  const [issueArray, setIssueArray] = useState([]); // the relative issue array used for dropdowlist ?
  const [isSearchError, setIsSearchError] = useState(false); // whether meet error will display the modal window
  const [modalMessage, setModalMessage] = useState(""); // check is empty search or can not find the issue number or part number from data
  const [tagsTerm, setTagsTerm] = useState([]); // the tags terms of search field about user input and selected 
  const [issueDisplayArray, setIssueDisplayArray] = useState([]); // used for display all the relative issue of user search in the issue menu  area

  // 1. all the area/system of issue table data
  const [areaArray, setAreaArray] = useState([]); // the item in the column area dropdown list
  const [selectedAreas, setSelectedAreas] = useState([]); // the user selected item component of cloumn area dropdownlist

  // 2. all the status of issue table data
  const [allStatus, setAllStatus] = useState([]); // all status of realtive issue
  const [selectStatus, setSelectStatus] = useState([]); // the user selected the statues

  // 3. all the Requestor person name of issue table data
  const [allRequestor, setAllRequestor] = useState([]);
  const [selectRequestor, setSelectRequestor] = useState([]);

  const [sortColumn, setSortColumn] = useState([]); // save which column that user clciked to sort
  const [tableViews, setTableViews] = useState("All"); // the tab views button: All, Share, Saved, Starred, and Hidden 
  const [showDropDown, setShowDropDown] = useState(false); // state to control visibility of dropdown
  const [issueMenu, setIssueMenu] = useState("All"); // the issuem menu state support user could switch issue Stages part
  const [issueRelativeArray, setIssueRelativeArray] = useState([]); // the relative issue array about user search

  // 2.2: the right side area ==> including Specifications and other information
  const [isOpenSpecification, setIsOpenSpecification] = useState(() => {
    const savedOpenKeySpec = localStorage.getItem("savedOpenKeySpec");
    return savedOpenKeySpec === "true"; // change locastage saved value to be bool format
  });  // control open the right area including key specification and consider 
  const [speciIssueId, setSpeciIssueId] = useState(); // the specification display the data of this issue id
  const [specificationArray, setSpecificationArray] = useState([]); // the menu that current case have it for display in the addtional data area
  const [specificationsMenu, setSpecificationsMenu] = useState("Key Specs"); // the state used for the Specifications menu change
  const [subKeySpecsMenu, setSubKeySpecsMenu] = useState("design_docs"); // the submenu select of key specification area
  const [selectIssueDetails, setSelectIssueDetails] = useState("current_issue"); // the drop menu of key speciifcation area: curr issue and Related Issues
  const [openItem, setOpenItem] = useState([]); // save the accordionItem which is open and which was closed array
  const [openDataCard, setOpenDataCard] = useState([]); // memorize which data card user open it

  /* these are used for discover module specifications area sub men moveing left or right*/
  const [isSpeciDragging, setIsSpeciDragging] = useState(false); // the specifications of module 2 area
  const [additionalArray, setAdditionalArray] = useState([]); // the menu that current case have it for display in the addtional data area
  const [speciStartX, setSpeciStartX] = useState(0); // the specifications of module 2 area
  const [scrollSpeciLeft, setScrollSpeciLeft] = useState(0); // the specifications of module 2 area
  const [scrollSpeciPosition, setScrollSpeciPosition] = useState(0); // save the moved distance of user moved it

  /* these are used for discover module Addtional data area */
  const [isAddtionDragging, setIsAddtionDragging] = useState(false);
  const [addtionStartX, setAddtionStartX] = useState(0);
  const [scrollAddtionLeft, setScrollAddtionLeft] = useState(0);
  const [addtionScrollPosition, setAddtionScrollPosition] = useState(0); // save the moved distance of user moved it

  // 2.3: the relative issue details & highlight state
  const [highlightArray, setHighlightArray] = useState({
    "Failure Mode": true,
    "Components": true,
    "Failure Condition": true,
    "After Effects": true,
    "Specification": true,
  });
  const [selectAllHighlight, setSelectAllHighlight] = useState(true); // New state for select all

  // 2.4 the share, save starred, hidden button area states
  const [starIssueArray, setStarIssueArray] = useState([]); // the star list of user could used selected to add
  const [userShareArray, setUserSharedArray] = useState([]); // This is user shared array
  const [userSaveArray, setUserSavedArray] = useState([]); // This is user saved array
  const [userHiddenArray, setUserHiddenArray] = useState([]) // This is user hidden array
  
  // the Share block that user selected happened change
  const [blockShareStates, setShareBlockStates] = useState(() => {
    const savedShareStates = localStorage.getItem('blockShareStates');
    if (savedShareStates) {
      try {
        // change to json format
        const parsedStates = JSON.parse(savedShareStates);
        // changet to set format
        return parsedStates.map(state => ({
          ...state,
          checkedRows: new Set(state.checkedRows || [])
        }));
      } catch (error) {
        console.error('Error parsing saved states:', error);
        return [];
      }
    }
    return [];
  }); // create the indpence state for each share, save and hide block

  // the save block that user selected happened change
  const [blockSaveStates, setSaveBlockStates] = useState(() => {
    const savedStates = localStorage.getItem('blockSaveStates');
    if (savedStates) {
      try {
        // change to json format
        const parsedStates = JSON.parse(savedStates);
        // changet to set format
        return parsedStates.map(state => ({
          ...state,
          checkedRows: new Set(state.checkedRows || [])
        }));
      } catch (error) {
        console.error('Error parsing saved states:', error);
        return [];
      }
    }
    return [];
  }); // create the indpence state for each share, save and hide block

  // the hidden block that user selected happened change
  const [blockHiddenStates, setHiddenBlockStates] = useState(() => {
    const savedHiddenStates = localStorage.getItem('blockHiddenStates');
    if (savedHiddenStates) {
      try {
        // change to json format
        const parsedStates = JSON.parse(savedHiddenStates);
        // changet to set format
        return parsedStates.map(state => ({
          ...state,
          checkedRows: new Set(state.checkedRows || [])
        }));
      } catch (error) {
        console.error('Error parsing saved states:', error);
        return [];
      }
    }
    return [];
  }); // create the indpence state for each share, save and hide block


  // 2.5: the key specification new deaign state 
  const [specMenu, setSpecMenu] = useState("");
  const [standMenu, setStandMenu] = useState("");

 /************************************************* 3. the module 3 Achieve page part state ***************************************/
 const [isAchieveSideBarOpen, setIsAchieveSideBarOpen] = useState(true); // the achieve module side bar open or not

 // 1. the Achieve Home Page
 const [userGraphArray, setUserGraphArray] = useState([]);

 // 2. Trends Page: (System -> Product -> Failure Mode -> Achtion Token)
 // select means the state used for search field => user could input and search => filter the format
 const [selectAchieveSystem, setSelectAchieveSystem] = useState(null); // the Propulsion that user searched system
 const [selectedProduct, setSelectedProduct] = useState(null); // the product that user searched in the Achieve Trends page

 // System (Defult) Level (1): select used for default status Series Dropdowne menu and model dropdown menu
 const [selectedSeries, setSelectedSeries] = useState(""); // the series dropdown menu below user searched product
 const [selectedModel, setSelectedModel] = useState("");  // the model dropdown menu below user searched product

 // Product Level (2): selected the views of product format table
 const [selectedViews, setSelectedViews] = useState("Failure mode"); // the user select the views in product level => will change the format colums
 const [productFormatColumn, setProductFormatColumn] = useState(); // the Product Level (2) column that user selected views
 const [productFormatData, setProductFormatData] = useState(); // the Product Level (2) format data

 // Failure mode level (3): used in the series, views (cost, failure rate, and resolution time), mode, milestones, and Design Stages dropdown menu area => filter the bar chart data
 const [selectedFailureSeries, setSelectedFailureSeries] = useState([]); // the series dropdown menu below user searched product
 const [selectedFailureModel, setSelectedFailureModel] = useState("B737");  // the model dropdown menu below user searched product
 const [selectedFailureViews, setSelectedFailureViews] = useState("Cost"); // the series dropdown menu below user searched product
 const [selectedFailureDesign, setSelectedFailureDesign] = useState("");  // the model dropdown menu below user searched product
 const [selectedFailureMilestones, setSelectedFailureMilestones] = useState("");  // the model dropdown menu below user searched product

 // Action Taken Level (4): user selected opned nested area of the Action tokes
 const [selectedAction, setSelectedAction] = useState(null);
 
 //this is used for user click and which the leve (System -> Product -> Failure Mode -> Achtion Token)
 const [openDetailsWindow, setOpenDetailsWindow] = useState(""); // Select open which level details popwindow
 
 // the click means user click the format and open the open window or click the system to open product page
 const [clickAchieveSystem, setClickAchieveSystem] = useState();     // the product that user click system => open the product page
 const [clickProduct, setClickProduct] = useState(); // the product or Component that user click in the Achieve Trends page format and open the Failure mode page
 const [clickFailureMode, setClickFailureMode] = useState(); // user click the failure mode ==> open the Action Taken page
 
 // 3. the Milestones Page:
 const [userClickViews, setUserClickViews] = useState("Cost");
 const [userClickModel, setUserClickModel] = useState(["B737", "B777"]);
 const [userClickBarChartView, setUserClickBarChartViews] = useState("Technicians");

 // 4. Issue overview: 
const [searchText, setSearchText] = useState("");
const [appliedSearchText, setAppliedSearchText] = useState("");
const [showModelFilter, setShowModelFilter] = useState(false);
const [selectedModels, setSelectedModels] = useState([]);
const [allModels, setAllModels] = useState([]);
const [expandedModelRows, setExpandedModelRows] = useState({});
const [expandedFailureModes, setExpandedFailureModes] = useState({});
const [failureModeChartData, setFailureModeChartData] = useState([]);
const [sortTableData, setSortTableData] = useState([]);

 // overview chart data card selection state
 const [selectedOverviewOption, setSelectedOverviewOption] = useState("Status"); // the selection dropdown menu of overview chart: status, aircraft, manufacturer, and location
 
 // the system overview chart data card selection state
 const [selectedSystemOption, setSelectedSystemOption] = useState("Systems overview"); // the selection dropdown menu of overview chart: status, aircraft, manufacturer, and location
 
 // the location status chart data card selection state
 const [selectedLocationOption, setSelectedLocationOption] = useState("Location overview"); // the selection dropdown menu of overview chart: status, aircraft, manufacturer, and location
 

 /************************************************* 4. Share View Module Page  ******************************************************/
 const [sharedBy, setSharedBy] = useState({}); // the shared by information
 const [sharedIssueArray, setSharedIssuedArray] = useState([]); // the shared issue array used to display share views module
 const [isSharedView, setIsSharedView] = useState(false); // judge it's it in the share views page or general oage
 const [sharedQuery, setSharedQuery] = useState([]); // the shared query 
 const [timeStamp, setTimeStamp] = useState(); // the time

  // Pass the all the function as value passed it to AppContext, that could throug useAppContext() used it
  return (
    <AppContext.Provider
      value={{
        //Drive Module
        registrationSuccess,
        setRegistrationSuccess,
        isRememberMe,
        setIsRemeberMe,
        graphWidth,
        setGraphWidth,
        selectedMenuItem,
        setSelectedMenuItem,
        multipleClicked,
        setMultipleClicked,
        selectedCar,
        setSelectedCar,
        selectInteraction,
        setSelectInteraction,
        filterNode,
        setFilterNode,
        filterEdge,
        setFilterEdge,
        filterEdgeArr,
        setFilterEdgeArr,
        filterNodeArr,
        setFilterNodeArr,
        isInteractions,
        setIsInteractions,
        searchNode,
        setSearchNode,
        connectArr,
        setConnectArr,
        connectionArr,
        setConnectionArr,
        clickNode,
        setClickNode,
        clickEdge,
        setClickedEdge,
        checkColor,
        setCheckColor,
        colorData,
        setColorData,
        page,
        setPage,
        data,
        setData,
        clickIndex,
        setClickInxdex,
        displayColor,
        setDisplayColor,
        selectedSections,
        setSelectedSections,
        selectedScenario,
        setSelectedScenario,
        displayName,
        setDisplayName,
        displayLinkColor,
        setDisplayLinkColor,
        displayErrorNode,
        setDisplayErrorNode,
        filterProgramNode,
        setFilterProgramNode,
        userDetailInfor,
        setUserDetailInfor,
        is2D,
        setIs2D,
        carFilter,
        setCarFilter,
        twoDzoomLevel,
        settwoDZoomLevel,
        systemArr,
        setSystemArr,
        subSystemArr,
        setSubSystemArr,
        componentArr,
        setComponentArr,
        isExpanded,
        setIsExpanded,
        isDragging,
        setIsDragging,
        startX,
        setStartX,
        scrollLeft,
        setScrollLeft,
        scrollPosition,
        setScrollPosition,
        massPercentage,
        setMassPercentage,
        costPercentage,
        setCostPercentage,
        timePercentage,
        setTimePercentage,
        investmentPercentage,
        setInvestmentPercentage,
        propertiesAreaOpen,
        setPropertiesAreaOpen,
        concernAreaOpen,
        setConcernAreaOpen,
        accordionState,
        setAccordionState,
        dataIndex,
        setDataIndex,
        scenarioNodeID,
        setScenarioNodeID,
        isOpenPrint,
        setIsOpenPrint,
        areaArray,
        setAreaArray,
        allStatus,
        setAllStatus,
        showsStarIssue,
        setShowsStarIssue,
        selectedAreas,
        setSelectedAreas,
        selectStatus,
        setSelectStatus,
        allRequestor, 
        setAllRequestor,
        selectRequestor, 
        setSelectRequestor,
        sortColumn,
        setSortColumn,
        scenarioPage,
        setScenarioPage,
        scenarioBusinessPage,
        setScenarioBusinessPage,
        stagesArray,
        setStagesArray,

        //Discover Module
        isSearchLoading,
        setIsSearchLoading,
        isKeySpecLoading, 
        setIsKeySpecLoading,
        hasSearched, 
        setHasSearched,
        clickedIssue,
        setClickedIssue,
        tagsTerm, 
        setTagsTerm,
        specData, 
        setSpecData,
        discoverColumns, 
        setDiscoverColumns,
        starIssueArray,
        setStarIssueArray,
        // Share:
        userShareArray, setUserSharedArray,
        blockShareStates, setShareBlockStates,
        // Save:
        userSaveArray, setUserSavedArray,
        blockSaveStates, setSaveBlockStates,
        // Hidden:
        userHiddenArray, setUserHiddenArray,
        blockHiddenStates, setHiddenBlockStates,

        rowsHeight, 
        setRowsHeight,
        rowsHeightMap, 
        setRowsHeightMap,
        clickedSimilarIssue,
        setClickedSimilarIssue,
        filterOptions, 
        setFilterOptions,
        basicValue, 
        setBasicValue,
        tableViews, 
        setTableViews,
        isSearchError,
        setIsSearchError,
        modalMessage,
        setModalMessage,
        inputIssue,
        setInputIssue,
        checkedRows, 
        setCheckedRows,
        currentRowIndex, 
        setCurrentRowIndex,
        issueArray,
        setIssueArray,
        issueDisplayArray,
        setIssueDisplayArray,
        showDropDown,
        setShowDropDown,
        issueMenu,
        setIssueMenu,
        IssuePageSize,
        setIssuePageSize,
        highlightArray,
        setHighlightArray,
        selectAllHighlight,
        setSelectAllHighlight,

        // Specication area
        speciIssueId, 
        setSpeciIssueId,
        isOpenSpecification, 
        setIsOpenSpecification,
        specificationArray,
        setSpecificationArray,
        isSpeciDragging,
        setIsSpeciDragging,
        speciStartX,
        setSpeciStartX,
        scrollSpeciLeft,
        setScrollSpeciLeft,
        scrollSpeciPosition,
        setScrollSpeciPosition,

        //addtional data area
        openItem, 
        setOpenItem,
        openDataCard, 
        setOpenDataCard,
        isAddtionDragging,
        setIsAddtionDragging,
        addtionStartX,
        setAddtionStartX,
        scrollAddtionLeft,
        setScrollAddtionLeft,
        addtionScrollPosition,
        setAddtionScrollPosition,
        additionalArray, 
        setAdditionalArray,
        specificationsMenu,
        setSpecificationsMenu,
        subKeySpecsMenu, 
        setSubKeySpecsMenu,
        selectIssueDetails, 
        setSelectIssueDetails,
        issueRelativeArray,
        setIssueRelativeArray,

        specMenu, 
        setSpecMenu,
        standMenu, 
        setStandMenu,

        // shared view mdoule
        sharedBy, 
        setSharedBy,
        sharedIssueArray, 
        setSharedIssuedArray,
        isSharedView, 
        setIsSharedView,
        sharedQuery, 
        setSharedQuery,
        timeStamp, 
        setTimeStamp,


        // achieve module
        isAchieveSideBarOpen, 
        setIsAchieveSideBarOpen,
        userGraphArray, setUserGraphArray,
        selectedOverviewOption, 
        setSelectedOverviewOption,
        selectedSystemOption, 
        setSelectedSystemOption,
        selectedLocationOption, 
        setSelectedLocationOption,
        selectAchieveSystem, 
        setSelectAchieveSystem,
        clickAchieveSystem, 
        setClickAchieveSystem,
        selectedProduct, 
        setSelectedProduct,
        selectedSeries, 
        setSelectedSeries,
        selectedModel, 
        setSelectedModel,
        openDetailsWindow, 
        setOpenDetailsWindow,
        productFormatColumn, setProductFormatColumn,
        productFormatData, setProductFormatData,
        clickProduct, 
        setClickProduct,
        clickFailureMode, 
        setClickFailureMode,
        selectedViews, 
        setSelectedViews,
        selectedFailureSeries, setSelectedFailureSeries,
        selectedFailureModel, setSelectedFailureModel,
        selectedFailureViews, setSelectedFailureViews,
        selectedFailureDesign, setSelectedFailureDesign,
        selectedFailureMilestones, setSelectedFailureMilestones,
        selectedAction, setSelectedAction,
        userClickViews, setUserClickViews,
        userClickModel, setUserClickModel,
        userClickBarChartView, setUserClickBarChartViews,
        searchText, setSearchText,
        appliedSearchText, setAppliedSearchText,
        showModelFilter, setShowModelFilter,
        selectedModels, setSelectedModels,
        allModels, setAllModels,
        expandedModelRows, setExpandedModelRows,
        expandedFailureModes, setExpandedFailureModes,
        failureModeChartData, setFailureModeChartData,
        sortTableData, setSortTableData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
