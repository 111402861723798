import React from "react";
import "./Optimizations.css";
import { Outlet, useLocation } from "react-router-dom";
import DefaultOptimization from "./DefaultOptimizations";

const Optimizations = () => {

    const location = useLocation();

    return <div className="optimizationsPage">
        {/* the default status page of issue overview */}
        {location.pathname === "/achieve/optimizations" && (
            <DefaultOptimization />
        )}

        {/* the child page below the issue overview */}
        <Outlet />
    </div>
}

export default Optimizations;