import React, { useState } from "react";
import './SortTable.css';
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "../../icons/Arrows";
import { ChevronUp, ChevronDown } from "../../icons/Arrows";
import FilterIcon from "../../icons/FilterIcon";
import SwitchButton from "../../keySpecs/SwitchImageButton/SwitchButton";
import { Badge } from "../../ui/Badge";

const SortTable = ({
    width,
    height,
    data,
    columns,
    rowHeight = "auto",
    selectedAction,  //user selected action row
    flex = 0,
    appliedSearchText = "", // Kept for displaying filter indication
}) => {
    const [currPage, setCurrPage] = useState(1);
    const [rowPage, setRowPage] = useState(10); // control how many rows in one page
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    // Handle column sort
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sort the data
    const sortedData = React.useMemo(() => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];
            
            // Find the column configuration for the current sort key
            const column = columns.find(col => col.key === sortConfig.key);
            
            // Handle different types of values based on column configuration
            if (column?.sortType === "number") {
                // Convert to numbers for numeric comparison
                const aNum = typeof aValue === 'number' ? aValue : Number(aValue) || 0;
                const bNum = typeof bValue === 'number' ? bValue : Number(bValue) || 0;
                return sortConfig.direction === 'asc' 
                    ? aNum - bNum 
                    : bNum - aNum;
            }
            if (sortConfig.key === 'Last activity' || column?.sortType === "date") {
                const aDate = new Date(aValue);
                const bDate = new Date(bValue);
                return sortConfig.direction === 'asc' 
                    ? aDate - bDate 
                    : bDate - aDate;
            }
            
            // Default string comparison
            return sortConfig.direction === 'asc'
                ? String(aValue || '').localeCompare(String(bValue || ''))
                : String(bValue || '').localeCompare(String(aValue || ''));
        });
    }, [data, sortConfig, columns]);

    const maxResult = sortedData.length;
    const startItem = (currPage - 1) * rowPage;
    const endItem = Math.min(currPage * rowPage, maxResult);

    // Reset page when sorting changes or data changes
    React.useEffect(() => {
        setCurrPage(1);
    }, [sortConfig, data]);

    // switch to next page
    const handleNextPage = () => {
        setCurrPage(currPage => currPage + 1);
    }

    // switch to previous page
    const handlePrevPage = () => {
        setCurrPage(currPage => currPage - 1);
    }

    // Helper function to determine row style based on rowHeight prop
    const getRowStyle = () => {
        // If rowHeight is "auto", return just position relative for dynamic sizing
        if (rowHeight === "auto") {
            return { position: "relative" };
        }
        // Otherwise, use the specified rowHeight
        return { height: rowHeight, position: "relative" };
    };

    return (
        <div className="sortTable" style={{ minWidth: width, maxWidth: width, minHeight: height, maxHeight: height, flex: flex}}>
            {/* Table Header */}
            <div className="sortTable-header">
                {columns.map((col) => (
                    <div
                        key={col.key}
                        className="sortTable-header-cell"
                        style={{ minWidth: col.width, maxWidth: col.width }}
                        onClick={() => handleSort(col.key)}
                    >
                        <div className="header-content">
                            <span>{col.label}</span>
                            <div className="sort-icons">
                                {sortConfig.key === col.key ? (
                                    sortConfig.direction === 'asc' ? (
                                        <ChevronUp size={16} color="#1570EF" />
                                    ) : (
                                        <ChevronDown size={16} color="#1570EF" />
                                    )
                                ) : (
                                    <FilterIcon size={16} />
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Table Body */}
            <div className="sortTable-body">
                {
                    // Render rows when we have data
                    sortedData.slice(startItem, endItem).map((row, rowIndex) => (
                        <div key={rowIndex} className="rowsArea">
                            <div
                                className={`sortTable-row ${rowIndex % 2 === 0 ? "even" : "odd"}`}
                                style={getRowStyle()}
                            >
                                {columns.map((col) => (
                                    <div
                                        key={col.key}
                                        className={`sortTable-cell ${col.key === 'Make_Model_Names' ? 'model-name-cell' : ''}`}
                                        style={{ minWidth: col.width, maxWidth: col.width }}
                                    >
                                        <span className={col.key === 'Make_Model_Names' ? 'model-names-content' : ''}>
                                            {col.render ? col.render(row[col.key], row) : String(row[col.key])}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                }
            </div>

            {/* Table Footer */}
            <div className="sortTable-footer">
                <div className="sortTable-left">
                    {maxResult > 0 ? (
                        `${startItem + 1} - ${endItem} of ${maxResult} Results`
                    ) : (
                        `0 Results`
                    )}
                    {appliedSearchText && (
                        <span className="search-filter-indicator"> • Filtered by: "{appliedSearchText}"</span>
                    )}
                </div>
                <div className="sortTable-right">
                    <SwitchButton
                        Icon={ChevronLeft}
                        onClick={handlePrevPage}
                        shape="circle"
                        disable={startItem <= 0}
                    />
                    <SwitchButton
                        Icon={ChevronRight}
                        onClick={handleNextPage}
                        shape="circle"
                        disable={endItem >= maxResult}
                    />
                </div>
            </div>
        </div>
    );
};

SortTable.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            width: PropTypes.string.isRequired,
            render: PropTypes.func,
            searchable: PropTypes.bool, // Flag to mark if column is searchable
            sortType: PropTypes.string, // Type of sort: "string", "number", "date"
        })
    ).isRequired,
    rowHeight: PropTypes.string,
    selectedAction: PropTypes.string,
    flex: PropTypes.number,
    appliedSearchText: PropTypes.string, // Kept for displaying filter indication
};

export default SortTable;
