import React from "react";
import './IssueOverview.css'
import { Outlet, useLocation } from "react-router-dom";
import DefaultIssueOverview from "./DefaultIssueOverview";

const IssueOverview = () => {

    const location = useLocation();

    // the main render area
    return (
        <div className="IssueOverviewArea">
            {/* the default status page of issue overview */}
            {location.pathname === "/achieve/issue-overview" && (
                <DefaultIssueOverview/>
            )}

            {/* the child page below the issue overview */}
            <Outlet />
        </div>
    )
}

export default IssueOverview