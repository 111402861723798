import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import './MilestonesCard.css'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabelItem, DropdownMenuCheckboxItem } from "../../ui/DropdownMenu/DropdownMenu";
import { PrintIcon, Share, Refresh } from "../../icons/General";
import Button from "../../ui/Button/Button";
import {
    Chart as ChartJS,
    LineElement,
    BarElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend,
    Filler
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";  

 
ChartJS.register(
    LineElement,
    BarElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend,
    Filler
);

const MilestonesCard = ({
    defaultHeight = "500px",
    defaultWidth = "500px",
    chartType = "Line Chart",  
    title,
    data, 
    BarData,  
    userClickViews,
    setUserClickViews,
    viewsDropdownArr,
    inactiveViews,
    hasModeDropDown,
    userClickModel,
    setUserClickModel,
    modeDropDownArray,
    isLoading,
}) => {
    const [tempSelectedModels, setTempSelectedModels] = useState(userClickModel);

    /******************** Line Chart Logic *********************/
    const lineChartData = useMemo(() => {
        if (chartType !== "Line Chart") return null;

        const labels = data && data.map(item => item["Years of Service"]);
        const firstDataset = {
            label: userClickModel[0],
            data: data && data.map(item => item["Reference_Model"]),
            borderColor: "#3b82f6",
            backgroundColor: "#3b82f6",
            borderWidth: 2,
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
        };

        const combinedSecondData = data && data.map(item =>
            item["Current_Model"] !== null && item["Current_Model"] !== undefined
                ? item["Current_Model"]
                : item["Estimation"]
        );

        const combinedSecondDataset = {
            label: userClickModel[1],
            data: combinedSecondData,
            segment: {
                borderColor: (ctx) => {
                    const currentData = data[ctx.p0DataIndex];
                    const nextData = data[ctx.p1DataIndex];
                    if (currentData["Current_Model"] === null || nextData["Current_Model"] === null) {
                        return "#98A2B3";
                    }
                    return "#B42318";
                },
                borderDash: (ctx) => {
                    const currentData = data[ctx.p0DataIndex];
                    const nextData = data[ctx.p1DataIndex];
                    if (currentData["Current_Model"] === null || nextData["Current_Model"] === null) {
                        return [5, 5];
                    }
                    return [];
                },
            },
            borderWidth: 2,
            tension: 0.1,
            pointRadius: 4,
            pointHoverRadius: 6,
            pointBackgroundColor: (ctx) =>
                data[ctx.dataIndex]["Current_Model"] !== null
                    ? "#B42318"
                    : "#98A2B3",
            pointBorderColor: "#ffffff",
            pointBorderWidth: 1,
            pointStyle: 'circle',
        };

        // Estimated dataset (only for legend)
        const estimatedDataset = {
            label: "Estimated",
            data: [], // empty data
            borderColor: "#98A2B3",
            backgroundColor: "#98A2B3",
            borderWidth: 2,
            borderDash: [5, 5],
            pointRadius: 0, // hide points
            pointHoverRadius: 0,
            pointBackgroundColor: "#98A2B3",
            pointBorderColor: "#ffffff",
            pointBorderWidth: 1,
            pointStyle: 'circle',
        };

        return {
            labels,
            datasets: [firstDataset, combinedSecondDataset, estimatedDataset],
        };
    }, [data, userClickModel, chartType]);

    // the model selected (multiple selected)
    const toggleTempModel = (e, item) => {
        e.preventDefault();
        setTempSelectedModels((prev) => {
            if (prev.includes(item)) {
                return prev.filter((model) => model !== item);
            } else {
                if (prev.length >= 2) return prev;
                return [...prev, item];
            }
        });
    };

    const handleRefreshClick = () => {
        if (tempSelectedModels.length > 0) {
            setUserClickModel(tempSelectedModels);
        }
    }

    // it will judge the temporay dropdown menu selection
    const shouldDisableItem = (item) => {
        if (tempSelectedModels.length === 0) return false;

        if (tempSelectedModels.length === 1) {
            const selected = tempSelectedModels[0];
            return item < selected;
        }

        if (tempSelectedModels.length === 2) {
            return !tempSelectedModels.includes(item);
        }

        return false;
    }

    /******************** Bar Chart Logic *********************/
    const barChartData = useMemo(() => {
        if (chartType !== "Bar Chart" || !BarData) return null;
                                             
        const locations = userClickViews === "Technicians" ?  Object.keys(BarData["estimated_technician_per_location"] || {}) : Object.keys(BarData["estimated_cost_per_location"] || {});

        return {
            labels: locations,
            datasets: [
                {
                    label: "Estimated Allocation",
                    data: userClickViews === "Technicians" ? locations.map(loc => BarData["estimated_technician_per_location"][loc]) : locations.map(loc => BarData["estimated_cost_per_location"][loc]),
                    backgroundColor: "#175CD3",
                    borderWidth: 1,
                    barThickness: 32,
                },
                {
                    label: "Current Allocation",
                    data: userClickViews === "Technicians" ? locations.map(loc => BarData["total_technician_per_location"][loc]) : locations.map(loc => BarData["total_cost_per_location"][loc]),
                    backgroundColor: "#84CAFF",
                    borderWidth: 1,
                    barThickness: 32,
                }
            ]
        };
    }, [BarData, chartType]);

    /******************** THE bar chart option *********************/
    const commonOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                align: 'end',
                labels: {
                    font: { size: 12, family: "Inter, sans-serif" },
                    padding: 15,
                    boxWidth: 12,
                    usePointStyle: true,
                },
            },
        },
    };

    const lineChartOptions = {
        ...commonOptions,
        scales: {
            x: {
                title: { display: true, text: "Years of Service" },
                grid: { display: true, color: "rgba(200, 200, 200, 0.2)" },
                font: { size: 12, family: "Inter, sans-serif" },
            },
            y: {
                title: { display: true, text: userClickViews },
                min: 0,
                grid: { display: true, color: "rgba(200, 200, 200, 0.2)" },
                font: { size: 12, family: "Inter, sans-serif" },
            },
        },
    };

    const barChartOptions = {
        ...commonOptions,
        scales: {
            x: {
                title: { display: true, text: "Location" },
                grid: { display: false },
            },
            y: {
                title: { display: true, text: userClickViews },
                min: 0,
                grid: { display: true, color: "rgba(200, 200, 200, 0.2)" },
            },
        },
    };


    // the search animation effect dots numbers
    const dots = Array.from({ length: 16 });

    return <div className="MilestonesCard" style={{ width: defaultWidth, minHeight: defaultHeight }}>
        {/* the dropdown menu used for selection switch system */}
        <div className="MilestonesCardMenuArea">
            <div className="leftDropDownArea">
                {title}
                <DropdownMenu>
                    <DropdownMenuTrigger height="32px">
                        <span className="dropdown-menu-trigger-text">{userClickViews}</span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <div className="milestonesDropDownMenuArea">
                        {viewsDropdownArr.map((item) => {
                            const isDisabled = inactiveViews?.includes(item);
                            return (
                                <DropdownMenuLabelItem
                                    key={item}
                                    checked={userClickViews === item}
                                    onSelect={(e) => {
                                        if (isDisabled) {
                                            e.preventDefault(); 
                                            return;
                                        }
                                        setUserClickViews(item);
                                    }}
                                    style={{
                                        color: isDisabled ? "#999" : "inherit",
                                        cursor: isDisabled ? "not-allowed" : "pointer",
                                        pointerEvents: isDisabled ? "none" : "auto", // optional, makes it fully unclickable
                                        opacity: isDisabled ? 0.6 : 1,
                                    }}
                                    title={isDisabled ? "Coming soon" : ""}
                                >
                                    {item}
                                </DropdownMenuLabelItem>
                            );
                        })}
                        </div>
                    </DropdownMenuContent>
                </DropdownMenu>

                {hasModeDropDown && <DropdownMenu>
                    <DropdownMenuTrigger width="130px" height="32px">
                        <span className="dropdown-menu-trigger-text">Select Model</span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent width="130px">
                        <div className="milestonesDropDownMenuArea">
                            {modeDropDownArray.map((item) => (
                                <DropdownMenuCheckboxItem
                                    key={item}
                                    checked={tempSelectedModels.includes(item)}
                                    onSelect={(e) => toggleTempModel(e, item)}
                                    disabled={shouldDisableItem(item)}
                                >
                                    {item}
                                </DropdownMenuCheckboxItem>
                            ))
                            }
                        </div>
                    </DropdownMenuContent>
                </DropdownMenu>
                }

                {hasModeDropDown && <Button
                    iconOnly
                    size="small"
                    variant="secondary"
                    onClick={handleRefreshClick}
                    disabled={tempSelectedModels.length === 0}
                >
                    <Refresh size={20} color="#667085" />
                </Button>}
            </div>

            <div className="rightButtonArea">
                <Button
                    iconOnly
                    size="small"
                    variant="tertiary-gray"
                    style={{ padding: "0px" }}
                //onClick={onClose}
                >
                    <PrintIcon size={20} color="#667085" />
                </Button>
                <Button
                    iconOnly
                    size="small"
                    variant="tertiary-gray"
                    style={{ padding: "0px" }}
                //onClick={onClose}
                >
                    <Share size={20} color="#667085" />
                </Button>

            </div>
        </div>

        <div className="MilestonesChartArea">
            {
                isLoading && <div className="fade-in">
                    <div className="dots-loader">
                        {dots.map((_, index) => (
                            <div
                                key={index}
                                className="dot"
                                style={{
                                    transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                                    animationDelay: `${index * 0.125}s`
                                }}
                            />
                        ))}
                    </div>
                </div>
            }
            {chartType === "Line Chart" && !isLoading && lineChartData && (
                <Line data={lineChartData} options={lineChartOptions} />
            )}

            {chartType === "Bar Chart" && !isLoading && barChartData && (
                <Bar data={barChartData} options={barChartOptions} />
            )}
        </div>

    </div>
}

MilestonesCard.propTypes = {
    defaultHeight: PropTypes.string,
    defaultWidth: PropTypes.string,
    data: PropTypes.array,
    BarData: PropTypes.object,
    userClickViews: PropTypes.string.isRequired,
    setUserClickViews: PropTypes.func.isRequired,
    viewsDropdownArr: PropTypes.array.isRequired,
    hasModeDropDown: PropTypes.bool,
    userClickModel: PropTypes.array,
    setUserClickModel: PropTypes.func,
    modeDropDownArray: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default MilestonesCard;