import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthStore from './../stores/useAuthStore';

const AuthRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { user, loading } = useAuthStore();
  const location = useLocation();

  if (isLoading) {
    return <></>;
  }

  if (!loading && (!isAuthenticated || !user)) {
    console.warn("[AuthRoute] Navigating to login.")
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthRoute;
