import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { fetchUser, postUser } from "../../../api/queries";
import { useAuth0Token } from "../../../hooks/useAuth0Token";
import useAuthStore from "../../../stores/useAuthStore";
import companyLogoWithoutName from "../../../image/ApiphanyLogo/ApiphanyIconWithoutName.jpg";
import { InputFields, Button } from "../../../Components";
import { FormHeader } from "../../../index";
import { useLocation } from "react-router-dom";
import "./layout.css";

const Register = () => {
  const { user, isAuthenticated } = useAuth0();
  const { getToken } = useAuth0Token();
  const { setAuth, setLoading } = useAuthStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const location = useLocation();

  const initialFirstName = location.state?.first_name || "";
  const initialLastName = location.state?.last_name || "";
  const initialEmail = location.state?.email || "";

  const [formData, setFormData] = useState({
    first_name: initialFirstName,
    last_name: initialLastName,
    email: initialEmail,
  });
  const [formErrors, setFormErrors] = useState({});


  const [errorMessage, setErrorMessage] = useState("");
  const nasa_org_id = 1;

  const validateForm = () => {
    const errors = {};
    if (!formData.first_name.trim()) errors.first_name = "First name is required.";
    if (!formData.last_name.trim()) errors.last_name = "Last name is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const createUserMutation = useMutation(postUser, {
    onSuccess: async (response) => {
      const newUser = await fetchUser(getToken);
  
      queryClient.setQueryData(["user", user?.sub], newUser);
      setAuth(newUser);
      setLoading(false);
      navigate("/discover");
    },
    onError: (error) => {
      setErrorMessage("An error occurred while creating your account.");
      setLoading(false);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setFormErrors({});

    if (!validateForm()) return;
    setLoading(true);
  
    const payload = {
      getToken,
      userData: {
        first_name: formData.first_name || user?.given_name || "TEMP",
        last_name: formData.last_name || user?.family_name || "TEMP",
        organization_id: nasa_org_id,
      },
    };
  
    console.log("[Register] Submitting registration with payload:", payload);
  
    createUserMutation.mutate(payload);
  };

  if (!isAuthenticated) {
    return <p>Please log in first to register.</p>;
  }

  return (
      <div className="loginComp">
        <div className="login-container">
          <FormHeader
            title="Create your account"
            subtitle="Enter your details to register."
            className="form-header"
            companyImage={companyLogoWithoutName}
          />

          <form onSubmit={handleSubmit}>
            <InputFields
                label="First Name"
                placeholder="Enter your first name"
                width="334px"
                height="24px"
                value={formData.first_name}
                onChange={handleChange}
                name="first_name"
                isPassword={false}
                type={formErrors.first_name ? "errorWithBlack" : "generalWithBlack"}
                errorMessage={formErrors.first_name}
                isRequired
            />

            <InputFields
                label="Last Name"
                placeholder="Enter your last name"
                width="334px"
                height="24px"
                value={formData.last_name}
                onChange={handleChange}
                name="last_name"
                isPassword={false}
                type={formErrors.last_name ? "errorWithBlack" : "generalWithBlack"}
                errorMessage={formErrors.last_name}
                isRequired
            />

            <InputFields
                label="Email"
                width="334px"
                height="24px"
                value={formData.email}
                onChange={handleChange}
                name="email"
                isPassword={false}
                type="disabled"
                isRequired
            />

            {errorMessage && (
              <div style={{ color: "red", marginBottom: "10px", fontSize: "13px" }}>
                {errorMessage}
              </div>
            )}

            <Button type="submit" variant="primary" size="small" width="100%">
              Register
            </Button>
          </form>
        </div>
      </div>
  );
};

export default Register;
