import { create } from "zustand";
import { persist } from "zustand/middleware";

const useAuthStore = create(
  persist(
    (set) => ({
      user: null,
      loading: true,
      setAuth: (userData) =>
        set({ user: userData, loading: false }),
      clearAuth: () => set({ user: null, loading: false }),
      setLoading: (value) => set({ loading: value }),
    }),
    {
      name: "auth-storage",
      getStorage: () => localStorage,
    }
  )
);
export default useAuthStore;
