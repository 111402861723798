// the cutomize card used to display the data of key specification area
import React from "react";
import PropTypes from "prop-types";
import "./CommentItem.css";

const CommentItem = ({
    author,
    timestamp,
    context,
    defaultWidth = "350px", 
}) => {
    return (
        <div className="commentItem" style={{ width: defaultWidth }}>
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                <span className="userNameStyle"> @{author} </span>
                <span className="timeStyle"> {timestamp} </span>
            </div>

            <div
                dangerouslySetInnerHTML={{
                    __html: context,
                }}
                className="contentStyle"
            />
        </div>
    );
};

CommentItem.propTypes = {
    author: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
    context: PropTypes.string.isRequired,
    defaultWidth: PropTypes.string,
};

export default CommentItem;