// Failure Mode Level (3)
import React, {useState} from "react";
import './Trends.css';
import { ChevronRight } from "../../../Components/icons/Arrows";
import { useAppContext } from "../../../AppContext";
import { useGroupQuery, useTimeEvolutionQuery, useCompoDescription } from "../../../api/queries";
import { FormatTable, Badge, IssueTrendsBar, BarChart, DatePicker, Button} from "../../../Components";
import {  DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuCheckboxItem, DropdownMenuLabelItem } from "../../../Components/ui/DropdownMenu/DropdownMenu";

const FailureModePage = () => {
    const { openDetailsWindow, setOpenDetailsWindow,
        clickProduct, setClickProduct, setClickFailureMode,
        selectedFailureSeries, setSelectedFailureSeries,
        selectedFailureModel, setSelectedFailureModel,
        selectedFailureViews, setSelectedFailureViews, clickAchieveSystem,
        selectedFailureDesign, setSelectedFailureDesign,
        selectedFailureMilestones, setSelectedFailureMilestones,
    } = useAppContext();

    // call the backend API end point ==> using the product or componment name to get the data
    // 3.1 The summary of current componment or product
    const { data: summaryData, isLoading: summaryDataLoading } = useCompoDescription({
        component: clickProduct.Name || null,
    });

    // 3.2: the bar chart data with time
    const { data: timeEvolution, isLoading: timeEvolutionLoading } = useTimeEvolutionQuery({
        component: clickProduct.Name || null,
    });

    // 3.3  the fauiler mode format data
    const { data: failureModeFormatData, isLoading: failureModeFormatDataLoading } = useGroupQuery({
        component: clickProduct.Name || null,
        model: null,
        series: null
    });

    // fake data:
    const modelArray = (timeEvolution && !timeEvolutionLoading) ? Object.keys(timeEvolution).sort() : [];
    const currSeriesArray =  (timeEvolution && !timeEvolutionLoading && selectedFailureModel) ? Object.keys(timeEvolution[selectedFailureModel]).sort() : ["100", "200", "300", "400", "500", "600", "700", "800", "900"];

    const viewsArray = ["Cost", "Failure Rate", "Resolution time"];
    
    // const stagesArray = ["Maintenance", "Field Testing"];

    // 3. the third level: the failure mode search range:
    const [dateFailureModeRange, setDateFailureModeRange] = useState({
        startDate: null,
        endDate: null
    });

    // hanlde get the user select date from date picker component in the failure mode search
    const handleFailureModeRange = (dates) => {
        setDateFailureModeRange(dates);
    };

    // the model dropdown menu click function: when user switch the model, unselect all the series that user clicked
    const toggleClickModel = (item) => {
        setSelectedFailureModel(item);
        setSelectedFailureSeries([]);
    }

    // the model selected (multiple selected)
    const toggleClickSeries = (e, item) => {
        e.preventDefault();
        setSelectedFailureSeries((prev) => {
            if (prev.includes(item)) {
                return prev.filter((model) => model !== item);
            } else {
                return [...prev, item];
            }
        });
    };

    return <>
        <div className="pageRoute">
            <button className={`routeButton DisableClicked`}> Achieve </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton DisableClicked`}> System Trends </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton`}> {clickAchieveSystem.Name} </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}>
                {clickProduct.Name}: Failure modes
            </button>
        </div>

        <span className="detailWindowTitle">{clickProduct.Name}</span>
       { (!summaryDataLoading && summaryData) && <span className="detailWindowText">{summaryData.Description || ""}</span>}


        <div className="failureModeChartArea">
            <div className="seriesMode">
                <DropdownMenu>
                    <DropdownMenuTrigger width="130px" height="34px">
                        <span className="dropdown-menu-trigger-text">{selectedFailureModel|| "Select Model"}</span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent width="130px">
                        {modelArray.length > 0
                            ? modelArray.map((item) => (
                                <DropdownMenuLabelItem
                                    key={item}
                                    checked={selectedFailureModel === item}
                                    onSelect={() => toggleClickModel(item)}
                                >
                                    {item}
                                </DropdownMenuLabelItem>
                            ))
                            : <div>No data available</div>
                        }
                    </DropdownMenuContent>
                </DropdownMenu>

                <DropdownMenu>
                    <DropdownMenuTrigger width="133px" height="34px">
                        <span className="dropdown-menu-trigger-text">Select Series</span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent width="133px">
                        {currSeriesArray.map((item) => (
                            <DropdownMenuCheckboxItem
                                key={item}
                                checked={selectedFailureSeries.includes(item)}
                                onSelect={(e) => toggleClickSeries(e, item)}
                            >
                                {item}
                            </DropdownMenuCheckboxItem>
                        ))
                        }
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>

            <DropdownMenu>
                <DropdownMenuTrigger width="180px" height="34px">
                    <span className="dropdown-menu-trigger-text">{selectedFailureViews || "Select Views"}</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent width="180px">
                    {viewsArray.map((item) => (
                        <DropdownMenuLabelItem
                            key={item}
                            checked={selectedFailureViews === item}
                            onSelect={() => setSelectedFailureViews(item)}
                        >
                            {item}
                        </DropdownMenuLabelItem>
                    ))
                    }
                </DropdownMenuContent>
            </DropdownMenu>

            {/* <DropdownMenu>
                <DropdownMenuTrigger width="146px" height="34px">
                    <span className="dropdown-menu-trigger-text">{selectedFailureDesign || "Design Stages"}</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent width="146px">
                    {stagesArray.map((item) => (
                        <DropdownMenuLabelItem
                            key={item}
                            checked={selectedFailureDesign === item}
                            onSelect={() => setSelectedFailureDesign(item)}
                        >
                            {item}
                        </DropdownMenuLabelItem>
                    ))
                    }
                </DropdownMenuContent>
            </DropdownMenu> */}

            {/* <DropdownMenu>
                <DropdownMenuTrigger width="146px" height="34px">
                    <span className="dropdown-menu-trigger-text">{selectedFailureMilestones || "Milestones"}</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent width="146px">
                    {stagesArray.map((item) => (
                        <DropdownMenuLabelItem
                            key={item}
                            checked={selectedFailureMilestones === item}
                            onSelect={() => setSelectedFailureMilestones(item)}
                        >
                            {item}
                        </DropdownMenuLabelItem>
                    ))
                    }
                </DropdownMenuContent>
            </DropdownMenu> */}

            <DatePicker
                width="460px"
                height="428px"
                initialMode="Since"
                startDate={dateFailureModeRange.startDate}
                endDate={dateFailureModeRange.endDate}
                onApply={handleFailureModeRange}
            />
        </div>

        <BarChart
            width="calc(100% - 48px)"
            height="400px"
            data={timeEvolutionLoading ? {} : timeEvolution[selectedFailureModel]}
            selectedFailureViews={selectedFailureViews}
            selectedFailureSeries={selectedFailureSeries}
            dateFailureModeRange={dateFailureModeRange}
            isLoading={timeEvolutionLoading}
        />

        <div className="TrendsSingleBarArea">
            <span className="detailWindowTitle">Issue trends: </span>
            <IssueTrendsBar
                width="100%"
                height="14px"
                data={[
                    { name: "Re-opened", occurrences: 10, color: "#FDA29B" },
                    { name: "Temporarily resolved", occurrences: 10, color: "#FEDF89" },
                    { name: "Stayed closed", occurrences: 80, color: "#75E0A7" },
                ]}
            />
        </div>


       <FormatTable
            width="100%"
            height="640px"
            data={failureModeFormatData}
            columns={[
                { key: "Failure Mode", label: "Failure Mode", width: "27%" },
                // {
                //     key: "Failure Type",
                //     label: "Failure Type",
                //     width: "12%",
                //     render: (value) => (
                //         <div className="product-cell">
                //             <Badge type="category" variant={getColorVersion(value)}>
                //                 {value}
                //             </Badge>
                //         </div>
                //     ),
                // },
                { key: "Total Downtime", label: "Total Downtime", width: "12%" },
                { key: "Resolutions", label: "Resolutions", width: "10%" },
                { key: "Mitigation steps", label: "Mitigation steps", width: "10%" },
                { key: "Avg. Downtime", label: "Avg. Downtime", width: "10%" },
                {
                    key: "Open Issues",
                    label: "Open Issues",
                    width: "10%",
                    render: (value) => (
                        <div className="product-cell">
                            <Badge type="status" variant="Current">
                                {value} issues
                            </Badge>
                        </div>
                    ),
                },
                { key: "Last Occurrence", label: "Last Occurrence", width: "13%" },
                { key: "Occurrences", label: "Occurrences", width: "8%" },
            ]}
            rowHeight="50px"
            setOpenDetailsWindow={setOpenDetailsWindow}
            setClickProduct={setClickProduct}
            setClickFailureMode={setClickFailureMode}
            currentLevel={openDetailsWindow}
            isLoading={failureModeFormatDataLoading}
        />

    </>
}

export default FailureModePage;