import PropTypes from 'prop-types';
import './StatCard.css';

const StatCard = ({ label, number, percentage }) => {
  return (
    <div className="statCard">
      <div className="label">{label}</div>
      <div className="valueRow">
        <span className="number">{number}</span>
        {percentage && <span className="percentage">{percentage}%</span>}
      </div>
    </div>
  );
};

StatCard.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  percentage: PropTypes.number,
};

export default StatCard;
