// the Achieve Add chart search & Add input area
import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import "./ChartSearch.css";
import { Search } from "../../icons/General";
import Button from "../../ui/Button/Button";

const ChartSearch = ({
    width = "350px",
    height = "34px",
    placeholder="",
    options = [],
    onSearch, 
    flex = 0,
    inputValue,
    setInputValue,
}) => {
    // const [inputValue, setInputValue] = useState(""); // the user input value
    const [dropDownOptions, setDropDownOptions] = useState(options); // dropdown area item
    const [dropdownVisible, setDropdownVisible] = useState(false); // whether open dropdown menu or not
    const searchRef = useRef(null); // the search UI change listener

    // Handle input box changes
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Filter matching items
        const matches = options.filter((item) =>
            item.toLowerCase().startsWith(value.toLowerCase())
        );
        setDropDownOptions(matches);
        setDropdownVisible(matches.length > 0);
    };



    // Process the selected drop-down item
    const handleSelectItem = (value) => {
        setInputValue(value);
        onSearch(value);
        setDropdownVisible(false);

        // the function, passed user selected product to parents level
        if (onSearch) onSearch(value);
    };


    return <>
        <div className={`chartSearch-box ${inputValue ? "isSearched" : ""}`}
            style={{
                flex: flex > 0 ? flex : "unset",
                width: flex > 0 ? "100%" : width,
                maxHeight: height,
                minHeight: height
            }}
            ref={searchRef}
        >
            <Search size={16} color="#667085" />

            {/* The input area */}
            <input
                type="text"
                placeholder={placeholder}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setDropdownVisible(dropDownOptions.length > 0)}
                onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
            />

            {/* the search field dropdown menu */}
            {dropdownVisible && (
                <div className="chartSearchDropdown">
                    {dropDownOptions.map((item) => (
                        <div key={item} className="chartSearchDropdown-item" onClick={() => handleSelectItem(item)}>
                            {item}
                        </div>
                    ))}
                </div>
            )}
        </div>

        <Button variant="primary" size="small" onClick={() => {
            if (inputValue.trim()) {
                onSearch(inputValue);
                setDropdownVisible(false);
            }
        }}>
            Apply
        </Button>
    </>
}

ChartSearch.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  searchItem: PropTypes.string,
  onSearch: PropTypes.func,
};

export default ChartSearch;