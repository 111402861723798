import React from "react";
import Plot from "react-plotly.js";
import "./DesignChart.css";
import PropTypes from "prop-types";
import backgroundImage from "../../../image/chartBackground.png";

const DesignChart = ({
    width,
    height,
    title = "Define Your Chart Graph",
    chartData,  
    chartLayout, 
    flex = 1,
    isLoading,
    isError,
}) => {
    // the search animation effect dots numbers
    const dots = Array.from({ length: 16 });

    return (
        <div
            className="designChartArea"
            style={{
                flex: flex > 0 ? flex : "unset",
                width: flex > 0 ? "100%" : width,
                minHeight: height,
                maxHeight: height,
            }}
        >
            <div className="designChartAreaTitle">
                {isError && <span className="errorTitle">Query is not relevant!</span>}
                {!isError && title}    
            </div>

            {
                isError && <div className="ErrorMessageUI">
                    <span className="errorTitle">We couldn't create a visualization for this request.</span>
                    <span className="errorText">Please check that your query includes "Client" data with measurable factors. For example: 
                        "Show me the distribution of primary problems" or "A bar chart of issues by stage grouped by status"</span>
                </div>
            }

            {(!isLoading && (!chartData && !chartLayout) && !isError) && (
                <img
                    src={backgroundImage}
                    alt="Chart Background"
                    className="chartBackground"
                />
            )}

            {isLoading && (
                <div className="fade-in">
                    <div className="dots-loader">
                        {dots.map((_, index) => (
                            <div
                                key={index}
                                className="dot"
                                style={{
                                    transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                                    animationDelay: `${index * 0.125}s`
                                }}
                            />
                        ))}
                    </div>
                </div>
            )}
            
            { !isLoading && !isError && chartData && chartLayout && (
                <Plot
                    data={chartData}
                    style={{ width: '100%', height: '100%' }}
                    useResizeHandler={true}
                />
            )}
        </div>
    );
};

DesignChart.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    title: PropTypes.string,
    htmlGraph: PropTypes.string,
    flex: PropTypes.number,
};

export default DesignChart;

