import React from "react";
import "./Milestones.css";
import { ChevronRight } from "../../../Components/icons/Arrows";
import { MilestonesCard, Badge, MilestonesFormat } from "../../../Components";
import { useAppContext } from "../../../AppContext";
import { useCostEstimationQuery, useFailureEstimationQuery, useCostAllocationQuery, useTechnicianAllocationQuery, useGroupAllocationQuery} from "../../../api/queries";

const Milestones = () => {

    const { userClickViews, setUserClickViews,
        userClickModel, setUserClickModel,
        userClickBarChartView, setUserClickBarChartViews
    } = useAppContext();

    // call the API function
    // first graph:
    const { data: costEstimation, isLoading: iscostEstimationLoading, error: costEstimationError } = useCostEstimationQuery({
        referenceModel: userClickModel[0],
        currentModel: userClickModel[1]
    });

    const { data: failureEstimation, isLoading: isfailureEstimationLoading, error: failureEstimationError } = useFailureEstimationQuery({
        referenceModel: userClickModel[0],
        currentModel: userClickModel[1]
    });

    // second graph:
    const { data: costAllocation, isLoading: isCostAllocationLoading, error: costAllocationError } = useCostAllocationQuery({
        referenceModel: userClickModel[0],
        currentModel: userClickModel[1]
    });
 

    const { data: techEstimation, isLoading: istechEstimationLoading, error: techEstimationError } = useTechnicianAllocationQuery({
        referenceModel: userClickModel[0],
        currentModel: userClickModel[1]
    });
 

    // the format API:
    const { data: groupAllocation, isLoading: isGroupAllocationLoading, error: groupAllocationError } = useGroupAllocationQuery({
        model: userClickModel[0],
    });

    const isLoading = iscostEstimationLoading || isfailureEstimationLoading; // first graph judeing loading
    const isBarChartLoading = isCostAllocationLoading || istechEstimationLoading; // the bar chart loading

    const getColorVersion = (label) => {
        if (!label) return "category-gray";
        switch (label) {
            case "system":
                return "category-green";
            case "design":
                return "category-green";
            case "Maintenance":
                return "category-red";
            case "component":
                return "category-yellow";
            case "assembly":
                return "category-purple";
            case "supplier quality":
                return "category-blue";
            default:
                return "category-gray";
        }
    };

    return <div className="milestonesPage">
         <div className="pageRoute">
            <button className={`routeButton DisableClicked`}> Achieve </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton DisableClicked`}> System Metrics </button>
            <ChevronRight size={16} color="#D0D5DD" />
            <button className={`routeButton isClicked`}> Forecast </button>
        </div>

        <span className="PageText">Forecasting</span>

        <MilestonesCard
            data={userClickViews === "Cost" ? costEstimation : failureEstimation}
            userClickViews={userClickViews}
            setUserClickViews={setUserClickViews}
            viewsDropdownArr={["Cost", "Failure", "Duplicate Failures", "Downtime", "Effectiveness"]}
            inactiveViews={["Duplicate Failures", "Downtime", "Effectiveness"]}
            hasModeDropDown={true}
            userClickModel={userClickModel}
            setUserClickModel={setUserClickModel}
            modeDropDownArray={["B737", "B747", "B757", "B767", "B777", "B787"]}
            defaultWidth="100%"
            title="Timeline"
            chartType="Line Chart"
            isLoading={isLoading}
        />

        <MilestonesCard
            BarData={userClickBarChartView === "Technicians" ? techEstimation : costAllocation}
            userClickViews={userClickBarChartView}
            setUserClickViews={setUserClickBarChartViews}
            viewsDropdownArr={["Technicians", "Cost", "Stock", "Experience", "Inspections"]}
            inactiveViews={["Stock", "Experience", "Inspections"]}
            hasModeDropDown={false}
            defaultWidth="100%"
            defaultHeight="500px"
            title="Resources Allocation"
            chartType="Bar Chart"
            isLoading={isBarChartLoading}
        />

        <MilestonesFormat
            width="100%"
            height="calc(100vh - 180px)"
            data={groupAllocation || []}
            columns={[
                {
                    key: "Failure Mode",
                    label: "Failure Mode",
                    width: "31%",
                },
                {
                    key: "Resolutions",
                    label: "Resolutions",
                    width: "9%",
                },
                {
                    key: "Mitigation steps",
                    label: "Mitigation Steps",
                    width: "10%",
                },
                {
                    key: "Avg. Downtime",
                    label: "Avg. Downtime",
                    width: "10%",
                },
                {
                    key: "Open Issues",
                    label: "Open Issues",
                    width: "11%",
                    render: (value) => (
                        <div className="product-cell">
                            <Badge type="status" variant="Current">
                                {value} issues
                            </Badge>
                        </div>
                    ),
                },
                {
                    key: "Last Location",
                    label: "Last Location",
                    width: "10%",
                },
                { 
                    key: "Occurrences", 
                    label: "Occurrences", 
                    width: "8%" ,
                },
                { 
                    key: "Last Occurrence", 
                    label: "Last Occurrence", 
                    width: "10%" ,
                },
            ]}
            rowHeight="50px"
            isLoading={isGroupAllocationLoading}
        />
    </div>
}

export default Milestones;
