// Display the share, save, and hidden issue block (recived the data from parents componemnt level)
import React, { useState, useEffect } from "react";
import "./IssueBlock.css";
import { QueryHeader, NotificationComp } from "../../../Components";
import CustomizeTable from "../Issue Table/CustomizeTable/CustomizeTable";
import { useAppContext } from "../../../AppContext";
import toast from 'react-hot-toast';
import { 
  useHideIssuesMutation,
  useUnhideIssuesMutation,
  useSaveIssuesMutation,
  useDeleteSavedIssuesMutation,
  useOrganizationUsersQuery,
  useShareIssuesMutation,
  useToggleFavoriteMutation
} from '../../../api/queries';
import { ArrowUp } from "../../../Components/icons/Arrows";

const IssueBlock = ({
  displayColumnswithCustomHeader,
  // handleHeaderCheckboxChange,
  // handleCheckboxChange,
  handleColumnResize,
  handleCellClick,
  handleRowClick,
  // setIssuePageSize,
  isSharedView,
  tableViews,
  selectedAreas,
  handleAreaChange,
  handleSaveSystem,
  checkOpenTab,
  isOpenSpecification,
  rowsHeight,
  // setRowsHeight,
  handleRowHover,
  handleRowLeave,
  handleMouseMove,

  // recived the data from parents level componment
  blockArray,
  dataBlock,
  setDataBlock,
}) => {
  // create the indpence state for each block
  const {setIssueDisplayArray, setIssueRelativeArray, 
    clickedSimilarIssue, setClickedSimilarIssue} = useAppContext();
  const [showEmailSelect, setShowEmailSelect] = useState(false); // the people's email of share feature dropdown menu
  const [selectedIssueIds, setSelectedIssueIds] = useState([]); // the issue ids that user selected for save hide share and star


  // the header checkbox Functions: handle various table-related operations need to know which block the operation is for
  const handleBlockHeaderCheckboxChange = (blockIndex) => {
    setDataBlock((prevStates) => {
      const newStates = [...prevStates];
      
      // check whether this block was exist or not
      if (!blockArray || !blockArray[blockIndex]) {
        console.error(`Block at index ${blockIndex} not found`);
        return prevStates; // if not exist, return deafult status
      }
      
      const block = blockArray[blockIndex];
      const newCheckedRows = new Set();
      
      // check current block was all selected or not
      if (newStates[blockIndex]?.checkedRows.size === block.issues.length) {
        // if all selected, unselect all
        newStates[blockIndex].checkedRows = newCheckedRows;
      } else {
        // if not, selected all the rows
        block.issues.forEach(row => newCheckedRows.add(row["Issue ID"]));
        newStates[blockIndex].checkedRows = newCheckedRows;
      }
      return newStates;
    });
  };

  // the signgle row checkbox function
  const handleBlockCheckboxChange = (blockIndex, id) => {
    setDataBlock((prevStates) => {
      const newStates = [...prevStates];
      const newCheckedRows = new Set(newStates[blockIndex].checkedRows);
      
      if (newCheckedRows.has(id)) {
        newCheckedRows.delete(id);
      } else {
        newCheckedRows.add(id);
      }
      
      newStates[blockIndex].checkedRows = newCheckedRows;
      
      return newStates;
    });
  };

  // the cell click function: handle various table-related operations need to know which block the operation is for
  const handleBlockCellClick = (blockIndex, cellInfo, rowIndex) => {
    setDataBlock((prevStates) => {
      const newStates = [...prevStates];
      newStates[blockIndex].clickedIssue = cellInfo.row;
      newStates[blockIndex].currentRowIndex = rowIndex;
      return newStates;
    });
    
    // call the parents function
    if (handleCellClick) {
      handleCellClick(cellInfo, rowIndex);
    }
  };

  // setup the single bloack difference row size
  const handleBlockSetIssuePageSize = (blockIndex, newPageSize) => {
    setDataBlock((prevStates) => {
      const newStates = [...prevStates];
      newStates[blockIndex].issuePageSize = newPageSize;
      return newStates;
    });
  };

  // used for difference block change he row height
  const handleBlockRowsHeightMap = (blockIndex, newMap) => {
    setDataBlock((prevStates) => {
      const newStates = [...prevStates];
      newStates[blockIndex].rowsHeightMap = newMap;
      return newStates;
    });
  };

  /************************  the View button: share save star hide feature with connect API backend function     *************************/ 
  // 1. the Save Button connection API function
  const { mutateAsync: save } = useSaveIssuesMutation();

  const handleSave = async (query, blockIndex) => {
    const selectedIssueIds = Array.from(dataBlock[blockIndex].checkedRows);
    const sourceId = query;

    try {
      await save({ issueIds: selectedIssueIds, sourceId });

      // Clear only the checked rows for this specific block
      setDataBlock((prevStates) => {
        const newStates = [...prevStates];
        newStates[blockIndex].checkedRows = new Set();
        return newStates;
      });
    } catch (error) {
      console.error('Error saving issues:', error);
    }
  };

  // 2. the Share Button connection API function
  const shareIssuesMutation = useShareIssuesMutation();

  // Function to handle "Share" button click 
  const handleShare = async (blockIndex, selectedEmails, currentQuery) => {
    if (selectedEmails.length === 0) {
      setSelectedIssueIds(Array.from(dataBlock[blockIndex].checkedRows));
      setShowEmailSelect(true);
      return;
    }
    const issueIds = Array.from(dataBlock[blockIndex].checkedRows);
    try {
      await shareIssuesMutation.mutateAsync({
        emails: selectedEmails,
        issueIds: issueIds,
        query: {
          query: typeof currentQuery === "String" ? currentQuery : currentQuery.toString(), 
        },
      });

      toast.success('Issues shared successfully');
      setShowEmailSelect(false);
      setSelectedIssueIds([]);

      // Clear only the checked rows for this specific block
      setDataBlock((prevStates) => {
        const newStates = [...prevStates];
        newStates[blockIndex].checkedRows = new Set();
        return newStates;
      });
    } catch (error) {
      console.error("Error sharing issues:", error);
      toast.error(error.message || 'Failed to share issues');
    }
  };

  // 3.1 Function to handle "Hide" button click
  const { mutateAsync: hide } = useHideIssuesMutation();

  const handleHide = async (query, blockIndex) => {
    const selectedIssueIds = Array.from(dataBlock[blockIndex].checkedRows);
    const sourceId = query;

    try {
      await hide({ issueIds: selectedIssueIds, sourceId });

      // Clear the checked rows for this specific block
      setDataBlock((prevStates) => {
        const newStates = [...prevStates];
        newStates[blockIndex].checkedRows = new Set();
        return newStates;
      });

      // Remove hidden issues from both display array and relative array
      const filterOutHidden = array =>
        array.filter(issue => !selectedIssueIds.includes(issue["Issue ID"]));

      // Note: These might need to be block-specific as well depending on your implementation
      setIssueDisplayArray(filterOutHidden);
      setIssueRelativeArray(filterOutHidden);

      // If the clicked issue was hidden, clear it for this block
      if (dataBlock[blockIndex].clickedIssue &&
        selectedIssueIds.includes(dataBlock[blockIndex].clickedIssue["Issue ID"])) {
        setDataBlock((prevStates) => {
          const newStates = [...prevStates];
          newStates[blockIndex].clickedIssue = null;
          return newStates;
        });
      }

      // If the clicked similar issue was hidden, clear it
      // This needs to be adapted if clickedSimilarIssue becomes block-specific
      if (clickedSimilarIssue && selectedIssueIds.includes(clickedSimilarIssue["Issue ID"])) {
        setClickedSimilarIssue(null);
      }

    } catch (error) {
      console.error('Error hiding issue(s):', error);
      toast.error('Failed to hide issue(s)');
    }
  };

  // 3.2: the unhide button feature
  const { mutateAsync: unhide } = useUnhideIssuesMutation();

  const handleUnhide = async (query, blockIndex) => {
    const selectedIssueIds = Array.from(dataBlock[blockIndex].checkedRows);
    const sourceId = query; // unhidden

    try {
      await unhide({
        issueIds: selectedIssueIds,
        sourceId: sourceId
      });

      // Clear only the checked rows for this specific block
      setDataBlock((prevStates) => {
        const newStates = [...prevStates];
        newStates[blockIndex].checkedRows = new Set();
        return newStates;
      });
    } catch (error) {
      console.error('Error unhiding issues:', error);
    }
  };

  const { data: teamMembers = [], isLoading: isTeamMemberLoading } = useOrganizationUsersQuery();

  // 1.2. the function: used to Delete saved issues  
  const { mutateAsync: deleteSaved } = useDeleteSavedIssuesMutation();

  const handleDelete = async (query, blockIndex) => {
    const selectedIssueIds = Array.from(dataBlock[blockIndex].checkedRows);
    const sourceId = query;

    try {
      await deleteSaved({
        issueIds: selectedIssueIds,
        sourceId: sourceId
      });

      // Clear only the checked rows for this specific block
      setDataBlock((prevStates) => {
        const newStates = [...prevStates];
        newStates[blockIndex].checkedRows = new Set();
        return newStates;
      });
    } catch (error) {
      console.error('Error deleting saved issues:', error);
    }
  };

  // 4.1 the function used selected to add into star issue array list
  const toggleFavoriteMutation = useToggleFavoriteMutation();

  // Favorite issues function
  const handleFavorite = async (blockIndex) => {
    const selectedIssueIds = Array.from(dataBlock[blockIndex].checkedRows);
    const sourceId = dataBlock[blockIndex].clickedIssue?.["Issue ID"];

    try {
      // Process each issue sequentially
      await Promise.all(selectedIssueIds.map(async (issueId) => {
        await toggleFavoriteMutation.mutateAsync({
          issueId,
          sourceId,
          isFavorite: false // false because we want to favorite it (API expects opposite)
        });
      }));

      // Update UI state for all affected issues
      // Note: This might need to be block-specific depending on your implementation
      setIssueDisplayArray((prevArray) =>
        prevArray.map((issue) =>
          selectedIssueIds.includes(issue["Issue ID"])
            ? { ...issue, is_favorite: true }
            : issue
        )
      );

      // Update clicked issue if it's in the selection for this block
      if (dataBlock[blockIndex].clickedIssue &&
        selectedIssueIds.includes(dataBlock[blockIndex].clickedIssue["Issue ID"])) {
        setDataBlock((prevStates) => {
          const newStates = [...prevStates];
          newStates[blockIndex].clickedIssue = {
            ...newStates[blockIndex].clickedIssue,
            is_favorite: true
          };
          return newStates;
        });
      }

      // Clear only the checked rows for this specific block
      setDataBlock((prevStates) => {
        const newStates = [...prevStates];
        newStates[blockIndex].checkedRows = new Set();
        return newStates;
      });

      toast.success('Issues favorited successfully');
    } catch (error) {
      console.error('Error favoriting issues:', error);
      toast.error('Failed to favorite issues');
    }
  };

  // 4.2. Unfavorite issues function
  const handleUnfavorite = async (blockIndex) => {
    const selectedIssueIds = Array.from(dataBlock[blockIndex].checkedRows);
    const sourceId = dataBlock[blockIndex].clickedIssue?.["Issue ID"];

    try {
      // Process each issue sequentially
      await Promise.all(selectedIssueIds.map(async (issueId) => {
        await toggleFavoriteMutation.mutateAsync({
          issueId,
          sourceId,
          isFavorite: true // true because we want to unfavorite it (API expects opposite)
        });
      }));

      // Update UI state for all affected issues
      // Note: This might need to be block-specific depending on your implementation
      setIssueDisplayArray((prevArray) =>
        prevArray.map((issue) =>
          selectedIssueIds.includes(issue["Issue ID"])
            ? { ...issue, is_favorite: false }
            : issue
        )
      );

      // Update clicked issue if it's in the selection for this block
      if (dataBlock[blockIndex].clickedIssue &&
        selectedIssueIds.includes(dataBlock[blockIndex].clickedIssue["Issue ID"])) {
        setDataBlock((prevStates) => {
          const newStates = [...prevStates];
          newStates[blockIndex].clickedIssue = {
            ...newStates[blockIndex].clickedIssue,
            is_favorite: false
          };
          return newStates;
        });
      }

      // Clear only the checked rows for this specific block
      setDataBlock((prevStates) => {
        const newStates = [...prevStates];
        newStates[blockIndex].checkedRows = new Set();
        return newStates;
      });

      toast.success('Issues unfavorited successfully');
    } catch (error) {
      console.error('Error unfavoriting issues:', error);
      toast.error('Failed to unfavorite issues');
    }
  };

  /************************  the View button: share save star hide feature with connect API backend function     *************************/

  // used to change the query to array format
  const createInitialTerms = (queryData) => {
    let terms = [];
    
    // If queryData is a string, treat it as a simple query
    if (typeof queryData === 'string' || !isNaN(Number(queryData))) {
      terms.push({
        id: Date.now().toString(),
        value: queryData,
        isIssueId: !isNaN(Number(queryData)),
        fieldKey: 'Issue ID',
      });
      return terms;
    }

    // Handle structured query object if present
    if (queryData.query) {
      terms.push({
        id: Date.now().toString(),
        value: queryData.query,
        isIssueId: !isNaN(Number(queryData.query)),
        fieldKey: 'Issue ID',
      });
    }

    // Only process arrays if they exist
    if (queryData.systemArr?.length) {
      terms.push(...queryData.systemArr.map(param => ({
        id: `filter-system-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'system',
      })));
    }

    if (queryData.statusArr?.length) {
      terms.push(...queryData.statusArr.map(param => ({
        id: `filter-status-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'status',
      })));
    }

    if (queryData.requestorArr?.length) {
      terms.push(...queryData.requestorArr.map(param => ({
        id: `filter-requestor-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'requestor',
      })));
    }

    if (queryData.keywordsArr?.length) {
      terms.push(...queryData.keywordsArr.map(param => ({
        id: `filter-keywords-${param.toLowerCase().replace(/\s+/g, '-')}`,
        value: param,
        isIssueId: false,
        fieldKey: 'keywords',
      })));
    }

    return terms;
  };


  // the main render function
  return (
    <div className="IssueBlock">
      {blockArray.length === 0 && <div className="EmptyNotification">
        <NotificationComp
          errorTitle={`You do not have any ${tableViews} Issues`}
          hasSearchBar={false}
          Icon={ArrowUp}
        />
        </div>
      }

      {blockArray.map((item, blockIndex) => (
        dataBlock[blockIndex] && (
          <div key={blockIndex} className="issue-block-item">
            {/* the block query header area */}
            <QueryHeader
              timestamp={tableViews === "Shared" ? new Date(item.created_at).toLocaleString() : new Date(item.last_updated).toLocaleString() }
              queryArray={createInitialTerms(item.query || {"query": "489130"})}
              teamArray={item.shared_with}
              tableViews={tableViews}
              isSharedViews={isSharedView} 
              defaultWidth="100%"
            />
            
            {/* the issue table */}
            <CustomizeTable
              blockIndex={blockIndex}
              currentQuery={item.query}
              clickedIssue={dataBlock[blockIndex].clickedIssue}
              rows={item.issues}
              displayColumnswithCustomHeader={displayColumnswithCustomHeader}
              checkedRows={dataBlock[blockIndex].checkedRows}
              handleHeaderCheckboxChange={() => handleBlockHeaderCheckboxChange(blockIndex)}
              handleCheckboxChange={(id) => handleBlockCheckboxChange(blockIndex, id)}
              currentRowIndex={dataBlock[blockIndex].currentRowIndex}
              handleColumnResize={handleColumnResize}
              handleCellClick={(cellInfo, rowIndex) => handleBlockCellClick(blockIndex, cellInfo, rowIndex)}
              handleRowClick={handleRowClick}
              IssuePageSize={dataBlock[blockIndex].issuePageSize}
              setIssuePageSize={(newPageSize) => handleBlockSetIssuePageSize(blockIndex, newPageSize)}
              isSharedView={isSharedView}
              tableViews={tableViews}
              selectedAreas={selectedAreas}
              handleAreaChange={handleAreaChange}
              handleSaveSystem={handleSaveSystem}
              checkOpenTab={checkOpenTab}
              isOpenSpecification={isOpenSpecification}
              rowsHeight={rowsHeight}
              rowsHeightMap={dataBlock[blockIndex].rowsHeightMap}
              setRowsHeightMap={(newMap) => handleBlockRowsHeightMap(blockIndex, newMap)}
              handleRowHover={handleRowHover}
              handleRowLeave={handleRowLeave}
              handleMouseMove={handleMouseMove}
              hoveredRow={dataBlock[blockIndex].hoveredRow}

              handleDelete={() => handleDelete(item.query, blockIndex)}
              handleSave={() => handleSave(item.query, blockIndex)}
              handleShare={handleShare}
              handleUnhide={() => handleUnhide(item.query, blockIndex)}
              handleHide={() => handleHide(item.query, blockIndex)}
              handleUnfavorite={handleUnfavorite}
              handleFavorite={handleFavorite}
              teamMembers={teamMembers}
              isTeamMemberLoading={isTeamMemberLoading}
            />
          </div>
        )
      ))}
    </div>
  );
};

export default IssueBlock;