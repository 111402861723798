// this is the achieve details page including all the UI display, chart and data
import React from "react";
import "./achieve.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { SideBar } from "../../Components";
import { useAppContext } from "../../AppContext";
import { ChartIcon, Overview, Home, Settings, RouteTourIcon} from "../../Components/icons/General";
import AchieveHome from "./Home Page/AchieveHome.jsx";
import Trends from "./Trends/Trends.jsx"
import Milestones from "./Milestones Page/Milestones.jsx";
import Optimizations from "./Optimizations Page/Optimizations.jsx"
import IssueOverview from "./Issue overview/IssueOverview.jsx";
import SupplierRisk from "./Optimizations Page/Supplier Risk/SupplierRisk.jsx";
import TimeAllocation from "./Optimizations Page/TimeAllocation/TimeAllocation.jsx";
// import StatusOverview from "./Status overview/StatusOverview.jsx";
// the pages below the issue overview
// import LocationPage from "./Status overview/Location Page/LocationPage.jsx";
// import IssueDetailsPage from "./Status overview/Issue Page/IssueDetailsPage.jsx";
// import SystemPage from "./Status overview/System Page/SystemPage.jsx";
// import ManufacturerPage from "./Status overview/Manufacturer Page/ManufacturerPage.jsx"

const AchieveDetails = () => {

    const { isAchieveSideBarOpen, setIsAchieveSideBarOpen } = useAppContext();

    return <div className="achieveModule">
        <SideBar
            type="achieve"
            itemObj={[
                { icon: Home, title: "Home", path: "/achieve/achieve-home" },
                { 
                  icon: ChartIcon, 
                  title: "System Metrics", 
                  path: "/achieve/system-metrics",
                  subItems: [
                    { icon: RouteTourIcon, title: "Forecast", path: "/achieve/system-metrics/forecast" }
                  ]
                },
                { icon: Settings, title: "Optimizations", path: "/achieve/optimizations" },
                { icon: Overview, title: "Issue overview", path: "/achieve/issue-overview" },
              ]}
            isOpen={isAchieveSideBarOpen}
            setIsOpen={setIsAchieveSideBarOpen}
        />

        {/* these page was below to the Achieve module, so do not need added into router.js */}
        <div className="achieveContentArea"
            style={{
                width: isAchieveSideBarOpen ? 'calc(100vw - 212px)' : 'calc(100vw - 65px)',
            }}
        >
            <Routes>
                <Route path="/" element={<Navigate to="/achieve-home" replace />} />
                <Route path="achieve-home" element={<AchieveHome />} />
                <Route path="system-metrics" element={<Trends />} />
                <Route path="system-metrics/forecast/*" element={<Milestones />} />
                <Route path="issue-overview" element={<IssueOverview />} />
                <Route path="optimizations/*" element={<Optimizations />}>
                    <Route path="supplier-risk/:vendor" element={<SupplierRisk />} />
                    <Route path="time-allocation/:stage" element={<TimeAllocation />} />
                </Route>
            </Routes>
        </div>
    </div>
};

export default AchieveDetails;
