import React, { useEffect, useRef, useState } from "react";
import './AddChart.css';
import toast from 'react-hot-toast';
import { Button, ChartSearch, DesignChart } from "../../../../Components";
import { useSearchChartData } from "../../../../api/queries";
import { useAppContext } from "../../../../AppContext";
import { AIautoFill, ChartIcon, PieChartIcon, LineChartIcon, ScatterChartIcon } from "../../../../Components/icons/General";
import {  DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabelItem } from "../../../../Components/ui/DropdownMenu/DropdownMenu";

const AddChart = ({setOpenAddChart}) => {

    const { setUserGraphArray } = useAppContext();
    const [addSearchItem, setAddSearchItem] = useState(""); // the query that user input and send to backend get graph
    const [chartType, setChartType] = useState({ Icon: AIautoFill, Label: "Auto" });
    const [isChartLoading, setIsChartLoading] = useState(false);

    //the html data graph from backend database ==> used to recived the data graph from backend
   // const [currGraphTitle, setGraphTitle] = useState("");

    // state to handle the user input value of Add chart search field
    const [inputChartValue, setInputChartValue] = useState("");

    // State to hold the chart data and layout
    const [chartData, setChartData] = useState(null);
    const [chartLayout, setChartLayout] = useState(null);

    // the erro happend or not
    const [isErrorGraph, setIsErrorGraph] = useState(false);
    
    // const searchRef = useRef(null);

    // the userInput value
    //const [userInputTitle, setUserInputTitle] = useState("");

    // chart type array
    const chartArray = [
        {Icon: AIautoFill, Label: "Auto"},
        {Icon: ChartIcon, Label: "Bar Chart"},
        {Icon: PieChartIcon, Label: "Pie Chart"},
        {Icon: LineChartIcon, Label: "Line Chart"},
        {Icon: ScatterChartIcon, Label: "Scatter Chart"},
    ]

    // swicth the chart typle that user selected
    const handleSelectedChartType = (item) => {
        setChartType(item);
    }

    // const handleInputChangeTitle = (e) => {
    //     setUserInputTitle(e.target.value);
    // }

    // call the API end point and recived the graph chart data
    const mutation = useSearchChartData();

    //if user input something, call the API function
    useEffect(() => {
        if (addSearchItem.trim()) {
            handleSearch();
        }
    }, [addSearchItem]);

    // call the API
    const handleSearch = () => {
        setIsChartLoading(true);
        setIsErrorGraph(false); // every time restart the error status
    
        mutation.mutate(
            { user_query: addSearchItem },
            {
                onSuccess: (chartJson) => {
                    const chartObj = JSON.parse(chartJson);
                    setChartData(chartObj.data);
                    setChartLayout(chartObj.layout); 
                    setIsChartLoading(false);
                    setIsErrorGraph(false); // if scuess, then error status keep false
                },
                onError: (error) => {
                    setIsChartLoading(false);
                    setIsErrorGraph(true); // if happend error, change the error status to true
    
                    if (error.response && error.response.data) {
                        // get the error messages from backend
                        const errorData = error.response.data;
                        if (Array.isArray(errorData) && errorData.length > 0 && errorData[0].error) {
                            toast.error(errorData[0].error.description);
                        } else {
                            toast.error("An unknown error occurred.");
                        }
                    } else {
                        toast.error("Query is not relevant!");
                    }
                },
            }
        );
    };

    // need check whether have this graph already or not (in future)
    const handleSave = () => {
        if (chartData && chartLayout) {
            const newGraph = {
                title: addSearchItem,
                chartData: chartData,
                chartLayout: chartLayout,
            };
            
            // Ensure we're working with an array
            setUserGraphArray(prevGraphs => {
                // If prevGraphs is not an array, start a new array
                const updatedGraphs = Array.isArray(prevGraphs) ? prevGraphs : [];
                return [...updatedGraphs, newGraph];
            });
            setChartData(null);
            setChartLayout(null);
            setOpenAddChart(false);
            setInputChartValue("");
        }
    }
    
    // main render area
    return <div className="addChartArea">
        <div className="titleArea">
            <span className="addChartTitle">Visualize your data</span>
            <span className="addChartText">This Visualization tool is in Beta. Astra may occasionally make mistakes or misinterpret your query.</span>
        </div>

        <div className="searchArea">
            <ChartSearch
                width="546px"
                height="34px"
                placeholder="Enter your query. Example: Top 5 failure modes in the past decade."
                options={["Top 5 failure modes in the past decade", "A line chart of number of issues by quarter", "Bar chart of top 3 aircraft models by issue percentage", "Show me the distribution of primary problems", "A bar chart of issues by stage grouped by status"]}
                searchItem={addSearchItem}
                onSearch={setAddSearchItem}
                flex={1}
                inputValue={inputChartValue}
                setInputValue={setInputChartValue}
            />
            {/* <div className="viewByArea">View by</div> */}

            <DropdownMenu>
                <DropdownMenuTrigger style={{ width: "155px", height: "34px" }} disabled>
                    <span className="dropdown-menu-trigger-text">
                        <chartType.Icon size={16} color="#667085" style={{ marginRight: "8px" }} /> {chartType.Label}
                    </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent style={{ width: "155px" }}>
                    {chartArray.map((item) => (
                        <DropdownMenuLabelItem
                            key={item.Label}  
                            checked={chartType === item} 
                            onSelect={() => handleSelectedChartType(item)}
                        >
                            <item.Icon size={16} color="#667085" style={{ marginRight: "8px" }} /> {item.Label}
                        </DropdownMenuLabelItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>

        <DesignChart
            width="900px"
            height="550px"
            title={addSearchItem ? addSearchItem : "Define Your Graph"}
            chartData={chartData}
            chartLayout={chartLayout}
            flex={1}
            isLoading={isChartLoading}
            isError={isErrorGraph}
        />

        <div className="changeTitle">
            {/* <div className="titleArea">
                <span className="addChartTitle">Give your Chart a title</span>
                <span className="addChartText">You can leave the title blank for suggested title.</span>
            </div> */}

            <Button size="small" disabled={!chartData} onClick={handleSave}> Save </Button>
            {/* <div className="inputValueArea">
                <div className={`changeTitle-box ${userInputTitle ? "isSearched" : ""}`}
                    ref={searchRef}
                >
                    <input
                        type="text"
                        value={userInputTitle}
                        onChange={handleInputChangeTitle}
                    />
                </div>

                <Button size="small" disabled={!chartData}> Save </Button>
            </div> */}
        </div>
    </div>
}

export default AddChart;