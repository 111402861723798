import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./SideBar.css";
import { SideBarIcon, AddModule } from "../../icons/General";
import { ChevronDownIcon } from "../../icons";

const SideBar = ({
  type,
  itemObj = [],
  historyList = {},
  isOpen,
  setIsOpen,
}) => {

  const navigate = useNavigate(); // used for jump to othe page
  const location = useLocation(); // get current url location
  const [selectionItem, setSelectionItem] = useState(""); 
  const [expandedItems, setExpandedItems] = useState(new Set()); // the expand item list

  // listener the URL and get which one was clicked ==> only check the start of page
  useEffect(() => {
    const findDeepestMatch = (items, currentMatch = null) => {
      for (const item of items) {
        if (location.pathname === item.path || location.pathname.startsWith(item.path)) {
          currentMatch = item;
        }
        if (item.subItems) {
          const subMatch = findDeepestMatch(item.subItems, currentMatch);
          if (subMatch) {
            currentMatch = subMatch;
            setExpandedItems((prevExpandedItems) => {
              const newExpandedItems = new Set(prevExpandedItems);
              newExpandedItems.add(item.title);
              return newExpandedItems;
            });
          }
        }
      }
      return currentMatch;
    };
  
    const matchedItem = findDeepestMatch(itemObj);
    if (matchedItem) {
      setSelectionItem(matchedItem.title);
    }
  }, []);

  // the function of achieve side bar open and closed
  const toggleSideBar = () => {
    setIsOpen(!isOpen);
  };

  // the function used to select of side bar ==> and whethe open the sub menu
  const handleSelectionItem = (item) => {
    if (item.subItems) {
      const newExpandedItems = new Set(expandedItems);
      if (expandedItems.has(item.title)) {
        newExpandedItems.delete(item.title);
      } else {
        newExpandedItems.add(item.title);
      }
      setExpandedItems(newExpandedItems);
    }
  
    // avoid user click same page again, that render same page
    if (location.pathname !== item.path) {
      setSelectionItem(item.title);
      navigate(item.path);
    }
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      {/* SideBar Toggle Icon */}
      <div className="sidebar-toggle-icon" onClick={toggleSideBar}>
        <SideBarIcon size={24} color="#667085" />
      </div>

      {/* Achieve module SideBar Items */}
      {type === "achieve" && <div className="sidebar-items">
        {itemObj.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className={`sidebar-item ${selectionItem === item.title ? "isClicked" : ""}`}
                onClick={() => handleSelectionItem(item)}
              >
                <div className="itemIcon">
                  <item.icon size={24} color="#667085" />
                </div>
                {isOpen && <div className="item-title">{item.title}</div>}
                {item.subItems && isOpen && (
                  <div className="chevron-icon">
                    <ChevronDownIcon color="#667085" className={`subArrow-icon ${expandedItems.has(item.title) ? 'open' : ''}`}/>
                  </div>
                )}
              </div>

              {/* sub item (if have it) */}
              {item.subItems && expandedItems.has(item.title) && (
                <div className={`sidebar-subitems ${expandedItems.has(item.title) ? "show" : ""}`}>
                  {item.subItems.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      className={`sidebar-item sub-item ${selectionItem === subItem.title ? "isClicked" : ""}`}
                      onClick={() => handleSelectionItem(subItem)}
                    >
                      <div className="subItemIcon">
                        <subItem.icon size={24} color="#667085" />
                      </div>
                      {isOpen && <div className="subItem-title">{subItem.title}</div>}
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
      </div>}

      {/* Discover module SideBar Items */}
      {type === "discover" && <div className="sidebar-items">
        <div className="sidebar-item">
          <div className="itemIcon"> <AddModule size={24} color="#667085" /> </div>
          {isOpen && <div className="item-title">New Search</div>}
        </div>
        {/* History List */}
        {isOpen && (
          <div className="history-list">
            {Object.entries(historyList).map(([key, values], index) => (
              <div className="history-section" key={index}>
                <div className="history-title">{key}</div>

                <div className="history-items">
                  {values.map((item, idx) => (
                    <span key={idx} className="history-item">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>}
    </div>
  );
};

SideBar.propTypes = {
  type: PropTypes.string.isRequired,
  itemObj: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      subItems: PropTypes.array,
    })
  ).isRequired,
  historyList: PropTypes.array, // History list for additional functionality (future use)
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectionItem: PropTypes.string,
  setSelectionItem: PropTypes.func,
};

export default SideBar;
