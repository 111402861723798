// Chart Graph: Ahieve module Chart Card without chart data item display list (Bar Chart)
import React from "react";
import PropTypes from "prop-types";
import './ChartGraph.css'
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabelItem } from "../../ui/DropdownMenu/DropdownMenu";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Helper function to truncate long strings
const truncateString = (str, maxLength = 25) => {
  if (!str || str.length <= maxLength) return str;
  return str.substring(0, maxLength) + '...';
};

const ChartGraph = ({
  chartArr = [],
  chartType,
  dropDownArray = [],
  selectedOption,
  onDropdownChange,
  defaultHeight = "500px",
  defaultWidth = "500px",
  yAxisLabel,
  isIssueOverviewData,
  hideViewDetails = false,
  truncateLabels = true, // New prop to control label truncation
  maxLabelLength = 25, // New prop to set max label length
  chartTitle = ""
}) => {
  // Get the label axis list
  const labels = chartArr.map(item => truncateLabels ? truncateString(item.label, maxLabelLength) : item.label);
  // Store original labels for tooltips
  const originalLabels = chartArr.map(item => item.label);

  /************************************** 1. the y-axis Horizontal Bar Chart (system overviews)   ***************************************/
  // Generate data for stacked bars
  const yAxisData = {
    labels,
    datasets: chartArr.every(item => item.components?.length >= 1) ? [
      {
        label: "Component 1",
        data: chartArr.map(item => item.components[0]?.count ?? 0),
        backgroundColor: "#9966FF",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 2",
        data: chartArr.map(item => item.components[1]?.count ?? 0),
        backgroundColor: "#4BC0C0",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 3",
        data: chartArr.map(item => item.components[2]?.count ?? 0),
        backgroundColor: "#4dc9f6",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      }
    ] : [
      {
        label: "Component 1",
        data: [chartArr[0]?.count ?? 0, 0, 0],  // only display the series 1 value
        backgroundColor: "#9966FF",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 2",
        data: [0, chartArr[1]?.count ?? 0, 0],  // only display the series 2 value
        backgroundColor: "#4BC0C0",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      },
      {
        label: "Component 3",
        data: [0, 0, chartArr[2]?.count ?? 0],  // only display the series 3 value
        backgroundColor: "#4dc9f6",
        borderWidth: 1,
        stack: "stack1",
        maxBarThickness: 30,
      }
    ]
  };

  const yAxisOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false, // allow the chart over grid all the area
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end', // display in the end of top area
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 11,
          font: {
            size: 11, // the font size
          },
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return context.raw > 0 ? `${context.dataset.label}: ${context.raw}` : null;
          }
        }
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
      },
      y: {
        ticks: { autoSkip: false },
        stacked: true,
        title: {
          display: !!yAxisLabel,
          text: yAxisLabel
        }
      }
    }
  };

  /************************************** 2. the x-axis Bar Chart (location status overviews)   ***************************************/
  // Generate data for stacked bars

  const xAxisData = {
    labels: isIssueOverviewData ? (() => {
      // Use the pre-computed sortedData
      return chartArr.map(item => truncateLabels ? truncateString(item.label, maxLabelLength) : item.label);
    })() : labels,
    datasets: isIssueOverviewData ? (() => {
      // Use the pre-computed sortedData
      return [
        {
          label: "Open",
          data: chartArr.map(({ item }) => Number((item.Issues[0]?.count ?? 0).toFixed(2))),
          backgroundColor: "#175CD3",
          borderWidth: 1,
          maxBarThickness: 30,
          stack: "stack1",
        },
        {
          label: "Closed",
          data: chartArr.map(({ item }) => Number((item.Issues[1]?.count ?? 0).toFixed(2))),
          backgroundColor: "#2E90FA",
          borderWidth: 1,
          maxBarThickness: 30,
          stack: "stack1",
        }
      ];
    })() : chartArr.every(item => item.Issues?.length >= 1) ? [
      {
        label: "On Hold",
        data: chartArr.map(item => item.Issues[0]?.count ?? 0),
        backgroundColor: "#f97066",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      },
      {
        label: "Ongoing",
        data: chartArr.map(item => item.Issues[1]?.count ?? 0),
        backgroundColor: "#fec84b",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      },
      {
        label: "Closed",
        data: chartArr.map(item => item.Issues[2]?.count ?? 0),
        backgroundColor: "#32d583",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      }
    ] : chartArr.every(item => 'count' in item) ? [
      // Simple data format with just count (for failure modes chart)
      {
        label: "Count",
        data: chartArr.map(item => item.count),
        backgroundColor: "#175CD3",
        borderWidth: 1,
        maxBarThickness: 30,
      }
    ] : [
      // Existing fallback case
      {
        label: "On Hold",
        data: [chartArr[0]?.count ?? 0, 0, 0],
        backgroundColor: "#f97066",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      },
      {
        label: "Ongoing",
        data: [0, chartArr[1]?.count ?? 0, 0],
        backgroundColor: "#fec84b",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      },
      {
        label: "Closed",
        data: [0, 0, chartArr[2]?.count ?? 0],
        backgroundColor: "#32d583",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      }
    ]
  };

  const xAxisOptions = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false, // allow the chart over grid all the area
    layout: {
      padding: {
        right: 3,
        left: 3,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end', // display in the end of top area
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 12,
          font: {
            size: 11, // the font size
          },
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            // For truncated labels, show the full label in tooltip title
            const index = context[0].dataIndex;
            if (truncateLabels && chartType === "xBarChart") {
              if (isIssueOverviewData) {
                return chartArr[index]?.label || context[0].label;
              } else {
                return originalLabels[index] || context[0].label;
              }
            }
            return context[0].label;
          },
          label: function (context) {
            // Format the label based on dataset
            const value = context.raw;
            const formattedValue = yAxisLabel && yAxisLabel.includes('%')
              ? `${value}%`
              : value;
            return `${context.dataset.label}: ${formattedValue}`;
          }
        }
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          callback: function (value, index) {
            return this.getLabelForValue(value);
          },
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        stacked: true,
        title: {
          display: !!yAxisLabel,
          text: yAxisLabel
        }
      }
    }
  };

  // Add a yBarChart option for the failure modes (horizontal bar chart)
  const yFailureModesData = {
    labels,
    datasets: chartArr.every(item => 'Issues' in item && item.Issues.length >= 2) ? [
      {
        label: "Open",
        data: chartArr.map(item => item.Issues[0]?.count ?? 0),
        backgroundColor: "#175CD3",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      },
      {
        label: "Closed",
        data: chartArr.map(item => item.Issues[1]?.count ?? 0),
        backgroundColor: "#2E90FA",
        borderWidth: 1,
        maxBarThickness: 30,
        stack: "stack1",
      }
    ] : chartArr.every(item => 'count' in item) ? [
      // Simple data format with just count (fallback for failure modes chart)
      {
        label: "Count",
        data: chartArr.map(item => item.count),
        backgroundColor: "#175CD3",
        borderWidth: 1,
        maxBarThickness: 30,
      }
    ] : []
  };

  const yFailureModesOptions = {
    indexAxis: 'y',  // This makes it horizontal
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 10,
        left: 10,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 12,
          font: {
            size: 11,
          },
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            const index = context[0].dataIndex;
            if (truncateLabels) {
              return originalLabels[index] || context[0].label;
            }
            return context[0].label;
          },
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          }
        }
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        title: {
          display: !!yAxisLabel,
          text: yAxisLabel
        }
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        }
      }
    }
  };

  return (
    <div className="ChartGraph" style={{ width: defaultWidth, height: defaultHeight }}>
      {/* the dropdown menu used for selection switch system */}
      <div className="chartGraphMenuArea">
        {chartTitle && <div className="chartTitle">{chartTitle}</div>}
        {dropDownArray.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger height="32px">
              <span className="dropdown-menu-trigger-text">{selectedOption}</span>
            </DropdownMenuTrigger>
            <DropdownMenuContent>

              <div className="selectionMenuArea">
                {dropDownArray.map((item) => (
                  <DropdownMenuLabelItem
                    key={item}
                    checked={selectedOption === item}
                    onSelect={() => onDropdownChange(item)}
                  >
                    {item}
                  </DropdownMenuLabelItem>
                ))}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        {!hideViewDetails && <div className="detailsGraphText">View details</div>}
      </div>

      <div className="chartGraphArea">
        {chartType === "failureModeChart" && <Bar data={yFailureModesData} options={yFailureModesOptions} />}

        {/* the y-axis bar chart */}
        {chartType === "yBarChart" && <Bar data={yAxisData} options={yAxisOptions} />}

        {/* the x-axis bar chart */}
        {chartType === "xBarChart" && <Bar data={xAxisData} options={xAxisOptions} />}
      </div>
    </div>
  );
};

ChartGraph.propTypes = {
  chartArr: PropTypes.array.isRequired,
  chartType: PropTypes.string.isRequired,
  dropDownArray: PropTypes.array,
  selectedOption: PropTypes.string,
  onDropdownChange: PropTypes.func,
  defaultHeight: PropTypes.string,
  defaultWidth: PropTypes.string,
  yAxisLabel: PropTypes.string,
  hideViewDetails: PropTypes.bool,
  truncateLabels: PropTypes.bool,
  maxLabelLength: PropTypes.number,
  chartTitle: PropTypes.string
};

export default ChartGraph;