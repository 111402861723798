import React from "react";
import PropTypes from "prop-types";

const LineChartIcon = ({ size = 24, color = "black", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6837 7.27026C20.0868 7.64787 20.1074 8.2807 19.7297 8.68372L15.8109 12.8664C15.8026 12.8752 15.793 12.8857 15.7823 12.8974C15.6799 13.0093 15.4673 13.2416 15.1846 13.3708C14.9474 13.4793 14.686 13.524 14.4262 13.5006C14.1167 13.4727 13.8389 13.3244 13.7052 13.2529C13.6911 13.2454 13.6786 13.2388 13.6679 13.2332L10.545 11.6117L6.72975 15.6837C6.35214 16.0868 5.71931 16.1074 5.31628 15.7297C4.91326 15.3521 4.89265 14.7193 5.27026 14.3163L9.18913 10.1336C9.19741 10.1248 9.20697 10.1143 9.21774 10.1026C9.32011 9.99073 9.53273 9.75843 9.81538 9.62918C10.0526 9.52071 10.314 9.47597 10.5738 9.49937C10.8833 9.52726 11.1611 9.67563 11.2948 9.74707C11.3089 9.75459 11.3214 9.76125 11.3321 9.76684L14.455 11.3883L18.2703 7.31628C18.6479 6.91326 19.2807 6.89265 19.6837 7.27026Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2C3.55228 2 4 2.44772 4 3V20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V3C2 2.44772 2.44772 2 3 2Z"
    />
  </svg>
);

LineChartIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default LineChartIcon;
