import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import useAuthStore from "../stores/useAuthStore";

export const useAuth0Token = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const { clearAuth } = useAuthStore();

  const getToken = useCallback(async () => {
    try {
      return await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          organization: process.env.REACT_APP_AUTH0_ORGANIZATION_ID,
        }
      });
    } catch (error) {
      console.error('Error getting access token:', error);
      clearAuth();
      await logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
      throw error;
    }
  }, [getAccessTokenSilently, logout, clearAuth]);

  return { getToken };
}; 